import NoShowError from "components/ErrorScreens/NoShowError";
import TerminatedError from "components/ErrorScreens/TerminatedError";
import FooterLogo from "components/FooterLogo";
import Header from "components/Header";
import { ErrorTypes, StorageKeyNames } from "globals/enums";
import { FunctionComponent, useEffect } from "react";
import { ProctoringRoute } from "routes/route";
import {
  setIsCameraGrabEnabled,
  setIsFaceDetectionEnabled,
  setLinkAndVideo,
  setVideoStreamingEnabled,
  setScreenRecordingEnabled
} from "store/app.slice";
import { RootState, useAppDispatch, useAppSelector } from "store/store";

const Terminated: FunctionComponent = () => {
  const twilioAudioCall = useAppSelector((state: RootState) => state.app.twilioAudioCall);
  const errorScreenName = useAppSelector((state: RootState) => state.app.uiErrorScreen);
  const dispatch = useAppDispatch();
  useEffect(() => {
    sessionStorage.setItem(StorageKeyNames.Screen, ProctoringRoute.Terminated);
    if (twilioAudioCall) {
      twilioAudioCall.destroy();
    }

    const tvp_chat_container = document.getElementById("tvp-chat-container") as HTMLDivElement;
    if (tvp_chat_container) {
      tvp_chat_container.remove();
    }

    dispatch(setIsCameraGrabEnabled({ isCameraGrabEnabled: false }));
    dispatch(setIsFaceDetectionEnabled({ isFaceDetectionEnabled: false }));
    dispatch(setVideoStreamingEnabled({ isVideoStreamingEnabled: false }));
    dispatch(setScreenRecordingEnabled({ isScreenRecordingEnabled: false }));
    dispatch(setLinkAndVideo({ showLinkAndVideo: false }));

    const element = document?.getElementById("proview_root");
    if (element) {
      element.style.backgroundColor = "#000";
    }
  }, []);
  return (
    <div
      className="tvp-flex tvp-flex-col tvp-justify-start tvp-h-auto tvp-max-w-2xl tvp-min-w-2xl 
            tvp-rounded-lg tvp-bg-white tvp-mx-4 tvp-my-0 tvp-px-2.5 tvp-py-4 tvp-overflow-x-hidden tvp-base"
    >
      {errorScreenName === ErrorTypes.TerminatedError ? (
        <>
          <Header
            title={""}
            titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-red tvp-uppercase tvp-ml-2.5"}
          />
          <TerminatedError />
        </>
      ) : (
        <>
          <Header
            title={""}
            titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-red tvp-uppercase tvp-ml-2.5"}
          />
          <NoShowError />
        </>
      )}
      <FooterLogo />
    </div>
  );
};

export default Terminated;
