import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAppSelector, useAppDispatch } from "store/store";
import { removeToastedAlert } from "store/app.slice";
import { FormattedMessage } from "react-intl";
import { AlertDisplayType } from "helpers/alert-type";
import "react-toastify/dist/ReactToastify.css";

interface ToastAlert {
  description: string;
  name: string;
}

export const ToastContent = (props: ToastAlert) => {
  return (
    <div>
      <p>{<FormattedMessage id={props.name} />}</p>
      <p>{<FormattedMessage id={props.description} />}</p>
    </div>
  );
};
const Toastcontainer = () => {
  const dispatch = useAppDispatch();
  const notificationAlerts = useAppSelector((state) => state.app.notificationAlerts);

  useEffect(() => {
    if (notificationAlerts.length) {
      notificationAlerts[0].displayType == AlertDisplayType.Info
        ? toast.info(
            <ToastContent
              name={notificationAlerts[0].alertName}
              description={notificationAlerts[0].description}
            />
          )
        : toast.error(
            <ToastContent
              name={notificationAlerts[0].alertName}
              description={notificationAlerts[0].description}
            />
          );
      dispatch(removeToastedAlert());
    }
  }, [notificationAlerts]);

  return (
    <ToastContainer
      icon={true}
      role="alert"
      position="top-right"
      autoClose={2000}
      hideProgressBar={true}
      closeOnClick={true}
      pauseOnFocusLoss={false}
      pauseOnHover={true}
      draggable={false}
      theme="colored"
    />
  );
};

export default Toastcontainer;
