import React, { useContext, useEffect, useState } from "react";
import { ProctoringRoute, getEndpoint } from "routes/route";
import { FormattedMessage } from "react-intl";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import FaceDetect from "assets/img/face_detect.png";
import FooterLogo from "components/FooterLogo";
import Header from "components/Header";
import ListInstruction from "components/ListInstruction";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { GlobalAppState } from "globals/interfaces";
import { nextScreen } from "routes/workflow";
import { setAudioSourceInput } from "store/app.slice";
import { handleError } from "helpers/sentry";

import { MediaContext } from "context/MediaContext";

import _ from "underscore";

import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
import { ClientErrorTypes } from "globals/enums";

function AudioTestInstructions() {
  const { stop, start } = useContext(MediaContext);
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const instlist = [
    {
      id: 1,
      item: <FormattedMessage id="page.audioInstruction.one" />,
      altText: "Arrow indicating instruction one"
    },
    {
      id: 2,
      item: <FormattedMessage id="page.audioInstruction.two" />,
      altText: "Arrow indicating instruction two"
    },
    {
      id: 3,
      item: <FormattedMessage id="page.audioInstruction.three" />,
      altText: "Arrow indicating instruction three"
    }
  ];

  const navigate = useNavigate();

  const [microphoneDevices, setMicrophoneDevices] = useState<MediaDeviceInfo[]>([]);
  // const [selectedMicrophone, setSelectedMicrophone] = useState<MediaDeviceInfo | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getMicrophones = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const microphones = devices.filter((device) => device.kind === "audioinput");
        setMicrophoneDevices(microphones);
      } catch (error) {
        handleError(error, ClientErrorTypes.AudioInputError);
      }
    };
    getMicrophones();
  }, []);

  const handleNext = () => {
    const getNextScreen = nextScreen(
      ProctoringRoute.HardwareTestInstructions,
      globalState.configuration,
      globalState.session.session_type
    );
    navigate(getEndpoint(getNextScreen));
  };

  const handleChange = (event: any) => {
    const selectedDevice =
      microphoneDevices.find((device) => device.label === event.value) || microphoneDevices[0];
    dispatch(setAudioSourceInput({ audio_source: selectedDevice }));
    stop();
    const mediaConstraints: MediaStreamConstraints = {
      audio: {
        deviceId: selectedDevice.deviceId
      }
    };
    start(mediaConstraints);
  };

  const uniqueDeviceList = _.uniq(microphoneDevices, false, function (item) {
    return item.groupId;
  });

  const list = uniqueDeviceList.map((device) => {
    return device.label;
  });

  return (
    <Base>
      <Header
        title={<FormattedMessage id="page.audioInstruction.header" />}
        titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
        tabIndex={1}
      />
      <Content>
        <div className="tvp-w-full tvp-h-auto tvp-bg-white  tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-px-2 tvp-justify-center">
          <p>
            <FormattedMessage
              id="page.hardwareTest.instruction"
              values={{ strong: (chunks) => <strong>{chunks}</strong> }}
            />
          </p>
          <div className="tvp-flex tvp-col-row">
            <div className="tvp-or1-2">
              <ListInstruction instList={instlist} tips={true} className="" />
            </div>
            <div className="tvp-flex tvp-or2-1">
              <div className="tvp-flex tvp-flex-col tvp-justify-center tvp-items-center tvp-mt-5 tvp-w-full">
                <img
                  className="tvp-w-24"
                  src={FaceDetect}
                  alt="Icon indicating man with microphone"
                />
                <label className="tvp-my-2" id="aria-label" htmlFor="aria-audioTest-input">
                  <FormattedMessage id="page.audioInstruction.microphone.text" />
                </label>
                <Dropdown
                  ariaLabelledby="aria-label"
                  inputId="aria-audioTest-input"
                  list={list}
                  onChange={handleChange}
                  tabIndex={2}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="tvp-parts tvp-justify-center">
          <Button
            tabIndex={3}
            label={<FormattedMessage id="page.audioInstruction.next.button" />}
            className={
              "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green tvp-w-150"
            }
            onClick={handleNext}
          />
        </div>
      </Content>
      <FooterLogo />
    </Base>
  );
}

export default AudioTestInstructions;
