import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { appId, proctoringApiBaseUrl, xClientVersion } from "config/constant";
import type { RootState } from "store/store";
import { ChannelState } from "globals/interfaces";

export const channelApi = createApi({
  reducerPath: "channel",
  baseQuery: fetchBaseQuery({
    baseUrl: proctoringApiBaseUrl,
    prepareHeaders: (headers: Headers, { getState }) => {
      const sessionToken = (getState() as RootState).app.session.session_token;
      headers.set("app-id", appId as string);
      headers.set("session-token", sessionToken);
      headers.set("x-talview-client-version", xClientVersion);
    }
  }),
  endpoints: (builder) => ({
    getChannel: builder.query<ChannelState, void>({
      query: () => "v1/chat/channel"
    })
  })
});

export const { useGetChannelQuery } = channelApi;
