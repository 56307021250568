export enum ProctoringRoute {
  Landing = "/",
  Splash = "splash",
  TermsOfUse = "terms-of-use",
  Welcome = "welcome",
  Instructions = "instructions",
  HardwareTest = "hardware-test",
  HardwareTestInstructions = "hardware-test-instructions",
  FaceCapture = "face-capture",
  FaceCaptureInstructions = "face-capture-instructions",
  AudioDetection = "audio-detection",
  TestEnvInstructions = "env-test-instructions",
  TestEnvCapture = "env-test-capture",
  PreFlightSuccess = "pre-flight-success",
  VerificationStatus = "verification-status",
  PhotoIDVerification = "photo-id-verification",
  ExamStart = "exam-start-view",
  Error = "error",
  Terminated = "terminated",
  EndScreen = "End-screen",
  ScreenShare = "screen-share",
  RecorderScreen = "recorder-screen",
  SecondaryDevice = "secondary-device",
  PauseProctor = "pause-proctor",
  FullScreen = "full-screen",
  Feedback = "feedback"
}

export function getEndpoint(route: ProctoringRoute | string): string {
  return `/${route}`;
}
