import { useEffect, useState } from "react";

const useDetectAudio = (
  audioCounter: number,
  startRecording: boolean,
  mediaStream: MediaStream | null | undefined
) => {
  const [audioVolumeLevel, setAudioVolumeLevel] = useState<number>(0);
  const [audioDetectionPass, setAudioDetectionPass] = useState<boolean>(false);
  const [audioDetecting, setAudioDetecting] = useState<boolean>(false);
  async function audioDetection() {
    const audioContext = new AudioContext();
    if (mediaStream) {
      const streamNode = audioContext.createMediaStreamSource(mediaStream);
      const meter = createAudioMeter(audioContext);
      streamNode.connect(meter);
    }
  }

  const createAudioMeter = (audioContext: any) => {
    const processor = audioContext.createScriptProcessor(512);
    setAudioDetecting(true);
    processor.onaudioprocess = (audioProcessingEvent: any) => {
      const buf = audioProcessingEvent.inputBuffer.getChannelData(0);
      const bufLength = buf.length;
      let sum = 0;
      let x;

      for (let i = 0; i < bufLength; i++) {
        x = buf[i];
        if (Math.abs(x) >= processor.clipLevel) {
          processor.clipping = true;
          processor.lastClip = window.performance.now();
        }
        sum += x * x;
      }
      const rms = Math.sqrt(sum / bufLength);
      processor.volume = Math.max(rms, processor.volume * processor.averaging);
      if (processor.volume > 0.02) {
        const audioLevel = processor.volume * 400;
        setAudioVolumeLevel(audioLevel);
        if (audioLevel > 25) {
          audioCounter++;
        }
        if (audioCounter > 30 && !audioDetectionPass) {
          processor.shutdown();
          setAudioDetectionPass(true);
        }
      }
    };
    processor.clipping = false;
    processor.lastClip = 0;
    processor.volume = 0;
    processor.clipLevel = 0.98;
    processor.averaging = 0.95;
    processor.clipLag = 750;

    processor.connect(audioContext.destination);

    processor.checkClipping = () => {
      if (!processor.clipping) {
        return false;
      }
      if (processor.lastClip + processor.clipLag < window.performance.now()) {
        processor.clipping = false;
      }
      return processor.clipping;
    };

    processor.shutdown = () => {
      processor.disconnect();
      setAudioDetecting(false);
      processor.onaudioprocess = null;
    };
    return processor;
  };

  useEffect(() => {
    if (startRecording) {
      audioDetection();
    }
  }, [startRecording]);

  return [audioVolumeLevel, audioDetectionPass, audioDetecting];
};

export default useDetectAudio;
