import { createSlice } from "@reduxjs/toolkit";

const queueSlice = createSlice({
  name: "videoRecordingQueue",
  initialState: {
    items: []
  },
  reducers: {
    enqueueItem: (state, action) => {
      state.items.push(action.payload);
    },
    dequeueItem: (state) => {
      state.items.shift();
    }
  }
});

export const { enqueueItem, dequeueItem } = queueSlice.actions;
export default queueSlice.reducer;
