import React from "react";
import useCountdown from "hooks/useCountdown";
import { getSystemTime } from "helpers/common";

type Props = {
  duration: number;
  data?: string | Date;
};

const Countdown: React.FC<Props> = (props) => {
  const renderValue = (value: number): React.ReactNode => {
    if (value > 9) return value.toString();
    else return `0${value}`;
  };

  const [days, hours, minutes, seconds] = useCountdown(
    props.data
      ? new Date(props.data).getTime() + props.duration * 1000
      : getSystemTime().getTime() + props.duration * 1000
  );
  return (
    <>
      {days > 0 ? renderValue(days) + ":" : null}
      {hours > 0 ? renderValue(hours) + ":" : null}
      {renderValue(minutes)}:{renderValue(seconds)}
    </>
  );
};

export default Countdown;
