export const verification = `{
  "configuration": {
    "type": "live_proctor",
    "eye_gaze": false,
    "audio_call": true,
    "valid_from": "2022-12-30T07:15:00.000Z",
    "valid_till": "2023-02-27T22:30:00.000Z",
    "audio_check": true,
    "screen_grab": true,
    "face_capture": false,
    "alert_configs": [
      {
        "id": 641,
        "alert_type_id": 1,
        "name": "Unknown",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 642,
        "alert_type_id": 2,
        "name": "Session Created",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 643,
        "alert_type_id": 3,
        "name": "Session Joined",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 644,
        "alert_type_id": 4,
        "name": "Window Focus-In",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 646,
        "alert_type_id": 6,
        "name": "User Idle",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": true,
        "prompt_min_occurrence": null
      },
      {
        "id": 647,
        "alert_type_id": 7,
        "name": "User Active",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 648,
        "alert_type_id": 8,
        "name": "Session Closed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 649,
        "alert_type_id": 9,
        "name": "Socket connection success",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 650,
        "alert_type_id": 10,
        "name": "Socket disconnected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 651,
        "alert_type_id": 11,
        "name": "Screen-capture Started",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 652,
        "alert_type_id": 12,
        "name": "Screen-capture Stopped",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 653,
        "alert_type_id": 13,
        "name": "Recording Started",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 654,
        "alert_type_id": 14,
        "name": "Recording Stopped",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 655,
        "alert_type_id": 15,
        "name": "Face Detected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 645,
        "alert_type_id": 5,
        "name": "Window Focus-Out",
        "severity": "medium",
        "threshold": 2,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 657,
        "alert_type_id": 17,
        "name": "Notification Permission Granted",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 658,
        "alert_type_id": 18,
        "name": "Notification Permission Denied",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 660,
        "alert_type_id": 20,
        "name": "Screen-capture Install Failed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 661,
        "alert_type_id": 21,
        "name": "Screen-capture Screenshot",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 662,
        "alert_type_id": 22,
        "name": "Camera Screenshot",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 663,
        "alert_type_id": 23,
        "name": "Screen-capture Video",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 664,
        "alert_type_id": 24,
        "name": "Camera Video",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 665,
        "alert_type_id": 25,
        "name": "Face Detected Location",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 666,
        "alert_type_id": 26,
        "name": "Camera Test Passed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 667,
        "alert_type_id": 27,
        "name": "Camera Test Failed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 668,
        "alert_type_id": 28,
        "name": "Microphone Test Passed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 669,
        "alert_type_id": 29,
        "name": "Microphone Test Failed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 670,
        "alert_type_id": 30,
        "name": "Network Test Passed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 671,
        "alert_type_id": 31,
        "name": "Network Test Failed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 672,
        "alert_type_id": 32,
        "name": "Id Submitted",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 673,
        "alert_type_id": 33,
        "name": "Id Photo",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 676,
        "alert_type_id": 36,
        "name": "Network Change",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 677,
        "alert_type_id": 37,
        "name": "OS Change",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 678,
        "alert_type_id": 38,
        "name": "Browser Change",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 679,
        "alert_type_id": 39,
        "name": "Camera Audio",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 680,
        "alert_type_id": 40,
        "name": "Speaker Recognized",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 681,
        "alert_type_id": 41,
        "name": "Speaker Not Recognized",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 682,
        "alert_type_id": 42,
        "name": "Face Recognized",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 683,
        "alert_type_id": 43,
        "name": "Face Not Recognized",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 684,
        "alert_type_id": 44,
        "name": "Face: Id - Session Matched",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 685,
        "alert_type_id": 45,
        "name": "Face: Id - Session Not Matched",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 686,
        "alert_type_id": 46,
        "name": "Face: Database Session Matched",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 687,
        "alert_type_id": 47,
        "name": "Face: Database Session Not Matched",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 688,
        "alert_type_id": 48,
        "name": "Emotion Change Detected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 689,
        "alert_type_id": 49,
        "name": "Object Detected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 690,
        "alert_type_id": 50,
        "name": "Voice Activity Started",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 691,
        "alert_type_id": 51,
        "name": "Voice Activity Stopped",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 692,
        "alert_type_id": 52,
        "name": "Illumination Level",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 693,
        "alert_type_id": 53,
        "name": "Location Permission Granted",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 694,
        "alert_type_id": 54,
        "name": "Location Permission Denied",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 695,
        "alert_type_id": 55,
        "name": "Screen-capture Required",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 696,
        "alert_type_id": 56,
        "name": "Screen-capture Installed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 697,
        "alert_type_id": 57,
        "name": "Browser Not Supported",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 698,
        "alert_type_id": 58,
        "name": "Browser Supported",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 700,
        "alert_type_id": 60,
        "name": "Camera Detected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 701,
        "alert_type_id": 61,
        "name": "Hardware Test Skipped",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 702,
        "alert_type_id": 62,
        "name": "Forced",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 703,
        "alert_type_id": 63,
        "name": "Detected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 704,
        "alert_type_id": 64,
        "name": "Not Detected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 705,
        "alert_type_id": 65,
        "name": "Network Connected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 706,
        "alert_type_id": 66,
        "name": "Camera Permission Granted",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 707,
        "alert_type_id": 67,
        "name": "Camera Permission Denied",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 708,
        "alert_type_id": 68,
        "name": "Microphone Permission Granted",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 709,
        "alert_type_id": 69,
        "name": "Microphone Permission Denied",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 710,
        "alert_type_id": 70,
        "name": "Hardware Test Passed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 3059,
        "alert_type_id": 169,
        "name": "Mobile Phone Detected",
        "severity": "high",
        "threshold": 2,
        "prompt_each_time": true,
        "prompt_min_occurrence": null
      },
      {
        "id": 699,
        "alert_type_id": 59,
        "name": "Camera Not Detected",
        "severity": "medium",
        "threshold": 2,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 713,
        "alert_type_id": 73,
        "name": "Face Detection Retried",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 715,
        "alert_type_id": 75,
        "name": "Audio Test Failed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 716,
        "alert_type_id": 76,
        "name": "Audio Test Retried",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 3060,
        "alert_type_id": 170,
        "name": "Book detected",
        "severity": "medium",
        "threshold": 3,
        "prompt_each_time": true,
        "prompt_min_occurrence": null
      },
      {
        "id": 776,
        "alert_type_id": 138,
        "name": "Precheck Approved",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 777,
        "alert_type_id": 139,
        "name": "Precheck Rejected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 778,
        "alert_type_id": 140,
        "name": "Precheck Submitted",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 779,
        "alert_type_id": 141,
        "name": "Precheck Pending",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 780,
        "alert_type_id": 142,
        "name": "Session Playback Url",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 781,
        "alert_type_id": 143,
        "name": "Live Proctor Screenshot",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 782,
        "alert_type_id": 144,
        "name": "Session Terminated",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 783,
        "alert_type_id": 145,
        "name": "Photo ID capture",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 784,
        "alert_type_id": 146,
        "name": "Face capture",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 785,
        "alert_type_id": 147,
        "name": "Screen Capture",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 786,
        "alert_type_id": 148,
        "name": "Screen Capture Disabled",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 787,
        "alert_type_id": 149,
        "name": "Screen Capture Enabled",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 721,
        "alert_type_id": 81,
        "name": "Multiple Face Detected",
        "severity": "critical",
        "threshold": 1,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 719,
        "alert_type_id": 79,
        "name": "Network Lost",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 720,
        "alert_type_id": 80,
        "name": "Network Reconnected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 722,
        "alert_type_id": 82,
        "name": "Text Copied",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 723,
        "alert_type_id": 83,
        "name": "Contextmenu Clicked",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 724,
        "alert_type_id": 84,
        "name": "Context Switched",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 725,
        "alert_type_id": 85,
        "name": "Print Screen Clicked",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 726,
        "alert_type_id": 87,
        "name": "Custom Event",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 727,
        "alert_type_id": 88,
        "name": "Android: Screenshot",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 728,
        "alert_type_id": 89,
        "name": "Android: Pause",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 729,
        "alert_type_id": 90,
        "name": "Android: Resume",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 730,
        "alert_type_id": 91,
        "name": "Android: Incoming Call",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 731,
        "alert_type_id": 92,
        "name": "Android: Battery Low",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 732,
        "alert_type_id": 93,
        "name": "Android: Battery Okay",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 733,
        "alert_type_id": 94,
        "name": "Android: Proview Stop",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 734,
        "alert_type_id": 95,
        "name": "Android: Proview Start",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 735,
        "alert_type_id": 96,
        "name": "Android: Proview Start Fail",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 736,
        "alert_type_id": 97,
        "name": "Android: Raw Video",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 737,
        "alert_type_id": 98,
        "name": "Android: Network Disconnect",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 738,
        "alert_type_id": 99,
        "name": "Android: Network Reconnect",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 739,
        "alert_type_id": 100,
        "name": "iOS: Network Reconnect",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 740,
        "alert_type_id": 101,
        "name": "iOS: Network Disconnect",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 741,
        "alert_type_id": 102,
        "name": "iOS: Raw Video",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 742,
        "alert_type_id": 103,
        "name": "iOS: Proview Start Fail",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 743,
        "alert_type_id": 104,
        "name": "iOS: Proview Start",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 744,
        "alert_type_id": 105,
        "name": "iOS: Proview Stop",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 745,
        "alert_type_id": 106,
        "name": "iOS: Battery Okay",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 746,
        "alert_type_id": 107,
        "name": "iOS: Battery Low",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 747,
        "alert_type_id": 108,
        "name": "iOS: Incoming Call",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 748,
        "alert_type_id": 109,
        "name": "iOS: Resume",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 749,
        "alert_type_id": 110,
        "name": "iOS: Pause",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 750,
        "alert_type_id": 111,
        "name": "iOS: Screenshot",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 751,
        "alert_type_id": 112,
        "name": "WebRTC Video File",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 752,
        "alert_type_id": 113,
        "name": "Session Completed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 753,
        "alert_type_id": 114,
        "name": "Photo Enrollment",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 754,
        "alert_type_id": 115,
        "name": "Voice Enrollment 1",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 755,
        "alert_type_id": 116,
        "name": "Voice Enrollment 2",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 756,
        "alert_type_id": 117,
        "name": "Voice Enrollment 3",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 757,
        "alert_type_id": 118,
        "name": "Photo Id Enrollment",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 758,
        "alert_type_id": 119,
        "name": "Photo Fingerprint Check Request",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 759,
        "alert_type_id": 120,
        "name": "Photo Fingerprint Check Success",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 760,
        "alert_type_id": 121,
        "name": "Photo Fingerprint Check Failed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 761,
        "alert_type_id": 122,
        "name": "Voice Fingerprint Check Request",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 762,
        "alert_type_id": 123,
        "name": "Voice Fingerprint Check Success",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 763,
        "alert_type_id": 124,
        "name": "Voice Fingerprint Check Failed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 764,
        "alert_type_id": 125,
        "name": "Face Verification Check Request",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 765,
        "alert_type_id": 126,
        "name": "Face Verification Check Success",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 766,
        "alert_type_id": 127,
        "name": "Face Verification Check Failed",
        "severity": "critical",
        "threshold": 0,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 768,
        "alert_type_id": 129,
        "name": "Voice detected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 770,
        "alert_type_id": 131,
        "name": "Microphone switched",
        "severity": "medium",
        "threshold": 2,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 771,
        "alert_type_id": 132,
        "name": "Microphone not detected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 772,
        "alert_type_id": 133,
        "name": "Test Environment Check",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 773,
        "alert_type_id": 134,
        "name": "Test Environment Camera Video",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 774,
        "alert_type_id": 136,
        "name": "Proctor Rating Override",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 788,
        "alert_type_id": 150,
        "name": "Screen Capture Denied",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 789,
        "alert_type_id": 151,
        "name": "Cron Job Session Completed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 790,
        "alert_type_id": 152,
        "name": "Partial Screen Sharing Permitted",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 791,
        "alert_type_id": 153,
        "name": "Full Screen Sharing Permitted",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 792,
        "alert_type_id": 154,
        "name": "Face capture Success",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 793,
        "alert_type_id": 155,
        "name": "Face capture Failure",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 794,
        "alert_type_id": 156,
        "name": "ID card capture success",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 795,
        "alert_type_id": 157,
        "name": "ID card capture failure",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 796,
        "alert_type_id": 158,
        "name": "Socket connection failure",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 797,
        "alert_type_id": 159,
        "name": "Audio device detected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 798,
        "alert_type_id": 160,
        "name": "Audio device not detected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 799,
        "alert_type_id": 161,
        "name": "Environment test capture success",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 800,
        "alert_type_id": 162,
        "name": "Environment test capture failure",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 801,
        "alert_type_id": 163,
        "name": "Photo ID card Not Available",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 802,
        "alert_type_id": 164,
        "name": "Gazing away",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 803,
        "alert_type_id": 165,
        "name": "Eye detected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 804,
        "alert_type_id": 166,
        "name": "Eye not detected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 805,
        "alert_type_id": 167,
        "name": "Src Photo Id Card Not Available",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 806,
        "alert_type_id": 168,
        "name": "Src Face Not Available",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 769,
        "alert_type_id": 130,
        "name": "Camera switched",
        "severity": "medium",
        "threshold": 2,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 767,
        "alert_type_id": 128,
        "name": "Pasting text detected",
        "severity": "low",
        "threshold": 1,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 4360,
        "alert_type_id": 16,
        "name": "Face Not Detected",
        "severity": "high",
        "threshold": 3,
        "prompt_each_time": true,
        "prompt_min_occurrence": null
      },
      {
        "id": 4364,
        "alert_type_id": 172,
        "name": "Audio Call Requested",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": 1
      },
      {
        "id": 4368,
        "alert_type_id": 173,
        "name": "Audio Call Initiated",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": 1
      },
      {
        "id": 4372,
        "alert_type_id": 174,
        "name": "Audio Call Connected",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": 1
      },
      {
        "id": 4376,
        "alert_type_id": 175,
        "name": "Audio Call Progress",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": 1
      },
      {
        "id": 4380,
        "alert_type_id": 176,
        "name": "Audio Call Ended",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": 1
      },
      {
        "id": 4384,
        "alert_type_id": 74,
        "name": "Audio Test Passed",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": null
      },
      {
        "id": 4400,
        "alert_type_id": 179,
        "name": "File Upload Failure",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": 1
      },
      {
        "id": 4404,
        "alert_type_id": 180,
        "name": "Audio Recorded",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": 1
      },
      {
        "id": 4412,
        "alert_type_id": 181,
        "name": "Candidate Offline",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": 1
      },
      {
        "id": 4426,
        "alert_type_id": 182,
        "name": "Midway Check Retry",
        "severity": "none",
        "threshold": null,
        "prompt_each_time": false,
        "prompt_min_occurrence": 1
      }
    ],
    "end_buffer_time": 0,
    "video_recording": true,
    "object_detection": false,
    "twilio_streaming": true,
    "environment_check": true,
    "start_buffer_time": 0,
    "chat_support_event": true,
    "mixpanel_analytics": true,
    "pre_flight_approval": true,
    "terms_and_conditions": true,
    "chat_support_precheck": true,
    "high_touch_proctoring": true,
    "id_verification_check": true,
    "midway_face_verification": true,
    "organization_id": 31
  },
  "verification_code": "HA34mtKx02Hyg9popf28fbT5wM8aziu95u7CQ3ZMbndahEOn8iIlb39SJKnsfWNnDBojMn9xUCAc4bGBzZQX+4s7hcWONjeLAk4UBWRnRPcF7pue52Y70tJNKibHtZYWJzcswUOdBGb96bmWREzq6DSr+q7mQhp0H/ABNrmiEU9uPEg/7Pile0mVnzHbztCW80SBOYP8QFiBpuVl3DQdhD8sBmEU7d7SO8/aYaaR1K15Vzxs6whEmrtDFHQviznujBZGhXrKYkwWBH5iM4/o0dNVV4qcpZTn9m5Ykj1I8gi7SD7Qj6u/BD+gfQecBCkm/Dswl59AupCrP9T4xosJQNmmUK9og6qhlRZnLTwyR6sc41nr+SnSc+3GGPniN+iRwvmlZzqQ6Lh/F/UkwGKyFuZ5dARdmqaAz65fjFzuFb/a+sH4M3i0BJKE2A/Dbn0cPnStmhZGtZxR0WD+Y891xMvp5NF8kL9"
}`;
