import { ErrorTypes, SessionStatus } from "../globals/enums";
import { PreCheckItem } from "../services/precheck";

/**
 * This function returns the detail for the alert provided as an input
 * @param precheck - precheck array
 * @param verificationType - type of the verification eg. photo_id
 * @returns filtered precheck data according to the type
 */
export const filterPrecheckData = (precheck: PreCheckItem[], verificationType: string) => {
  return precheck.find((e) => e.verification_type === verificationType);
};

/**
 * This function returns the error type as per the status of the session
 * @param status - session status
 * @returns error type as per the session status
 */
export const getErrorType = function (status: string) {
  switch (status) {
    case SessionStatus.Terminated:
      return ErrorTypes.TerminatedError;
    case SessionStatus.NoShow:
      return ErrorTypes.NoShowError;
    case SessionStatus.Completed:
      return ErrorTypes.SessionCompleted;
    default:
      return null;
  }
};
