import { ProctoringRoute, getEndpoint } from "routes/route";
import React, { useEffect } from "react";
import Header from "components/Header";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import FooterLogo from "components/FooterLogo";
import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
const ExamStart = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(getEndpoint(ProctoringRoute.PreFlightSuccess));
    }, 2000);
  }, [navigate]);

  return (
    <Base>
      <Header title={""} titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-dgray tvp-ml-2.5"} />
      <Content>
        <p className="tvp-clr-dgray tvp-mb-2.5 tvp-text-sm tvp-mt-12">
          <FormattedMessage id="page.ExamStart.HeaderText" />
        </p>
        <p className="tvp-mb-3">
          <FormattedMessage id="page.ExamStart.reDirect.text" />
          <strong className="tvp-clr-red tvp-text-sm"> 00:00</strong>
        </p>
        <p>
          <FormattedMessage id="page.ExamStart.success.text" />
        </p>
      </Content>
      <FooterLogo />
    </Base>
  );
};

export default ExamStart;
