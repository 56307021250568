import { livekitWSUrl } from "config/constant";
import { StreamingProvider, ErrorTypes } from "globals/enums";
import { RecordingService, RecordingServiceOptions } from "globals/interfaces";
import { handleError } from "helpers/sentry";
import {
  AudioPresets,
  ConnectionQuality,
  DisconnectReason,
  LocalAudioTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  Room,
  RoomEvent,
  Track,
  VideoPresets
} from "livekit-client";

export class LivekitService implements RecordingService {
  public livekitRoom;
  private options: RecordingServiceOptions = {};

  async connect(options: RecordingServiceOptions) {
    this.options = options;
    try {
      const room = new Room({
        disconnectOnPageLeave: false,
        publishDefaults: {
          videoCodec: "vp8",
          backupCodec: {
            codec: "h264"
          },
          videoSimulcastLayers: [VideoPresets.h360, VideoPresets.h540]
        },
        videoCaptureDefaults: {
          resolution: VideoPresets.h540.resolution
        },
        stopLocalTrackOnUnpublish: false,
      });
      await room.connect(livekitWSUrl, options.streamingToken, {
        autoSubscribe: false
      });

      room.on(RoomEvent.Disconnected, (reason) => {
        handleError(`Livekit disconnected: ${DisconnectReason[reason]}`);
        this.options.onDisconnect();
      });

      room.on(RoomEvent.Reconnecting, () => {
        this.options.onReconnecting();
      });

      room.on(RoomEvent.Reconnected, () => {
        this.options.onReconnected();
      });

      room.on(RoomEvent.LocalTrackPublished, (publish) => {
        if (
          room.localParticipant?.trackPublications.size >= 1 &&
          publish.track.source != Track.Source.ScreenShare
        ) {
          const audioTrackSid =
            room.localParticipant?.audioTrackPublications.entries().next().value?.[0] ?? null;
          const videoTrackSid =
            room.localParticipant?.videoTrackPublications.entries().next().value?.[0] ?? null;

          if (audioTrackSid || videoTrackSid) {
            const tracks: { videoTrackSid?: string; audioTrackSid?: string } = {
              ...(videoTrackSid && { videoTrackSid }),
              ...(audioTrackSid && { audioTrackSid })
            };
            this.options.onAudioVideoTrackPublished(
              {
                participantSid: room.localParticipant?.sid,
                tracks
              },
              StreamingProvider.Livekit
            );
          }
        }
      });

      room.on(RoomEvent.ConnectionQualityChanged, () => {
        const isGoodQuality = this.handleNetworkQualityChange(room);
        options.onNetworkQualityChange(isGoodQuality);
      });

      options.trackPublished(room);
      this.livekitRoom = room;
      console.log("connected", room.localParticipant);

      if (!options.tracks) {
        return;
      }
      const newVideoTrack = options.tracks.find((track) => track.kind === "video");
      const newAudioTrack = options.tracks.find((track) => track.kind === "audio");

      if (newVideoTrack) {
        await room.localParticipant.publishTrack(newVideoTrack, {
          name: "camera",
          source: Track.Source.Camera
        });
      }

      if (newAudioTrack) {
        await room.localParticipant.publishTrack(newAudioTrack, {
          name: "audio",
          source: Track.Source.Microphone,
          audioPreset: AudioPresets.speech
        });
      }
    } catch (e: any) {
      handleError("Livekit service connection failed");
      handleError(e);
      options.onConnectionFailure(e);
    }
  }

  handleNetworkQualityChange = (room) => {
    return (
      room.localParticipant.connectionQuality === ConnectionQuality.Good ||
      room.localParticipant.connectionQuality === ConnectionQuality.Excellent
    );
  };

  createLocalVideoTrack(track: MediaStreamTrack) {
    if (track) {
      const localTrack = new LocalVideoTrack(track);
      return localTrack;
    }
  }

  async unpublishTrack(publishedTrack: LocalTrackPublication) {
    if (publishedTrack) {
      await this.livekitRoom.localParticipant.unpublishTrack(publishedTrack);
    }
  }

  async flipVideo(tracks: MediaStreamTrack[]) {
    if (!tracks?.length) {
      return;
    }

    try {
      if (this.options?.tracks?.length >= 2) {
        await this.livekitRoom.localParticipant.videoTrackPublications.forEach(
          async (publication) => {
            if (publication.track && publication.source !== Track.Source.ScreenShare) {
              publication.track.stop();
              await this.livekitRoom.localParticipant.unpublishTrack(publication.track);
            }
          }
        );

        await this.livekitRoom.localParticipant.audioTrackPublications.forEach(
          async (publication) => {
            if (publication.track) {
              publication.track.stop();
              await this.livekitRoom.localParticipant.unpublishTrack(publication.track);
            }
          }
        );
      }
      const newVideoTrack = tracks.find((track) => track.kind === "video");
      const newAudioTrack = tracks.find((track) => track.kind === "audio");
      if (!newVideoTrack) {
        throw new Error("No video track found");
      }
      if (!newAudioTrack) {
        throw new Error("No audio track found");
      }
      await this.livekitRoom.localParticipant.publishTrack(
        this.createLocalVideoTrack(newVideoTrack),
        {
          name: "camera",
          source: Track.Source.Camera
        }
      );
      await this.livekitRoom.localParticipant.publishTrack(new LocalAudioTrack(newAudioTrack), {
        name: "audio",
        source: Track.Source.Microphone,
        audioPreset: AudioPresets.speech
      });
      this.options.tracks = [newVideoTrack, newAudioTrack];
    } catch (e) {
      this.options.onConnectionFailure(e);
    }
  }

  disconnect(): void {
    console.log("Livekit Disconnected");
    if (this.livekitRoom) {
      this.livekitRoom.disconnect();
    }
  }

  async publishTrack(track: LocalVideoTrack, trackName: string) {
    if (track) {
      const toPublishTrack = track;
      toPublishTrack.source = Track.Source.ScreenShare;

      try {
        const publishedTrack = await this.livekitRoom.localParticipant.publishTrack(
          toPublishTrack,
          { name: trackName }
        );
        return publishedTrack.track;
      } catch (e) {
        this.options.onConnectionFailure(e);
        return null;
      }
    }
  }
}
