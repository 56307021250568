import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { appId, proctoringApiBaseUrl, xClientVersion } from "config/constant";
import type { RootState } from "store/store";

export interface AudioTokenResponse {
  token: string;
}

export const audioTokenApi = createApi({
  reducerPath: "audioToken",
  baseQuery: fetchBaseQuery({
    baseUrl: proctoringApiBaseUrl,
    prepareHeaders: (headers: Headers, { getState }) => {
      const sessionToken = (getState() as RootState).app.session.session_token;
      headers.set("app-id", appId as string);
      headers.set("session-token", sessionToken);
      headers.set("x-talview-client-version", xClientVersion);
    }
  }),
  endpoints: (builder) => ({
    getAudioToken: builder.query<AudioTokenResponse, void>({
      query: () => "/v1/audio-call/attendee/token",
      transformResponse: (response: AudioTokenResponse) => {
        return response;
      }
    })
  })
});

export const { useGetAudioTokenQuery } = audioTokenApi;
