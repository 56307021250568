import { AlertLogConfig } from "services/session-token";
import { shouldNotifyCandidate } from "helpers/notify-candidate";
import { setNotificationAlerts } from "store/app.slice";
import { AlertName, AlertType, AlertDisplayType } from "helpers/alert-type";
import { enqueueItem } from "store/alertSlice";
import { getSystemTime } from "./common";

export const alertHelper = (alertConfig: AlertLogConfig[]) => {
  const getAlert = (alert: AlertName, base64Data?: string | null, description?: string | null) => {
    return {
      alert_type_id: AlertType[alert].id,
      description: description ? description : AlertType[alert].alertName,
      timestamp: getSystemTime().toISOString(),
      payload: base64Data ? { file: base64Data } : undefined
    };
  };
  const raiseAlert = async (
    alert: AlertName,
    dispatch: any,
    description?: string | null,
    payload?: string | null
  ) => {
    const alertData = getAlert(alert, payload, description);
    window.ProctorClient3.trigger(
      `log:event:all log:event:type:${alertData.alert_type_id}`,
      alertData
    );

    dispatch(enqueueItem(alertData));
    if (
      AlertType[alert].displayType !== AlertDisplayType.None &&
      shouldNotifyCandidate(alertConfig, AlertType[alert].id)
    ) {
      dispatch(setNotificationAlerts(AlertType[alert]));
    }
  };
  return { raiseAlert, getAlert };
};
