import React from "react";
import FooterLogo from "components/FooterLogo";
type ErrorWrapperProps = {
  header: React.ReactNode;
  content: React.ReactNode;
};

const ErrorWrapper = (props: ErrorWrapperProps) => {
  React.useEffect(() => {
    const element = document?.getElementById("proview_root");
    if (element) {
      element.style.display = "flex";
      element.style.backgroundColor = "#000";
    }
  }, []);

  return (
    <div id="basediv">
      <div
        className="tvp-flex tvp-flex-col tvp-justify-start tvp-h-auto tvp-max-w-2xl tvp-min-w-2xl 
    tvp-rounded-lg tvp-bg-white tvp-mx-4 tvp-my-0 tvp-px-2.5 tvp-py-4 tvp-overflow-x-hidden tvp-base"
      >
        {props?.header}
        {props?.content}
        <FooterLogo />
      </div>
    </div>
  );
};

export default ErrorWrapper;
