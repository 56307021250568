/* eslint-disable */
import { ProctoringRoute, getEndpoint } from "routes/route";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Button from "components/Button";
import FooterLogo from "components/FooterLogo";
import Header from "components/Header";
import LoaderGreen from "assets/img/loader_green.gif";
import Message from "components/Message";
import Canvas from "components/Canvas";
import VideoPlayer from "components/VideoPlayer";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { useAppDispatch, useAppSelector } from "store/store";
import { useFaceCapture, faceCaptureUploadApi } from "services/faceCaptureUpload";
import { GlobalAppState } from "globals/interfaces";
import { nextScreen } from "routes/workflow";
import { AlertLogConfig } from "services/session-token";
import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
import { MediaContext } from "context/MediaContext";
import { hideProview } from "../helpers/hideProview";
import * as faceapi from "@vladmandic/face-api";
import * as tf from "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-wasm";
import { ErrorMessage, SessionScope } from "globals/enums";
import { isWasmSupported, isWebGLSupported } from "helpers/face-detection";

function FaceCapture() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const [
    faceCapture,
    {
      isUninitialized: createAlertIsUninitialized,
      isLoading: createAlertIsLoading,
      isSuccess: createAlertSuccess
    }
  ] = useFaceCapture();
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [faceCaptureFailure, setFaceCaptureFailure] = useState<boolean>(false);
  const [captured, setCaptured] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const { mediaStream, mediaStreamError } = useContext(MediaContext);

  const uploadFaceCapture = async (payload: string) => {
    try {
      const alertData = alertHelper(alertConfig).getAlert(AlertName.FaceCapture, payload, null);
      await faceCapture(alertData).unwrap();
      alertHelper(alertConfig).raiseAlert(AlertName.FaceCaptureSuccess, dispatch);
      setLoading(false);
      if (mediaStreamError && mediaStreamError.name === "AudioHardwareFailedError") {
        hideProview();
        navigate(getEndpoint(ProctoringRoute.RecorderScreen));
      } else {
        const getNextScreen = nextScreen(
          ProctoringRoute.FaceCapture,
          globalState.configuration,
          globalState.session.session_type
        );
        navigate(getEndpoint(getNextScreen));
      }
    } catch (e: any) {
      setLoading(false);
      setFaceCaptureFailure(true);
      setErrorMessage(e);
      alertHelper(alertConfig).raiseAlert(AlertName.FaceCaptureFailure, dispatch);
    }
  };

  useEffect(() => {
    if (captured) {
      (async () => {
        if (canvasRef.current) {
          setLoading(true);
          const payload = canvasRef.current?.toDataURL("image/png");
          if (globalState?.session?.session_type !== SessionScope.LiveProctor) {
            await uploadFaceCapture(payload);
          } else {
            const MODEL_URL = process.env.REACT_APP_FACE_DETECTION_MODEL_URL;

            if (isWebGLSupported()) {
              await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
            } else if (isWasmSupported()) {
              await tf.setBackend("wasm");
              await tf.ready();
              await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
            } else {
              await tf.setBackend("cpu");
              await tf.ready();
              await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
            }

            const facePredictions = await faceapi.detectAllFaces(
              canvasRef.current,
              new faceapi.TinyFaceDetectorOptions({
                inputSize: 416,
                scoreThreshold: 0.2
              })
            );

            if (facePredictions.length === 0) {
              setLoading(false);
              setFaceCaptureFailure(true);
              setErrorMessage(ErrorMessage.NoFaceDetected);
              alertHelper(alertConfig).raiseAlert(AlertName.FaceCaptureFailure, dispatch);
            } else if (facePredictions.length > 1) {
              setLoading(false);
              setFaceCaptureFailure(true);
              setErrorMessage(ErrorMessage.MultipleFacesDetected);
              alertHelper(alertConfig).raiseAlert(AlertName.FaceCaptureFailure, dispatch);
            } else {
              await uploadFaceCapture(payload);
            }
          }
        }
      })();
    }
  }, [captured]);

  const handleReTake = () => {
    dispatch(faceCaptureUploadApi.util.resetApiState());
    setCaptured(false);
    setFaceCaptureFailure(false);
  };

  return (
    <Base>
      <Header
        title={<FormattedMessage id="page.faceCapture.header" />}
        titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
        tabIndex={1}
      />
      <Content>
        <div className="tvp-w-full tvp-h-auto tvp-bg-white  tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-px-2 tvp-items-center tvp-justify-center">
          {!mediaStream && <img src={LoaderGreen} alt="loader" />}
          {mediaStream && (
            <>
              <div className="tvp-photIdVideoPlayerWrapper tvp-mb-9">
                <VideoPlayer mediaStream={mediaStream} videoRef={videoRef} playerWidth={"200px"} />
                {captured && (
                  <Canvas videoRef={videoRef} canvasRef={canvasRef} height={150} width={200} />
                )}
              </div>
              {createAlertIsUninitialized && (
                <p className="tvp-mt-4">
                  <FormattedMessage id="page.faceCapture.initialize.text" />
                </p>
              )}
              {(createAlertIsLoading || loading) && !faceCaptureFailure && (
                <div
                  className="tvp-flex tvp-flex-row tvp-justify-center tvp-mt-3"
                  aria-live="polite"
                >
                  <img
                    className="tvp-squre-25 tvp-mr-4"
                    src={LoaderGreen}
                    alt="Loader Green icon"
                  />
                  <p>
                    <FormattedMessage id="page.faceCapture.loading.text" />
                  </p>
                </div>
              )}
              {createAlertSuccess && !faceCaptureFailure && (
                <div>
                  <Message
                    messages={<FormattedMessage id="page.faceCapture.success.text" />}
                    className="tvp-w-full tvp-flex"
                  />
                  <div className="tvp-flex tvp-flex-row tvp-justify-center tvp-mt-3">
                    <img className="tvp-squre-25 tvp-mr-4" src={LoaderGreen} alt="LoaderGreen" />
                    <Message
                      messages={<FormattedMessage id="page.faceCapture.proceeding.text" />}
                      className="tvp-flex tvp-justify-center"
                    />
                  </div>
                </div>
              )}
              {faceCaptureFailure && (
                <Message
                  messages={<FormattedMessage id="error.nofaceDetected" />}
                  className="tvp-w-full tvp-flex tvp-clr-red tvp-justify-center"
                />
              )}
              {!faceCaptureFailure ? (
                <Button
                  tabIndex={2}
                  label={<FormattedMessage id="page.faceCapture.takeAPicture.button" />}
                  className={
                    createAlertIsUninitialized
                      ? "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green tvp-min-w-150 tvp-mt-4"
                      : "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-bg-lgray tvp-clr-dgray tvp-mx-auto tvp-bdr-lgray tvp-w-150 tvp-mt-4"
                  }
                  disabled={!createAlertIsUninitialized || loading}
                  onClick={() => setCaptured(true)}
                />
              ) : (
                <Button
                  tabIndex={2}
                  label={<FormattedMessage id="page.faceCapture.reTake.button" />}
                  className={
                    "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-red tvp-bdr-red tvp-w-150  tvp-mt-4"
                  }
                  onClick={handleReTake}
                />
              )}
              {faceCaptureFailure && (
                <div className="tvp-flex tvp-flex-col tvp-align-middle tvp-mt-2">
                  <p className="tvp-flex tvp-justify-center">
                    <FormattedMessage id="page.faceCapture.or.text" />
                  </p>
                  <p>
                    <span
                      className="tvp-link"
                      onClick={() => navigate(getEndpoint(ProctoringRoute.FaceCaptureInstructions))}
                    >
                      <FormattedMessage id="page.faceCapture.link" />
                    </span>{" "}
                    <FormattedMessage id="page.faceCapture.link.text" />
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </Content>
      <FooterLogo />
    </Base>
  );
}

export default FaceCapture;
