import React, { useEffect, useState } from "react";
import Header from "components/Header";
import { FormattedMessage } from "react-intl";
import { RootState, useAppSelector } from "store/store";
import ErrorWrapper from "components/ErrorScreens/ErrorWrapper";
import { ErrorTypes } from "globals/enums";
import PermissionError from "components/ErrorScreens/PermissionError";
import CameraNotDetected from "components/ErrorScreens/CameraNotDetected";
import NetworkConnectivityError from "components/ErrorScreens/NetworkConnectivityError";
import TerminatedError from "components/ErrorScreens/TerminatedError";
import ProviewLoadingError from "components/ErrorScreens/ProviewLoadingError";
import NoShowError from "components/ErrorScreens/NoShowError";
import BrowserNotSupported from "components/ErrorScreens/BrowserNotSupported";
import { GlobalAppState } from "globals/interfaces";
import mixpanel from "config/mixpanel";
import ErrorInformationPage from "components/ErrorScreens/ErrorInformationPage";
import StreamingReconnection from "../components/ErrorScreens/StreamingReconnection";
import PermissionIcon from "../assets/img/permissions.png";
import StreamingDisconnection from "components/ErrorScreens/StreamingDisconnection";

type ErrorProps = {
  header: React.ReactNode;
  content: React.ReactNode;
};

const ErrorScreen = () => {
  const errorScreenName = useAppSelector((state: RootState) => state.app.uiErrorScreen);
  const appDetails: GlobalAppState = useAppSelector((state) => state.app);
  const validFrom = new Date(appDetails?.configuration?.valid_from);
  const [errorProps, setErrorProps] = useState<ErrorProps>({} as ErrorProps);
  const precheckStartTimeHour = Math.floor(appDetails.precheckStartTime / 60);
  const precheckStartTimeMinutes = appDetails.precheckStartTime % 60;

  useEffect(() => {
    if (
      errorScreenName !== ErrorTypes.NoShowError &&
      errorScreenName !== ErrorTypes.TerminatedError &&
      errorScreenName
    ) {
      mixpanel.track(errorScreenName);
    }
    if (!errorScreenName) {
      setErrorProps({
        header: (
          <Header
            title={<FormattedMessage id="component.proviewLoadingError.header" />}
            titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
          />
        ),
        content: <ProviewLoadingError />
      });
    }
    switch (errorScreenName) {
      case ErrorTypes.CameraInaccessibleError:
        setErrorProps({
          header: (
            <Header
              title={<FormattedMessage id="component.permissionError.header" />}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: <PermissionError />
        });
        break;
      case ErrorTypes.MicrophoneInaccessibleError:
        setErrorProps({
          header: (
            <Header
              title={<FormattedMessage id="component.permissionError.header" />}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: <PermissionError />
        });
        break;
      case ErrorTypes.NotificationsInaccessibleError:
        setErrorProps({
          header: (
            <Header
              title={<FormattedMessage id="component.permissionError.header" />}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: <PermissionError />
        });
        break;
      case ErrorTypes.CameraNotDetected:
        setErrorProps({
          header: (
            <Header
              title={<FormattedMessage id="component.cameraNotDetected.header" />}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: <CameraNotDetected />
        });
        break;
      case ErrorTypes.ProviewLoadingError:
        setErrorProps({
          header: (
            <Header
              title={<FormattedMessage id="component.proviewLoadingError.header" />}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: <ProviewLoadingError />
        });
        break;
      case ErrorTypes.NoShowError:
        setErrorProps({
          header: (
            <Header
              title={""}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: <NoShowError />
        });
        break;
      case ErrorTypes.TerminatedError:
        setErrorProps({
          header: (
            <Header
              title={""}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: <TerminatedError />
        });
        break;
      case ErrorTypes.NetworkConnectivityErrDetails:
        setErrorProps({
          header: (
            <Header
              title={<FormattedMessage id="component.networkConnectivityError.header" />}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: <NetworkConnectivityError />
        });
        break;
      case ErrorTypes.BrowserNotSupported:
        setErrorProps({
          header: (
            <Header
              title={<FormattedMessage id="component.browserNotSupported.header" />}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: <BrowserNotSupported />
        });
        break;
      case ErrorTypes.SessionCompleted:
        setErrorProps({
          header: (
            <Header
              title={""}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: (
            <ErrorInformationPage
              heading={<FormattedMessage id="component.sessionCompletedError.header" />}
              subheading={<FormattedMessage id="component.sessionCompletedError.subheader" />}
            />
          )
        });
        break;
      case ErrorTypes.SlotTimeAlreadyPassed:
        setErrorProps({
          header: (
            <Header
              title={""}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: (
            <ErrorInformationPage
              heading={<FormattedMessage id="component.slotTimePassedError.header" />}
              subheading={<FormattedMessage id="component.slotTimePassedError.subheader" />}
            />
          )
        });
        break;
      case ErrorTypes.TestNotStarted:
        setErrorProps({
          header: (
            <Header
              title={""}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: (
            <ErrorInformationPage
              heading={
                <>
                  <FormattedMessage id="component.testNotStartedError.header" />
                  {validFrom.toLocaleString(undefined, {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: false
                  })}
                </>
              }
              subheading={
                <>
                  {precheckStartTimeHour || precheckStartTimeMinutes ? (
                    <>
                      <FormattedMessage id="component.testNotStartedError.subheader-1" />
                      {precheckStartTimeHour ? (
                        <>
                          {precheckStartTimeHour} <FormattedMessage id="component.hour" />
                        </>
                      ) : null}
                      {precheckStartTimeMinutes ? (
                        <>
                          {precheckStartTimeMinutes} <FormattedMessage id="component.minutes" />
                        </>
                      ) : null}
                      <FormattedMessage id="component.testNotStartedError.subheader-2" />
                    </>
                  ) : null}
                </>
              }
            />
          )
        });
        break;
      case ErrorTypes.SessionNotScheduled:
        setErrorProps({
          header: (
            <Header title={""} titleClass={"text-sm font-semibold clr-red uppercase ml-2.5"} />
          ),
          content: (
            <ErrorInformationPage
              heading={<FormattedMessage id="component.sessionNotScheduledError.header" />}
              subheading={<FormattedMessage id="component.sessionNotScheduledError.subheader" />}
            />
          )
        });
        break;
      case ErrorTypes.SessionExpired:
        setErrorProps({
          header: (
            <Header
              title={<FormattedMessage id="component.proviewLoadingError.header" />}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: (
            <div className="tvp-h-auto tvp-bg-white tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-justify-center tvp-ml-auto tvp-mr-auto">
              <div className="tvp-w-full tvp-flex tvp-flex-row tvp-justify-around">
                <img
                  className="tvp-permissionIcon tvp-mt-5"
                  src={PermissionIcon}
                  alt="permissionIcon"
                />
              </div>
              <div className="tvp-text-center tvp-mx-auto tvp-mt-10 tvp-text-base">
                <p>{<FormattedMessage id="component.sessionExpired.segment-1.content" />}</p>
              </div>
            </div>
          )
        });
        break;
      case ErrorTypes.MediaStreamError:
        setErrorProps({
          header: (
            <Header
              title={""}
              titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            />
          ),
          content: (
            <ErrorInformationPage
              heading={""}
              subheading={<FormattedMessage id="component.mediaStream.ErrorMessage" />}
            />
          )
        });
        break;
      case ErrorTypes.StreamingReconnection:
        setErrorProps({
          header: (
            <Header
              title={<FormattedMessage id="component.proviewLoadingError.header" />}
              titleClass={"text-sm font-semibold clr-red uppercase ml-2.5"}
            />
          ),
          content: <StreamingReconnection />
        });
        break;
      default:
    }
  }, [errorScreenName, appDetails?.fireBaseState]);
  return <ErrorWrapper {...errorProps}></ErrorWrapper>;
};

export default ErrorScreen;
