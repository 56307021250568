import { useEffect, useRef } from "react";
import RenderHtmlContent from "components/Chat/RenderHtmlContent";
import dayjs from "dayjs";
import { Role } from "globals/enums";

type MessageListProps = {
  chatMessages: any;
};

const MessageList = (props: MessageListProps) => {
  const messageEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageEl && messageEl.current && props.chatMessages && props.chatMessages.length) {
      messageEl.current.scrollTop = messageEl.current.scrollHeight;
    }
  }, [props.chatMessages]);

  return (
    <div className="tvp-chatContent" ref={messageEl}>
      <ul>
        {props?.chatMessages.map((message: any) => {
          if (message?.user?.name !== Role.Proctor) {
            return (
              <div className="tvp-msgHolder1" key={message.id}>
                <div className="tvp-list1">{message?.text}</div>
                <div className="tvp-msgDesc1">
                  <div className="tvp-msgRole">You</div>
                  <div className="tvp-timeStamp">
                    {message?.read_at
                      ? dayjs(message?.read_at).format("hh:mm A")
                      : dayjs(message?.received_at).format("hh:mm A")}{" "}
                    | {message?.read_at ? "read" : "sent"}
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="tvp-msgHolder2" key={message.id}>
                <div className="tvp-list2" style={{backgroundColor: "#3b82f6"}}>
                  {message?.text && <RenderHtmlContent message={message.text} />}
                </div>
                <div className="tvp-msgDesc2">
                  <div className="tvp-msgRole">Proctor</div>
                  <div className="tvp-timeStamp">
                    {dayjs(message?.received_at).format("hh:mm A")}{" "}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default MessageList;
