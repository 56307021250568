import React, { useState, useEffect } from "react";
import Button from "components/Button";
import CheckIcon from "assets/img/check.svg";
import FooterLogo from "components/FooterLogo";
import Header from "components/Header";
import LoaderGreen from "assets/img/loader_green.gif";
import errorLogo from "assets/img/error.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { GlobalAppState } from "globals/interfaces";
import { FormattedMessage } from "react-intl";
import { PrecheckState, VerificationType } from "globals/enums";
import { PreCheckItem, useGetPrecheckQuery } from "services/precheck";
import { setFireBaseState, setUiErrorScreen } from "store/app.slice";
import Countdown from "components/Countdown";
import { filterPrecheckData, getErrorType } from "../helpers/precheck-helper";
import { getEndpoint, ProctoringRoute } from "routes/route";
import { precheckPollingInterval } from "../config/constant";
import { compareTime } from "helpers/compare-time";
import { recheckScreen } from "helpers/recheck-screen";

import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
import { pingApi, useCreatePingQuery } from "services/ping";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { AlertLogConfig } from "services/session-token";

type FallbackComponentProps = {
  setPhotoIdObject: (value: React.SetStateAction<PreCheckItem | undefined>) => void;
  setTestEnvObject: (value: React.SetStateAction<PreCheckItem | undefined>) => void;
};

export const FallbackComponent = ({
  setPhotoIdObject,
  setTestEnvObject
}: FallbackComponentProps) => {
  const appDetails: GlobalAppState = useAppSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isSuccess } = useGetPrecheckQuery(undefined, {
    pollingInterval: appDetails?.fireBaseConnectionState ? 0 : precheckPollingInterval // Polling interval set to 10 seconds if Firebase is disconnected
  });
  useEffect(() => {
    if (data && data.pre_check && isSuccess) {
      const photoIdState = filterPrecheckData(data?.pre_check, VerificationType.PhotoId);
      const testEnvState = filterPrecheckData(data?.pre_check, VerificationType.TestEnv);
      const fireBaseSessionStatus = data?.session_status;
      const terminationReason = data?.termination_reason;
      if (!appDetails?.fireBaseState.pre_check?.length || !appDetails?.fireBaseConnectionState) {
        setPhotoIdObject(photoIdState);
        setTestEnvObject(testEnvState);
        const updatedObj = {
          ...appDetails?.fireBaseState,
          pre_check: data.pre_check,
          status: fireBaseSessionStatus,
          termination_reason: terminationReason
        };
        dispatch(setFireBaseState(updatedObj));
        const sessionError = getErrorType(fireBaseSessionStatus);
        if (sessionError) {
          dispatch(setUiErrorScreen({ uiErrorScreen: sessionError as string }));
          navigate(getEndpoint(ProctoringRoute.Error));
        }
      }
    }
  }, [
    isSuccess,
    data,
    appDetails?.fireBaseState,
    setPhotoIdObject,
    setTestEnvObject,
    dispatch,
    appDetails?.fireBaseConnectionState
  ]);
  return null;
};

export const VerificationStatus = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const appDetails: GlobalAppState = useAppSelector((state) => state.app);
  const alertConfig: AlertLogConfig[] = appDetails.alertConfigs;
  const [photoIdObject, setPhotoIdObject] = useState<PreCheckItem>();
  const [testEnvObject, setTestEnvObject] = useState<PreCheckItem>();
  const [timeDifference, setTimeDifferece] = useState<number | null>(null);
  const [isVerificationSuccess, setIsVerificationSuccess] = useState(false);
  const [createPing] = pingApi.endpoints.createPing.useLazyQuery();
  const {
    configuration: { environment_check, id_verification_check },
    session: sessionDetails
  } = appDetails;

  const { data }: any = useCreatePingQuery(
    { session_token: sessionDetails?.session_token },
    { skip: !sessionDetails?.session_token }
  );

  const calculateTimeLeft = async (appDetails) => {
    const timeFromPing: any = await createPing({
      session_token: appDetails?.session?.session_token
    });
    if (timeFromPing.data) {
      const currentTime = new Date(`${timeFromPing.data}`);
      const examStartTime = new Date(appDetails?.configuration?.valid_from);
      return compareTime(currentTime, examStartTime);
    }
  };

  useEffect(() => {
    (async () => {
      const timeLeft = await calculateTimeLeft(appDetails);
      if (!appDetails?.configuration?.pre_flight_approval) {
        setTimeout(() => {
          navigate(getEndpoint(ProctoringRoute.PreFlightSuccess));
        }, timeLeft * 1000);
      }
      if (checkPrecheckState()) {
        setIsVerificationSuccess(true);
        const timeLeft = await calculateTimeLeft(appDetails);
        if (timeLeft >= 0) {
          setTimeDifferece(timeLeft);
          setTimeout(() => {
            navigate(getEndpoint(ProctoringRoute.PreFlightSuccess));
          }, timeLeft * 1000);
        } else {
          navigate(getEndpoint(ProctoringRoute.PreFlightSuccess));
        }
      }
      id_verification_check &&
        window.ProctorClient3 &&
        (window.ProctorClient3.isPhotoIdFailed = photoIdObject?.status === PrecheckState?.Rejected);
      environment_check &&
        window.ProctorClient3 &&
        (window.ProctorClient3.isTextEnvFailed = testEnvObject?.status === PrecheckState?.Rejected);
    })();
  }, [photoIdObject?.status, testEnvObject?.status, isVerificationSuccess]);

  useEffect(() => {
    if (isVerificationSuccess) {
      alertHelper(alertConfig).raiseAlert(AlertName.ProctorApprovalCompleted, dispatch);
    }
  }, [isVerificationSuccess])

  useEffect(() => {
    if (appDetails?.fireBaseState?.pre_check?.length) {
      const photoIdState = filterPrecheckData(
        appDetails?.fireBaseState?.pre_check,
        VerificationType.PhotoId
      );
      const testEnvState = filterPrecheckData(
        appDetails?.fireBaseState?.pre_check,
        VerificationType.TestEnv
      );
      setPhotoIdObject(photoIdState);
      setTestEnvObject(testEnvState);
    }
  }, [appDetails?.fireBaseState, photoIdObject, testEnvObject]);

  useEffect(() => {
    if (!(environment_check || id_verification_check)) {
      navigate(getEndpoint(ProctoringRoute.PreFlightSuccess));
    }
  }, [environment_check, id_verification_check]);

  const checkPrecheckState = () => {
    if (environment_check && id_verification_check) {
      return (
        photoIdObject?.status === PrecheckState.Approved &&
        testEnvObject?.status === PrecheckState.Approved
      );
    }
    else if (environment_check) return testEnvObject?.status === PrecheckState.Approved;
    else if (id_verification_check) return photoIdObject?.status === PrecheckState.Approved;
  };

  const handleReCheck = () => {
    const navigateToRecheckScreen = recheckScreen(photoIdObject?.status, testEnvObject?.status);
    navigateToRecheckScreen && navigate(getEndpoint(navigateToRecheckScreen));
  };

  return (
    <>
      <FallbackComponent setPhotoIdObject={setPhotoIdObject} setTestEnvObject={setTestEnvObject} />
      <Base>
        <Header
          title={<FormattedMessage id="page.Verification.header" />}
          titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
        />
        <Content>
          {!isVerificationSuccess ? (
            <div className="tvp-text-center">
              <h2>
                <FormattedMessage id="page.Verification.instruction.text" />
              </h2>
              <p className="tvp-mt-2">
                <FormattedMessage
                  id="page.Verification.instructions.textOne"
                  values={{ strong: (chunks) => <strong>{chunks}</strong> }}
                />
                <FormattedMessage
                  id="page.Verification.instructions.textTwo"
                  values={{ strong: (chunks) => <strong className="tvp-clr-red">{chunks}</strong> }}
                />
              </p>
            </div>
          ) : (
            <div>
              <h2>
                <FormattedMessage id="page.Verification.verificationSuccess" />
              </h2>
              <p className="tvp-mt-2">
                <FormattedMessage id="page.Verification.examStart.text" />
                {timeDifference && (
                  <strong className="tvp-clr-red tvp-ml-1">
                    <Countdown duration={timeDifference} data={data} />
                  </strong>
                )}
              </p>
            </div>
          )}
          <div className="tvp-ml-auto tvp-mr-auto tvp-w-55 tvp-h-fit">
            <div className="tvp-flex tvp-justify-between tvp-mt-8">
              <p>
                <FormattedMessage id="page.Verification.HardwareCheck.heading" />
              </p>
              {
                <p className="tvp-flex tvp-justify-between tvp-w-24" aria-live="polite">
                  <img className="tvp-mr-4 tvp-squre-25" src={CheckIcon} alt="Hardware Test" />
                  <FormattedMessage id="page.Verification.Success" />
                </p>
              }
            </div>
          </div>
          {id_verification_check && (
            <>
              <div className="tvp-ml-auto tvp-mr-auto tvp-w-55 tvp-h-fit">
                <div className="tvp-flex tvp-justify-between tvp-mt-8">
                  <p>
                    <FormattedMessage id="page.Verification.IDCardCheck.heading" />
                  </p>
                  {photoIdObject?.status === PrecheckState.Approved && (
                    <div className="tvp-flex tvp-justify-between tvp-w-24" aria-live="polite">
                      <img className="tvp-mr-4 tvp-squre-25" src={CheckIcon} alt="Photo Id" />
                      <FormattedMessage id="page.Verification.Success" />
                    </div>
                  )}
                  {(photoIdObject?.status === PrecheckState.Submitted ||
                    photoIdObject?.status === PrecheckState.Pending) && (
                      <div className="tvp-flex tvp-justify-between tvp-w-24" aria-live="polite">
                        <img className="tvp-mr-4 tvp-squre-25" src={LoaderGreen} alt="Photo Id" />
                        <FormattedMessage id="page.Verification.Verifying" />
                      </div>
                    )}
                  {photoIdObject?.status === PrecheckState.Rejected && (
                    <div aria-live="polite">
                      <p className="tvp-flex tvp-justify-between tvp-w-24">
                        <img className="tvp-mr-4 tvp-squre-25" src={errorLogo} alt="Photo Id" />
                        <FormattedMessage id="page.Verification.Rejected" />
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {photoIdObject?.status === PrecheckState.Rejected && photoIdObject?.reason && (
                <div className="tvp-flex tvp-justify-center" aria-live="polite">
                  <p className="tvp-clr-red tvp-ml-auto tvp-font-medium tvp-text-xs">
                    {photoIdObject?.reason}
                  </p>
                </div>
              )}
            </>
          )}

          {environment_check && (
            <>
              <div className="tvp-ml-auto tvp-mr-auto tvp-w-55 tvp-h-fit">
                <div className="tvp-flex tvp-justify-between tvp-mt-8">
                  <p>
                    <FormattedMessage id="page.Verification.EnvironmentCheck.heading" />
                  </p>
                  {testEnvObject?.status === PrecheckState.Approved && (
                    <p className="tvp-flex tvp-justify-between tvp-w-24" aria-live="polite">
                      <img
                        className="tvp-mr-4 tvp-squre-25"
                        src={CheckIcon}
                        alt="Envirnonment text"
                      />
                      <FormattedMessage id="page.Verification.Success" />
                    </p>
                  )}
                  {(testEnvObject?.status === PrecheckState.Submitted ||
                    testEnvObject?.status === PrecheckState.Pending) && (
                      <p className="tvp-flex tvp-justify-between tvp-w-24" aria-live="polite">
                        <img
                          className="tvp-mr-4 tvp-squre-25"
                          src={LoaderGreen}
                          alt="Envirnonment text"
                        />
                        <FormattedMessage id="page.Verification.Verifying" />
                      </p>
                    )}
                  {testEnvObject?.status === PrecheckState.Rejected && (
                    <p className="tvp-flex tvp-justify-between tvp-w-24" aria-live="polite">
                      <img
                        className="tvp-mr-4 tvp-squre-25"
                        src={errorLogo}
                        alt="Envirnonment text"
                      />
                      <FormattedMessage id="page.Verification.Rejected" />
                    </p>
                  )}
                </div>
              </div>
              {testEnvObject?.status === PrecheckState.Rejected && (
                <div className="tvp-flex tvp-justify-center tvp-mt-2" aria-live="polite">
                  <p className="tvp-clr-red tvp-font-medium tvp-text-xs">{testEnvObject?.reason}</p>
                </div>
              )}
            </>
          )}
          <Button
            label={<FormattedMessage id="button.Recheck" />}
            className={
              !(
                photoIdObject?.status === PrecheckState.Rejected ||
                testEnvObject?.status === PrecheckState.Rejected
              )
                ? "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-bg-lgray tvp-clr-dgray tvp-mx-auto tvp-cursor-none tvp-bdr-lgray tvp-mt-15"
                : "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-bg-white tvp-mx-auto tvp-clr-neutral tvp-bdr-green tvp-mt-15"
            }
            disabled={
              !(
                photoIdObject?.status === PrecheckState.Rejected ||
                testEnvObject?.status === PrecheckState.Rejected
              )
            }
            onClick={handleReCheck}
          />
        </Content>
        <FooterLogo />
      </Base>
    </>
  );
};

export default VerificationStatus;
