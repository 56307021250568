import React from "react";
import { photoIdCanvasDimensions } from "config/constant";
type Props = {
  height: number;
  width: number;
  videoRef: React.RefObject<HTMLVideoElement>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  captured?: boolean;
};
const Canvas: React.FC<Props> = (props) => {
  const photoCaptureRef = React.useRef<HTMLCanvasElement>(null);
  const { canvasWidth, canvasHeight } = photoIdCanvasDimensions;
  React.useEffect(() => {
    if (props.canvasRef.current) {
      const context = props.canvasRef.current.getContext("2d");
      if (context && props.videoRef.current) {
        context.drawImage(props.videoRef.current, 0, 0, canvasWidth, canvasHeight);
      }
    }
    const photoContext = photoCaptureRef.current?.getContext("2d");
    if (photoContext && props.videoRef.current) {
      photoContext.drawImage(props.videoRef.current, 0, 0, props.width, props.height);
    }
  }, []);

  return (
    <>
      <canvas
        role="img"
        style={{ display: "none" }}
        className="tvp-photoIdCanvas"
        ref={props.canvasRef}
        height={canvasHeight}
        width={canvasWidth}
      />
      <canvas
        role="img"
        className="tvp-photoIdCanvas"
        ref={photoCaptureRef}
        height={props.height}
        width={props.width}
      />
    </>
  );
};

export default Canvas;
