import { useEffect, useCallback } from "react";
import { AlertName, AlertType } from "helpers/alert-type";
import { useAppDispatch, useAppSelector } from "store/store";
import { SessionStatus } from "globals/enums";
import { enqueueItem as alertEnqueueItem } from "store/alertSlice";
import { isTsbBrowser } from "helpers/browser-helper";
import { getSystemTime } from "helpers/common";

const useTabCloseOrRefresh = () => {
  const alertQueue = useAppSelector((state) => state.alertQueue);
  const screenGrabQueue = useAppSelector((state) => state.screenGrabQueue);
  const cameraGrabQueue = useAppSelector((state) => state.cameraGrabQueue);
  const currentOfflineEvent = useAppSelector((state) => state.app.currentOfflineEvent);
  const sessionStatus = useAppSelector((state) => state.app.sessionStatus);
  const dispatch = useAppDispatch();

  const handleBeforeUnload = useCallback((e) => {
    e.preventDefault();
    e.returnValue = "";
    dispatch(
      alertEnqueueItem({
        alert_type_id: AlertType[AlertName.UserClosedRefreshed].id,
        description: AlertType[AlertName.UserClosedRefreshed].alertName,
        timestamp: getSystemTime().toISOString(),
        metadata: {
          alerts_queued: alertQueue?.items?.length || 0,
          screen_grabs_queued: screenGrabQueue?.items?.length || 0,
          camera_grabs_queued: cameraGrabQueue?.items?.length || 0,
          offline_video_chunks_queued: currentOfflineEvent.totalChunksQueued
        },
        sync: true
      })
    );
  }, []);

  useEffect(() => {
    if (sessionStatus === SessionStatus.Completed || sessionStatus === SessionStatus.Terminated) {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [sessionStatus]);

  const handleUnload = () => {
    window.addEventListener("beforeunload", handleBeforeUnload);
  };

  useEffect(() => {
    if (!isTsbBrowser()) {
      handleUnload();
    }
  }, []);

  return [sessionStatus];
};
export default useTabCloseOrRefresh;
