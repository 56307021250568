import { FormattedMessage } from "react-intl";
import { useAppDispatch } from "store/store";
import { ScreenShareStatus } from "globals/enums";
import { updateScreenGrab } from "services/screenShare";
import Button from "components/Button";

type ScreenShareErrorPropType = {
  screenShareError?: string;
};
const Error = ({ screenShareError }: ScreenShareErrorPropType) => {
  const dispatch = useAppDispatch();

  const start = () => {
    dispatch(updateScreenGrab({ screenShareStatus: ScreenShareStatus.ReInitializePermission }));
  };

  return (
    <div className="tvp-w-full tvp-h-auto tvp-bg-white  tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-justify-center">
      <span>
        <h3 className="tvp-my-3 tvp-font-bold tvp-text-xl tvp-text-center tvp-clr-neutral">
          <FormattedMessage id="component.screenShareError.segment-1.title" />
        </h3>
      </span>
      {screenShareError === "Permission denied by system" ? (
        <div className="tvp-text-center tvp-w-5/6 tvp-mx-auto">
          <p>
            <FormattedMessage id="component.screenShareError.SystemPermissionDenied.content" />
          </p>
        </div>
      ) : screenShareError === "Permission denied" ? (
        <>
          <div className="tvp-text-center tvp-w-5/6 tvp-mx-auto">
            <p>
              <FormattedMessage id="component.screenShareError.UserPermissionDenied.content" />
            </p>
          </div>
          <Button
            onClick={start}
            label={<FormattedMessage id="component.screenShareError.button" />}
            className={
              "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green tvp-w-150 tvp-mt-4"
            }
          />
        </>
      ) : (
        <>
          <div className="tvp-text-center tvp-w-5/6 tvp-mx-auto">
            <p>
              <FormattedMessage id="component.screenShareError.segment-1.content" />
            </p>
            <p className="tvp-mb-4">
              <FormattedMessage id="component.screenShareError.segment-1.content-2" />
            </p>
          </div>
          <Button
            onClick={start}
            label={<FormattedMessage id="component.screenShareError.button" />}
            className={
              "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green tvp-w-150 tvp-mt-4"
            }
          />
        </>
      )}
    </div>
  );
};

export default Error;
