import React from "react";
import arrow from "assets/img/arrow.svg";
import { FormattedMessage } from "react-intl";

type ListInstructionProps = {
  tips?: boolean;
  className: string;
  instList: {
    id: number;
    item: string | React.ReactNode;
    altText?: string;
  }[];
};

function ListInstruction(props: ListInstructionProps) {
  return (
    <div className={props.className}>
      {props.tips && (
        <h4 className="tvp-tips tvp-mb-4">
          <FormattedMessage id="page.faceCaptureInstruction.tips" />
        </h4>
      )}
      {props.instList.map((item) => {
        return (
          <li key={item.id}>
            <div className={"tvp-flex tvp-flex-row tvp-mr-2.5"}>
              <img
                className={"tvp-flex tvp-flex-row tvp-w-3 tvp-h-3 tvp-mt-1.5"}
                src={arrow}
                alt={item.altText}
              />
            </div>
            <div className={"tvp-w-11/12"}>{item.item}</div>
          </li>
        );
      })}
    </div>
  );
}

export default ListInstruction;
