import React from "react";
import parse from "html-react-parser";
import { ProctoringRoute, getEndpoint } from "routes/route";
import Button from "components/Button";
import { FormattedMessage } from "react-intl";
import FooterLogo from "components/FooterLogo";
import Header from "components/Header";
import ListInstruction from "components/ListInstruction";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { GlobalAppState } from "globals/interfaces";
import { nextScreen } from "routes/workflow";

import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
import { alertHelper } from "helpers/alert-helper";
import { AlertLogConfig } from "services/session-token";
import { AlertName } from "helpers/alert-type";
const Instructions = () => {
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const candidateInstructions = globalState?.session.instruction;
  const additionalInstruction = globalState.clientConfiguration.additionalInstruction;
  const alertConfig: AlertLogConfig[] = globalState.alertConfigs;
  const navigate = useNavigate();
  const instlist = [
    {
      id: 1,
      item: <FormattedMessage id="page.welcome.instructions.textOne" />,
      altText: "Arrow indicating instruction one"
    },
    {
      id: 2,
      item: <FormattedMessage id="page.welcome.instructions.textTwo" />,
      altText: "Arrow indicating instruction two"
    },
    {
      id: 3,
      item: <FormattedMessage id="page.welcome.instructions.textThree" />,
      altText: "Arrow indicating instruction three"
    }
  ];

  const instructions = () => {
    if (additionalInstruction) {
      return parse(additionalInstruction);
    }
    if (candidateInstructions) {
      return parse(candidateInstructions);
    }
    return null;
  };

  const handleNext = () => {
    const getNextScreen = nextScreen(
      ProctoringRoute.Instructions,
      globalState.configuration,
      globalState.session.session_type
    );
    alertHelper(alertConfig).raiseAlert(AlertName.InstructionsProceeded, dispatch);

    navigate(getEndpoint(getNextScreen));
  };

  return (
    <Base>
      <Header
        title={<FormattedMessage id="page.instruction.header" />}
        titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
        tabIndex={1}
      />
      <Content>
        <ListInstruction instList={instlist} tips={false} className="" />
        <div className="tvp-h-40 tvp-w-full tvp-overflow-y-auto tvp-bg-lgray-500 tvp-my-4 tvp-mx-auto tvp-p-4 tvp-px-2">
          {instructions()}
        </div>
        <Button
          label={<FormattedMessage id="button.proceed" />}
          className={
            "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green tvp-w-150"
          }
          onClick={handleNext}
          tabIndex={2}
        />
      </Content>
      <FooterLogo />
    </Base>
  );
};

export default Instructions;
