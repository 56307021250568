/* eslint-disable */
import { createApi, BaseQueryFn } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import type { AxiosRequestConfig, AxiosError } from "axios";

import { appId, platformStreamApiBaseUrl } from "config/constant";

export interface VideoFilePayload {
  blob: Blob;
  sessionToken: string;
  startTime: string;
}

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await axios({ url: baseUrl + url, method, data, params, headers });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message
        }
      };
    }
  };

export const platformStreamAPI = createApi({
  reducerPath: "platformStreamAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: platformStreamApiBaseUrl!
  }),
  endpoints: (builder) => ({
    videoFile: builder.mutation({
      query: (payload: VideoFilePayload) => {
        const formdata = new FormData();
        formdata.append("session_token", payload.sessionToken);
        formdata.append("start_timestamp", payload.startTime);
        formdata.append(
          "video_file",
          new Blob([payload.blob], { type: "video/webm" }),
          "video_file.webm"
        );
        return {
          url: "/v1/video-file",
          method: "POST",
          headers: {
            "app-id": appId as string
          },
          data: formdata
        };
      },
      transformResponse(response: any) {
        return response;
      },
      transformErrorResponse(errorResponse: any) {
        return errorResponse.data.message;
      }
    })
  })
});

export const { useVideoFileMutation } = platformStreamAPI;
