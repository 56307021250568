import { useEffect } from "react";
import { useAppSelector } from "store/store";
import { useNavigate } from "react-router-dom";
import { ScreenShareStatus, SecondaryDeviceStatus } from "globals/enums";
import { ProctoringRoute, getEndpoint } from "routes/route";
import { showProview } from "helpers/showProview";

const useSecondaryDevice = () => {
  const navigate = useNavigate();
  const globalState = useAppSelector((state) => state.app);
  const { screenShareStatus } = useAppSelector((state) => state.screenShare);
  const {
    fireBaseState: { secondary_device_status },
    configuration: { secondary_camera }
  } = globalState;

  useEffect(() => {
    if (
      secondary_camera &&
      secondary_device_status === SecondaryDeviceStatus.Disconnected &&
      screenShareStatus !== ScreenShareStatus.ScreenShareStopped &&
      screenShareStatus !== ScreenShareStatus.PermissionFailed &&
      screenShareStatus !== ScreenShareStatus.ReInitializePermission
    ) {
      showProview();
      navigate(getEndpoint(ProctoringRoute.SecondaryDevice));
    }
  }, [secondary_device_status, screenShareStatus]);
};

export default useSecondaryDevice;
