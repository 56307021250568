import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "store/store";

interface FirebaseCustomTokenResponse {
  customToken: string;
}

const firebaseCustomTokenApi = createApi({
  reducerPath: "firebaseCustomTokenApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PROCTORING_API_BASE_URL as string,
    prepareHeaders: (headers: Headers, { getState }) => {
      headers.set("app-id", process.env.REACT_APP_APP_ID as string);
      const session_token = (getState() as RootState).app.session.session_token;
      headers.set("session-token", session_token);
      return headers;
    }
  }),
  endpoints: (builder) => ({
    firebaseCustomToken: builder.query({
      query: () => ({
        url: "/v1/custom-firebase-token/attendee",
        method: "POST"
      }),
      transformResponse: (response: FirebaseCustomTokenResponse) => {
        return response.customToken;
      }
    })
  })
});

export const { useFirebaseCustomTokenQuery } = firebaseCustomTokenApi;

export default firebaseCustomTokenApi;
