import React from "react";
import { FormattedMessage } from "react-intl";

function NoShowError() {
  return (
    <div className="tvp-w-full tvp-h-auto tvp-bg-white  tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-justify-center">
      <span>
        <h3 className="tvp-my-3 tvp-font-bold tvp-text-xl tvp-text-center tvp-clr-neutral">
          <FormattedMessage id="component.noshowError.segment-1.title" />
        </h3>
      </span>
      <div className="tvp-text-center tvp-w-10/12 tvp-ml-auto tvp-mr-auto">
        <p>
          <FormattedMessage id="component.noshowError.segment-1.content" />
        </p>
      </div>
    </div>
  );
}

export default NoShowError;
