import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { AlertLogConfig } from "services/session-token";

const useDetectPrintScreen = () => {
  const dispatch = useAppDispatch();
  const alertConfig: AlertLogConfig[] = useSelector((state: RootState) => state.app.alertConfigs);
  const precheckSuccess = useSelector((state: RootState) => state.app.precheckSuccess);

  const handlePrintEvent = () => {
    alertHelper(alertConfig).raiseAlert(AlertName.PrintScreenClicked, dispatch);
  };

  const stop = () => {
    window.removeEventListener("beforeprint", handlePrintEvent);
  };

  const detectPrintScreen = () => {
    if (precheckSuccess) {
      window.addEventListener("beforeprint", handlePrintEvent);
    } else {
      stop();
    }
  };

  useEffect(() => {
    detectPrintScreen();
    return () => {
      stop();
    };
  }, [precheckSuccess]);
};

export default useDetectPrintScreen;
