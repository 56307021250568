import { useEffect, useState } from "react";
import MessageAudioBox from "components/Chat/MessageAudioBox";
import FirebaseMessageList from "./FirebaseMessageList";
import { useAppSelector } from "store/store";
import UseGetZoomLevel from "hooks/UseGetZoomLevel";
import Closebtn from "assets/img/chatClose.svg";
import Loader from "assets/img/loader_green.gif";
import { getDatabase, ref, onValue, push, set } from "firebase/database";

import { markCandidateMessagesAsReadInFirebase } from "./helpers";

import { v4 as uuidv4 } from "uuid";
import MessageInput from "./MessageInput";
import { ChatType, firebaseCollections } from "globals/enums";
import { getSystemTime } from "helpers/common";

type ChatProps = {
  chatOpen?: boolean;
  handleChatOpen: () => void;
  handleChatClose: () => void;
};

type Message = {
  created_at: string;
  id: string;
  read_at?: string;
  received_at: string;
  role?: string;
  text?: string;
  updated_at?: string;
  user_id?: number;
  type?: string;
  url?: string;
};

const FirebaseChatView = (Props: ChatProps) => {
  const configuration = useAppSelector((state) => state.app.configuration);
  const [chatMessages, setChatMessages] = useState<any[]>([]);
  const { resizeLevel } = UseGetZoomLevel();

  const sessionId = useAppSelector((state) => state.app?.session?.session_id);
  const messageRef = ref(getDatabase(), `${firebaseCollections.SessionV2}/${sessionId}/messages/`);

  const filterChatMessages = (messages: Message[]) =>
    messages.filter((message: Message) => message?.type !== ChatType.RECORDING);

  useEffect(() => {
    return onValue(messageRef, (messageSnapShot) => {
      const chatMessages = messageSnapShot.val() || {};
      const messages = filterChatMessages(Object.values(chatMessages));
      setChatMessages(messages);
      const proctorMessages = messages.filter((message: Message) => message?.role !== "candidate");
      if (proctorMessages.length) {
        Props.handleChatOpen();
        markCandidateMessagesAsReadInFirebase(sessionId, chatMessages);
      }
    });
  }, []);

  const handleSendMessage = async (inputText: string) => {
    if (inputText?.trim() !== "") {
      try {
        const messageData = {
          created_at: getSystemTime().toISOString(),
          id: uuidv4(),
          role: "candidate",
          text: inputText
        };
        await set(push(messageRef), messageData);
      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  return (
    <>
      {Props.chatOpen && (
        <div
          className={resizeLevel >= 11 ? "tvp-resizeChatScreen" : "tvp-chatScreen"}
          data-testid="chat-screen"
          aria-live="polite"
        >
          <div className="tvp-chatHeader" aria-labelledby="chat-heading" role="dialog">
            <h2 id="chat-heading">Talk to Proctor</h2>
            <div className="tvp-chatButtonWrapper">
              {" "}
              {configuration.audio_call && <MessageAudioBox />}
              <button
                onClick={Props.handleChatClose}
                className="tvp-button-no-border tvp-closeChat"
              >
                <img src={Closebtn} alt="close chat" />
              </button>
            </div>
          </div>

          {chatMessages ? (
            chatMessages?.length ? (
              <FirebaseMessageList chatMessages={chatMessages} />
            ) : (
              <div className="tvp-flex tvp-flex-col tvp-flex-grow"></div>
            )
          ) : (
            <img className="tvp-miniLoader" src={Loader} alt="loader" />
          )}
          <MessageInput handleSendMessage={handleSendMessage} active={true} />
        </div>
      )}
    </>
  );
};

export default FirebaseChatView;
