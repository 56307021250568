/* eslint-disable */
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useRefreshSASTokenMutation } from "services/session-token";
import { getSystemTime } from "helpers/common";

const useRefreshSASToken = () => {
  const app = useSelector((state: RootState) => state.app);
  const [refreshSASToken] = useRefreshSASTokenMutation();
  React.useEffect(() => {
    if (app.storage?.sas_token) {
      setInterval(() => {
        refreshSASToken({
          session_token: app.session.session_token,
          session_uuid: app.session.session_uuid,
          sas_token: app.storage?.sas_token!
        }).unwrap();
      }, new Date(app.storage.sas_expiries_in).getTime() - getSystemTime().getTime() - 60000);
    }
  }, [app.storage?.sas_token]);
};

export default useRefreshSASToken;
