import { defaultColor } from "config/constant";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/store";
const BrandingContainer = () => {
  const { configuration, verificationCodeSuccess } = useAppSelector((state) => state.app);
  const { brand_color, brand_logo } = configuration;
  const [brandLogo, setBrandLogo] = useState("");

  const handleBrandColor = (color: string) => {
    const root = document.querySelector(":root") as HTMLElement;
    root.style.setProperty("--brand-color", color);
  };

  const handleBrandLogo = (logo: string) => {
    if (!logo) return;
    setBrandLogo(logo);
  };

  useEffect(() => {
    if (brand_color) {
      handleBrandColor(brand_color);
    } else if (verificationCodeSuccess) {
      handleBrandColor(defaultColor);
    }
    if (brand_logo) {
      handleBrandLogo(brand_logo);
    }
  }, [verificationCodeSuccess, brand_color, brand_logo]);

  return (
    <>
      {brandLogo && (
        <div id="logo-container" className="tvp-flex">
          <img id="brand-logo" src={brandLogo} alt="brand logo" />
        </div>
      )}
    </>
  );
};
export default BrandingContainer;
