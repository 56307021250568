import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { AlertLogConfig } from "services/session-token";

const useDetectTabSwitch = () => {
  const dispatch = useAppDispatch();
  const alertConfig: AlertLogConfig[] = useSelector((state: RootState) => state.app.alertConfigs);
  const precheckSuccess = useSelector((state: RootState) => state.app.precheckSuccess);

  const browserPrefixes = ["moz", "ms", "o", "webkit"];

  const [isVisible, setIsVisible] = useState(true);

  // get the correct attribute name
  function getHiddenPropertyName(prefix) {
    return prefix ? prefix + "Hidden" : "hidden";
  }

  // get the correct event name
  function getVisibilityEvent(prefix) {
    return (prefix ? prefix : "") + "visibilitychange";
  }

  // get current browser vendor prefix
  function getBrowserPrefix() {
    for (let i = 0; i < browserPrefixes.length; i++) {
      if (getHiddenPropertyName(browserPrefixes[i]) in document) {
        // return vendor prefix
        return browserPrefixes[i];
      }
    }

    // no vendor prefix needed
    return null;
  }

  // bind and handle events
  const browserPrefix = getBrowserPrefix(),
    hiddenPropertyName = getHiddenPropertyName(browserPrefix),
    visibilityEventName = getVisibilityEvent(browserPrefix);

  function onVisible() {
    // prevent double execution
    if (!isVisible) {
      setIsVisible(true);
    }
  }

  function onHidden() {
    // prevent double execution
    if (isVisible) {
      setIsVisible(false);
    }
  }

  function handleVisibilityChange(forcedFlag) {
    // if iframe element on the document is on focus, return focus back to window
    if (document.activeElement.nodeName === "IFRAME" && !document[hiddenPropertyName]) {
      return window.focus();
    }
    // forcedFlag is a boolean when this event handler is triggered by a
    // focus or blur event otherwise it's an Event object
    if (typeof forcedFlag === "boolean") {
      if (forcedFlag) {
        return onVisible();
      }

      return onHidden();
    }

    if (document[hiddenPropertyName]) {
      return onHidden();
    }

    return onVisible();
  }

  const stop = () => {
    document.removeEventListener(visibilityEventName, handleVisibilityChange);
    document.removeEventListener("focus", handleVisibilityChange);
    document.removeEventListener("blur", handleVisibilityChange);
    window.removeEventListener("focus", handleVisibilityChange);
    window.removeEventListener("blur", handleVisibilityChange);
  };

  const detectBrowserTabSwitch = async () => {
    document.addEventListener(visibilityEventName, handleVisibilityChange, false);

    // extra event listeners for better behaviour
    document.addEventListener(
      "focus",
      function () {
        handleVisibilityChange(true);
      },
      false
    );

    document.addEventListener(
      "blur",
      function () {
        handleVisibilityChange(false);
      },
      false
    );

    window.addEventListener(
      "focus",
      function () {
        handleVisibilityChange(true);
      },
      false
    );

    window.addEventListener(
      "blur",
      function () {
        handleVisibilityChange(false);
      },
      false
    );
  };

  useEffect(() => {
    if (precheckSuccess) {
      detectBrowserTabSwitch();
    } else {
      stop();
    }

    // Clean up the event listener when the component unmounts
    return () => {
      stop();
    };
  }, [precheckSuccess, isVisible]); // Add isVisible as a dependency

  // Raise alert when visibility changes
  useEffect(() => {
    if (!alertConfig.length || !precheckSuccess) return;
    if (!isVisible) {
      alertHelper(alertConfig).raiseAlert(AlertName.WindowFocusOut, dispatch);
    } else {
      alertHelper(alertConfig).raiseAlert(AlertName.WindowFocusIn, dispatch);
    }
  }, [isVisible, alertConfig, dispatch]);

  return isVisible; // Return isVisible state variable if needed outside the hook
};

export default useDetectTabSwitch;
