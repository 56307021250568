import React from "react";
import Select from "react-select";

type DropdownProps = {
  list: string[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  inputId?: string;
  ariaLabelledby?: string;
  tabIndex?: number;
};

const Dropdown = (props: DropdownProps) => {
  const { list, onChange } = props;
  const handleChange = (event: any) => {
    onChange(event);
  };

  return (
    <div>
      {list.length && (
        <Select
          className="basic-single"
          aria-labelledby={props.ariaLabelledby}
          inputId={props.inputId}
          classNamePrefix="select"
          defaultValue={list.map((item) => ({ label: item, value: item }))[0]}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={false}
          name="color"
          options={list.map((item) => ({ label: item, value: item }))}
          onChange={(event) => handleChange(event)}
          tabIndex={props.tabIndex}
        />
      )}
    </div>
  );
};

export default Dropdown;
