import { FormattedMessage } from "react-intl";
import React from "react";

type Props = {
  label: string | undefined;
  mediaError?: string | null;
  loadingIcon: string | undefined;
  failureIcon: string | undefined;
  successIcon: string | undefined;
  state: boolean | null;
  loadingState: boolean | null;
  errorAltText: string;
  successAltText: string;
};

const HardwareTestIcon: React.FC<Props> = (props) => {
  return (
    <span className="tvp-flex tvp-flex-col tvp-items-center">
      {props.loadingState === true || props.loadingState === null ? (
        <img className="tvp-w-14" src={props.loadingIcon} alt={props.label} />
      ) : (
        <img
          alt={props.state && !props.mediaError ? props.successAltText : props.errorAltText}
          className="tvp-w-14"
          src={props.state && !props.mediaError ? props.successIcon : props.failureIcon}
        />
      )}
      <p>
        <FormattedMessage id={props.label} />
      </p>
    </span>
  );
};

export default HardwareTestIcon;
