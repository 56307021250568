import { getEndpoint, ProctoringRoute } from "routes/route";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import AudioProgressBar from "components/AudioProgressBar";
import Button from "components/Button";
import FooterLogo from "components/FooterLogo";
import Header from "components/Header";
import ListInstruction from "components/ListInstruction";
import LoaderGreen from "assets/img/loader_green.gif";
import TickGreen from "assets/img/tick_green.png";
import { MediaStreamErrorNames } from "globals/enums";
import { useNavigate } from "react-router-dom";
import warningIconSmall from "assets/img/warning_icon_small.png";
import useDetectAudio from "hooks/useDetectAudio";
import { AlertLogConfig } from "services/session-token";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { useAppDispatch, useAppSelector } from "store/store";
import { GlobalAppState } from "globals/interfaces";
import { nextScreen } from "routes/workflow";
import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
import { MediaContext } from "context/MediaContext";
import { hideProview } from "../helpers/hideProview";

const AudioDetection = () => {
  const navigate = useNavigate();
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const [isAudioDetectionFailed] = useState(false);
  const [startRecording, setStartRecording] = useState<boolean>(false);
  const [audioProgressWidth, setAudioProgressWidth] = useState<number>(0);
  const { audioStream, mediaStreamError } = useContext(MediaContext);
  const dispatch = useAppDispatch();
  const instlist = [
    {
      id: 1,
      item: <FormattedMessage id="page.audioDetection.main.text" />,
      altText: "Arrow indicating instruction one"
    },
    {
      id: 2,
      item: <FormattedMessage id="page.audioDetection.instruction.text" />,
      altText: "Arrow indicating instruction two"
    },
  ];
  const [audioVolumeLevel, audioDetectionPass, audioDetecting] = useDetectAudio(
    0,
    startRecording,
    audioStream
  );

  useEffect(() => {
    if (startRecording) {
      setAudioProgressWidth(audioVolumeLevel as number);
    }
  });

  const handleStartRecording = () => {
    setStartRecording(true);
  };

  useEffect(() => {
    if (audioDetectionPass) {
      (async () => {
        try {
          alertHelper(alertConfig).raiseAlert(AlertName.AudioTestPassed, dispatch);
          if (
            mediaStreamError &&
            mediaStreamError.message === MediaStreamErrorNames.AudioHardwareFailedMessage &&
            mediaStreamError.name === MediaStreamErrorNames.AudioHardwareFailedError
          ) {
            // Note : Once recorder screen is rendered, The exam screen already rendered so that we don't require to navigate to recorder screen again just need to hide the proview.
            hideProview();
          } else {
            const getNextScreen = nextScreen(
              ProctoringRoute.AudioDetection,
              globalState.configuration,
              globalState.session.session_type
            );
            navigate(getEndpoint(getNextScreen));
          }
        } catch (e: any) {
          // capture exception
        }
      })();
    }
  }, [audioDetectionPass]);

  return (
    <Base>
      <Header
        title={<FormattedMessage id="page.audioDetection.header" />}
        titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
        tabIndex={1}
      />
      <Content>
        <div className="tvp-my-3">
          <ListInstruction instList={instlist} tips={true} className="tvp-max-w-[400px] tvp-mx-auto" />
          <div className="tvp-my-8 tvp-text-center">
            <AudioProgressBar width={audioProgressWidth} />
          </div>
          {audioDetecting && (
            <p className="tvp-flex tvp-justify-center tvp-clr-neutral tvp-mb-2" aria-live="polite">
              <img className="tvp-squre-25 tvp-mr-4" src={LoaderGreen} alt="LoaderGreen" />
              <FormattedMessage id="page.audioDetection.audioDetecting.text" />
            </p>
          )}
          {audioDetectionPass && (
            <p className="tvp-flex tvp-justify-center tvp-clr-green tvp-mb-2" aria-live="polite">
              <img className="tvp-squre-25 tvp-mr-4" src={TickGreen} alt="TickGreen" />
              <FormattedMessage id="page.audioDetection.audioDetectionPass.text" />
            </p>
          )}
          {isAudioDetectionFailed && (
            <p className="tvp-flex tvp-justify-center tvp-clr-red tvp-mb-2" aria-live="polite">
              <img className="tvp-squre-25 tvp-mr-4" src={warningIconSmall} alt="CheckIcon" />
              <FormattedMessage id="page.audioDetection.audioDetectionFailed.text" />
            </p>
          )}
          <div className="tvp-buttonPart">
            <Button
              label={<FormattedMessage id="page.audioDetection.instructions.button" />}
              className={
                "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-bg-white tvp-clr-neutral tvp-bdr-green tvp-m-1"
              }
              onClick={() => navigate(getEndpoint(ProctoringRoute.HardwareTestInstructions))}
            />
            {isAudioDetectionFailed ? (
              <Button
                label={<FormattedMessage id="page.audioDetection.recordAgain.button" />}
                className="tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-bg-red tvp-bdr-red tvp-m-1"
                onClick={() => null}
              />
            ) : (
              <Button
                label={<FormattedMessage id="page.audioDetection.startRecording.button" />}
                className={
                  startRecording
                    ? "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-bg-lgray tvp-clr-dgray tvp-cursor-none tvp-bdr-lgray tvp-m-1"
                    : "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-bg-green tvp-bdr-green tvp-m-1"
                }
                disabled={startRecording}
                onClick={handleStartRecording}
              />
            )}
          </div>
        </div>
      </Content>
      <FooterLogo />
    </Base>
  );
};

export default AudioDetection;
