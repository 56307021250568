import ChatButton from "components/Chat/ChatButton";
import ChatView from "components/Chat/ChatView";
import ComponentToCloseOnClickOutside from "components/ComponentToCloseOnClickOutside";
import { useAppSelector, useAppDispatch } from "store/store";
import { setProctorCandidateChat } from "store/app.slice";
import FirebaseChatView from "./FirebaseChatView";
import { VideoProvider } from "globals/enums";

const ChatWrapper = () => {
  const dispatch = useAppDispatch();
  const globalStateChat = useAppSelector((state) => state.app.showProctorCandidateChat);
  const isTwilioStreaming = useAppSelector((state) => state.app?.configuration?.twilio_streaming);
  const streamingProvider = useAppSelector((state) => state.app?.configuration?.streaming_provider);

  const isFirebaseChat =
    isTwilioStreaming ||
    streamingProvider === VideoProvider.TWILIO ||
    streamingProvider === VideoProvider.LIVEKIT
      ? !(isTwilioStreaming && streamingProvider === VideoProvider.PLATFORMSTREAM)
      : false;

  const handleChatOpen = () => {
    dispatch(setProctorCandidateChat({ showProctorCandidateChat: true }));
  };

  const handleChatClose = () => {
    dispatch(setProctorCandidateChat({ showProctorCandidateChat: false }));
  };

  return (
    <div>
      <ComponentToCloseOnClickOutside onClick={handleChatClose}>
        {isFirebaseChat ? (
          <FirebaseChatView
            chatOpen={globalStateChat}
            handleChatOpen={handleChatOpen}
            handleChatClose={handleChatClose}
          />
        ) : (
          <ChatView
            chatOpen={globalStateChat}
            handleChatOpen={handleChatOpen}
            handleChatClose={handleChatClose}
          />
        )}
      </ComponentToCloseOnClickOutside>
      {<ChatButton onClick={handleChatOpen} />}
    </div>
  );
};

export default ChatWrapper;
