import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FullScreenState } from "../globals/enums";

export interface ScreenShareState {
  screenShareStatus: string;
  screenShareError?: string;
  fullScreenStatus?: string;
}

const screenShareSlice = createSlice({
  name: "screenShare",
  initialState: {
    screenShareStatus: ""
  },
  reducers: {
    updateScreenGrab: (state: ScreenShareState, action: PayloadAction<ScreenShareState>) => {
      return { ...state, ...action.payload };
    },
    updateFullScreenState: (
      state: ScreenShareState,
      { payload: { fullScreenStatus } }: PayloadAction<{ fullScreenStatus: FullScreenState }>
    ) => {
      state.fullScreenStatus = fullScreenStatus;
    }
  }
});

export const { updateScreenGrab, updateFullScreenState } = screenShareSlice.actions;

export default screenShareSlice;
