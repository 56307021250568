import { useEffect, useState } from "react";
import { IntlProvider, MessageFormatElement } from "react-intl";
import { AppLocale } from "globals/enums";
import { useAppSelector } from "store/store";
import { MessagesEntry, appMessages } from "lang/messages";
import { GlobalAppState } from "globals/interfaces";

export default function TranslationProvider({ children }: any) {
  const appDetails: GlobalAppState = useAppSelector((state) => state.app);
  const [currentLocale, setCurrentLocale] = useState<string>(
    appDetails.currentLanguage || AppLocale.English
  );
  const [currentLocaleMessages, setCurrentLocaleMessages] = useState<
    Record<string, string> | Record<string, MessageFormatElement[]> | undefined
  >(undefined);

  useEffect(() => {
    setCurrentLocale(appDetails.currentLanguage);
    const newLocale = appMessages.find(
      (localeMessage: MessagesEntry) => localeMessage.locale === appDetails.currentLanguage
    );
    if (newLocale) {
      setCurrentLocaleMessages(newLocale.messages);
    }
  }, [appDetails.currentLanguage]);

  return (
    <IntlProvider
      defaultLocale={AppLocale.English}
      locale={currentLocale}
      messages={currentLocaleMessages}
    >
      {children}
    </IntlProvider>
  );
}
