import { FormattedMessage } from "react-intl";
import PermissionIcon from "assets/img/permissions.png";
import ChromeIcon from "assets/img/chrome.jpg";
import FirefoxIcon from "assets/img/firefox.jpg";
import EdgeIcon from "assets/img/edge.png";

const BrowserNotSupported = () => {
  return (
    <div className="tvp-flex tvp-w-full tvp-h-auto tvp-bg-white  tvp-my-4 tvp-flex-grow tvp-flex-col tvp-justify-center">
      <div className="tvp-w-full tvp-flex tvp-flex-row tvp-justify-around">
        <img className="tvp-permissionIcon tvp-mt-2" src={PermissionIcon} alt="permissionIcon" />
      </div>
      <span>
        <h3 className="tvp-mt-1 tvp-font-bold tvp-text-xl tvp-text-center tvp-clr-neutral">
          <FormattedMessage id="component.browserNotSupported.segment-1.title" />
        </h3>
      </span>
      <div className="tvp-text-center tvp-w-5/6 tvp-mx-auto">
        <p>
          <FormattedMessage id="component.browserNotSupported.segment-1.content" />
        </p>
      </div>
      <div className="tvp-flex tvp-justify-center">
        <div className="tvp-flex tvp-flex-col tvp-align-middle tvp-justify-center tvp-m-2 tvp-w-auto">
          <a href="https://www.google.co.in/chrome/browser/desktop/index.html" target="blank">
            <img className="tvp-w-12 tvp-m-auto tvp-mb-1" src={ChromeIcon} alt="chromeIcon" />
            <p className="tvp-text-center">
              <FormattedMessage id="component.browserNotSupported.icon.chrome" />{" "}
              <span className="">V 105+</span>
            </p>
          </a>
        </div>
        <div className="tvp-flex tvp-flex-col tvp-align-middle tvp-justify-center tvp-m-2 tvp-w-auto">
          <a href="https://www.mozilla.org/en-US/firefox/new/" target="blank">
            <img className="tvp-w-12 tvp-m-auto tvp-mb-1" src={FirefoxIcon} alt="firefoxIcon" />
            <p className="tvp-text-center">
              <FormattedMessage id="component.browserNotSupported.icon.firefox" />{" "}
              <span className="">V 105+</span>
            </p>
          </a>
        </div>
        <div className="tvp-flex tvp-flex-col tvp-align-middle tvp-justify-center tvp-m-2 tvp-w-auto">
          <a href="https://www.microsoft.com/en-us/edge?form=MA13FJ" target="blank">
            <img className="tvp-w-12 tvp-m-auto tvp-mb-1" src={EdgeIcon} alt="edgeIcon" />
            <p className="tvp-text-center">
              <FormattedMessage id="component.browserNotSupported.icon.edge" />{" "}
              <span className="">V 105+</span>
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BrowserNotSupported;

// style="width:50px; margin:5px;"
