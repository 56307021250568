import { RootState, store, useAppDispatch, useAppSelector } from "store/store";
import { GlobalAppState } from "globals/interfaces";
import { FormattedMessage } from "react-intl";
import Header from "components/Header";
import FooterLogo from "components/FooterLogo";
import Connectivity from "assets/img/connectivity.png";
import { useEffect } from "react";
import { getEndpoint, ProctoringRoute } from "../routes/route";
import {
  ErrorTypes,
  ScreenShareStatus,
  SecondaryDeviceStatus,
  StorageKeyNames
} from "../globals/enums";
import { useNavigate } from "react-router-dom";
import { hideProview } from "../helpers/hideProview";
import { setUiErrorScreen } from "../store/app.slice";

const PauseProctorScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const globalState = useAppSelector<GlobalAppState>((state: RootState) => state.app);
  const {
    fireBaseState: { secondary_device_status },
    configuration: { secondary_camera },
    isPaused,
    uiPauseScreen
  } = globalState;

  useEffect(() => {
    const screenShareStatus = store.getState().screenShare.screenShareStatus;
    const currentScreen = sessionStorage.getItem(StorageKeyNames.Screen);
    if (!isPaused && currentScreen === ProctoringRoute.RecorderScreen) {
      if (store.getState().app.isStreamingDisconnected) {
        dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.StreamingReconnection }));
        navigate(getEndpoint(ProctoringRoute.Error));
      } else if (
        screenShareStatus === ScreenShareStatus.PermissionFailed ||
        screenShareStatus === ScreenShareStatus.ReInitializePermission
      ) {
        navigate(getEndpoint(ProctoringRoute.ScreenShare));
      } else if (secondary_camera && secondary_device_status !== SecondaryDeviceStatus.Connected) {
        navigate(getEndpoint(ProctoringRoute.SecondaryDevice));
      } else {
        hideProview();
        return;
      }
    }
  }, [isPaused, secondary_device_status, secondary_camera]);

  return (
    <div id="basediv">
      <div
        className="tvp-flex tvp-flex-col tvp-justify-start tvp-h-auto tvp-max-w-2xl tvp-min-w-2xl 
    tvp-rounded-lg tvp-bg-white tvp-mx-4 tvp-my-0 tvp-px-2.5 tvp-py-4 tvp-overflow-x-hidden tvp-base"
      >
        <Header
          title={<FormattedMessage id="page.PauseProctorScreen.header" />}
          titleClass={"tvp-text-sm tvp-font-semibold tvp-uppercase tvp-ml-2.5"}
        />
        <div className="tvp-w-full tvp-h-auto tvp-bg-white  tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-justify-center">
          <div className="tvp-w-full tvp-flex tvp-flex-row tvp-justify-around">
            <img src={Connectivity} alt="Proctoring paused" />
          </div>
          <h3 className="tvp-my-4 tvp-font-bold tvp-text-xl tvp-text-center tvp-clr-red">
            {uiPauseScreen.reason || (
              <FormattedMessage id="page.PauseProctorScreen.instruction.textOne" />
            )}
          </h3>
          <div className="tvp-text-center tvp-w-10/12 tvp-ml-auto tvp-mr-auto">
            <p>
              {uiPauseScreen.note || (
                <FormattedMessage id="page.PauseProctorScreen.instruction.textTwo" />
              )}
            </p>
          </div>
        </div>
        <FooterLogo />
      </div>
    </div>
  );
};

export default PauseProctorScreen;
