import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { ScreenShareStatus, BrowserName, StorageKeyNames } from "globals/enums";
import { ProctoringRoute } from "routes/route";
import screenShareChrome from "assets/img/screen-share-chrome.gif";
import screenShareFirefox from "assets/img/screen-share-firefox.gif";
import Button from "components/Button";
import Content from "components/layouts/Content";
import { useAppDispatch } from "store/store";
import { updateScreenGrab } from "services/screenShare";
import { getUserBrowser } from "helpers/browser-helper";

const Instructions = () => {
  const dispatch = useAppDispatch();
  const userBrowser = getUserBrowser();

  const start = () => {
    dispatch(
      updateScreenGrab({
        screenShareStatus: ScreenShareStatus.InitializePermission
      })
    );
  };

  useEffect(() => {
    sessionStorage.setItem(StorageKeyNames.Screen, ProctoringRoute.ScreenShare);
  }, []);

  return (
    <Content>
      <div className="tvp-w-full tvp-h-auto tvp-bg-white  tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-text-center">
        <>
          <h2 className="tvp-mb-2.5" role="heading">
            <FormattedMessage id="page.ScreenShare.headerText" />
          </h2>
          <p className="tvp-mb-1">
            <FormattedMessage id="page.ScreenShare.instruction.textOne" />
          </p>
          <p className="tvp-mb-4">
            <FormattedMessage id="page.ScreenShare.instruction.textTwo" />
          </p>
        </>

        <div>
          {userBrowser === BrowserName.Chrome && (
            <img className="tvp-screenShareIcon" src={screenShareChrome} alt="Share screen icon" />
          )}
          {userBrowser !== BrowserName.Chrome && (
            <img className="tvp-screenShareIcon" src={screenShareFirefox} alt="Share screen icon" />
          )}
        </div>
      </div>
      <div className="tvp-parts tvp-justify-center">
        <Button
          label={<FormattedMessage id="button.ProceedToExam" />}
          className={
            "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-8 tvp-mt-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green"
          }
          onClick={start}
        />
      </div>
    </Content>
  );
};

export default Instructions;
