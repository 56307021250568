import footerLogo from "assets/img/powered_by_grey.png";
import { useAppSelector } from "store/store";

function FooterLogo() {
  const { configuration, verificationCodeSuccess } = useAppSelector((state) => state.app);
  const { brand_logo } = configuration;

  return (
    <div>{!brand_logo && verificationCodeSuccess && <img src={footerLogo} alt="footerLogo" />}</div>
  );
}

export default FooterLogo;
