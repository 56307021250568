import { useState, useEffect } from "react";
import { useAppSelector } from "store/store";
import { ChatSupportOptions, SessionScope } from "globals/enums";

const useChatOptions = () => {
  const session = useAppSelector((state) => state.app.session);
  const showFreshChatOption = useAppSelector(
    (state) => state.app.configuration.chat_support_precheck
  );
  const sessionType = session?.session_type;
  const [chatOptions, setChatOptions] = useState<string[]>([]);

  useEffect(() => {
    const options: string[] = [];

    if (sessionType === SessionScope.LiveProctor) {
      options.push(ChatSupportOptions.ChatWithProctor);
    }

    if (showFreshChatOption) {
      options.push(ChatSupportOptions.ChatWithTechTeam);
    } else {
      const indexToRemove = options.indexOf(ChatSupportOptions.ChatWithTechTeam);
      if (indexToRemove !== -1) {
        options.splice(indexToRemove, 1);
      }
    }

    setChatOptions(options);
  }, [sessionType, showFreshChatOption]);

  return chatOptions;
};

export default useChatOptions;
