import React, { useContext, useEffect, useState } from "react";
import { ProctoringRoute, getEndpoint } from "routes/route";
import { FormattedMessage } from "react-intl";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import FaceDetect from "assets/img/face_detect.png";
import FooterLogo from "components/FooterLogo";
import Header from "components/Header";
import ListInstruction from "components/ListInstruction";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { setVideoSourceInput } from "store/app.slice";
import { GlobalAppState } from "globals/interfaces";
import { nextScreen } from "routes/workflow";
import { MediaContext } from "context/MediaContext";

import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
import { handleError } from "helpers/sentry";
import { detectMob } from "helpers/browser-helper";
import { ClientErrorTypes } from "globals/enums";

// eslint-disable-next-line
type FaceCaptureInstructionsProps = {};

const FaceCaptureInstructions = () => {
  const { start, stop } = useContext(MediaContext);
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const [videoInputSource, setVideoInputSource] = useState<MediaDeviceInfo[]>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const instlist = (() => {
    const commonInstructions = [
      {
        id: 1,
        item: <FormattedMessage id="page.faceCaptureInstruction.one" />,
        altText: "Arrow indicating instruction one"
      },
      {
        id: 2,
        item: <FormattedMessage id="page.faceCaptureInstruction.two" />,
        altText: "Arrow indicating instruction two"
      }
    ];

    if (detectMob()) {
      return commonInstructions;
    }

    return [
      ...commonInstructions,
      {
        id: 3,
        item: <FormattedMessage id="page.faceCaptureInstruction.three" />,
        altText: "Arrow indicating instruction three"
      }
    ];
  })();

  useEffect(() => {
    const getCameraSources = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoInput = devices.filter((device) => device.kind === "videoinput");
        setVideoInputSource(videoInput);
      } catch (error) {
        handleError(error, ClientErrorTypes.VideoInputError);
      }
    };
    getCameraSources();
  }, []);

  const list = videoInputSource?.map((device) => {
    return device.label;
  });

  const handleNext = () => {
    const getNextScreen = nextScreen(
      ProctoringRoute.FaceCaptureInstructions,
      globalState.configuration,
      globalState.session.session_type
    );
    navigate(getEndpoint(getNextScreen));
  };
  const handleChange = (selectedValed: any) => {
    const selectedDevice =
      videoInputSource?.find((device) => device.label === selectedValed.value) ||
      (videoInputSource && videoInputSource[0]);
    selectedDevice && dispatch(setVideoSourceInput({ video_source: selectedDevice }));
    stop();
    const mediaConstraints: MediaStreamConstraints = {
      video: {
        width: 720,
        height: 540,
        deviceId: selectedDevice?.deviceId
      }
    };
    start(mediaConstraints);
  };
  return (
    <Base>
      <Header
        title={<FormattedMessage id="page.faceCaptureInstruction.header" />}
        titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
        tabIndex={1}
      />
      <Content>
        <span className="tvp-align-center">
          <p>
            <FormattedMessage
              id="page.facecaptureInstructions.instruction"
              values={{ strong: (chunks) => <strong>{chunks}</strong> }}
            />
          </p>
        </span>
        <div className="tvp-flex tvp-my-5 tvp-col-row">
          <ListInstruction instList={instlist} tips={true} className="tvp-or1-2" />
          <span className="tvp-mt-5 tvp-flex tvp-flex-col tvp-items-center tvp-justify-center tvp-or2-1">
            <img className="tvp-w-24" src={FaceDetect} alt="Icon indicating man with microphone" />
            {!detectMob() && (
              <label className="tvp-my-2" id="aria-label" htmlFor="aria-facecapture-input">
                <FormattedMessage id="page.faceCaptureInstruction.camera.text" />
              </label>
            )}
            {list && !detectMob() && (
              <Dropdown
                ariaLabelledby="aria-label"
                inputId="aria-facecapture-input"
                list={list}
                onChange={handleChange}
                tabIndex={2}
                data-testid="camera-dropdown"
              />
            )}
          </span>
        </div>
        <Button
          label={<FormattedMessage id="button.next" />}
          tabIndex={3}
          className={
            "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green tvp-w-150"
          }
          onClick={handleNext}
        />
      </Content>
      <FooterLogo />
    </Base>
  );
};

export default FaceCaptureInstructions;
