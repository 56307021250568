import {
  firebaseAPIKey,
  firebaseAPPID,
  firebaseDatabaseURL,
  firebaseProjectID
} from "config/constant";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getDatabase, onValue, ref } from "firebase/database";

import {
  setFireBaseConnectionState,
  setFireBaseState,
  setUiErrorScreen,
  setSessionStatus
} from "store/app.slice";

import * as Sentry from "@sentry/react";
import { initializeApp } from "firebase/app";
import { getErrorType } from "../../helpers/precheck-helper";
import { ProctoringRoute, getEndpoint } from "../../routes/route";
import { NavigateFunction } from "react-router-dom";
import { SecondaryDeviceStatus, SessionStatus, firebaseCollections } from "globals/enums";
import firebaseCustomTokenApi from "../../services/firebase-custom-token";

type Props = {
  navigate: NavigateFunction;
};

const firebaseConfig = {
  apiKey: firebaseAPIKey,
  databaseURL: firebaseDatabaseURL,
  projectId: firebaseProjectID,
  appId: firebaseAPPID
};

const app = initializeApp(firebaseConfig);
const firebaseAuthentication = getAuth(app);
const firabaseDatabase = getDatabase(app);

const getSecondaryDeviceStatus = (secondaryDeviceObject) => {
  if (secondaryDeviceObject) {
    const values: any = Object.values(secondaryDeviceObject);

    const hasConnected = values.some((item) => item.status === SecondaryDeviceStatus.Connected);
    const hasDisconnected = values.some(
      (item) => item.status === SecondaryDeviceStatus.Disconnected
    );
    const allInactive = values.every((item) => item.status === SecondaryDeviceStatus.InActive);

    if (hasConnected) {
      return SecondaryDeviceStatus.Connected;
    } else if (hasDisconnected) {
      return SecondaryDeviceStatus.Disconnected;
    } else if (allInactive) {
      return SecondaryDeviceStatus.InActive;
    } else {
      return "unknown status";
    }
  }
  return undefined;
};
export const handleSessionData = (id, dispatch, navigate) => (snapshot) => {
  const data = snapshot.val();
  if (data) {
    const updatedObj = {
      pre_check: data.pre_check,
      status: data.status,
      termination_reason: data.termination_reason,
      proctor_group_room_identity: data.proctor_group_room_identity,
      audio_call_initiated: data.audio_call_initiated,
      secondary_device_status: getSecondaryDeviceStatus(data?.streaming_device?.secondary)
    };
    dispatch(setFireBaseState(updatedObj));
    const sessionError = getErrorType(data?.status);
    if (sessionError) {
      dispatch(setUiErrorScreen({ uiErrorScreen: sessionError as string }));
      if (data?.status === SessionStatus.Terminated) {
        const event = new CustomEvent("Terminated");
        document.dispatchEvent(event);
        dispatch(setSessionStatus({ status: SessionStatus.Terminated }));
        navigate(getEndpoint(ProctoringRoute.Terminated));
      }
    }
    dispatch(setFireBaseConnectionState({ is_connected: true }));
  } else {
    dispatch(setFireBaseConnectionState({ is_connected: false }));
  }
};

const FirebaseInit = async (id: number, dispatch: any, { navigate }: Props) => {
  try {
    const { data, isSuccess } = await dispatch(
      firebaseCustomTokenApi.endpoints.firebaseCustomToken.initiate({})
    );
    if (isSuccess) {
      signInWithCustomToken(firebaseAuthentication, data)
        .then(() => {
          const sessionRef = ref(firabaseDatabase, `${firebaseCollections.Session}/${id}`);
          onValue(sessionRef, handleSessionData(id, dispatch, navigate));
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    }
  } catch (e) {
    dispatch(setFireBaseConnectionState({ is_connected: false }));
    throw e;
  }
};

export { FirebaseInit };
