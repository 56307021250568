import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { AlertLogConfig } from "services/session-token";

const useDetectMultiMonitor = () => {
  const dispatch = useAppDispatch();
  const alertConfig: AlertLogConfig[] = useSelector((state: RootState) => state.app.alertConfigs);
  const precheckSuccess = useSelector((state: RootState) => state.app.precheckSuccess);

  useEffect(() => {
    if (precheckSuccess) {
      if ("isExtended" in window.screen) {
        if (window.screen.isExtended)
          alertHelper(alertConfig).raiseAlert(AlertName.MultipleMonitorDetected, dispatch);
      }
    }
  }, [precheckSuccess]);
};

export default useDetectMultiMonitor;
