import { GlobalAppState } from "globals/interfaces";
import { v4 as uuidv4 } from "uuid";
import { getSystemTime } from "./common";

const getPayLoad = (
  session_type: string,
  sas_token: string,
  alert_type_id: string,
  blob: any,
  type: string,
  offline_batch_id?: any
) => {
  return {
    url: offline_batch_id
      ? `/${alert_type_id}/offline_videos/${offline_batch_id}/${session_type}_${uuidv4()}_${getSystemTime().getTime()}.${type}?${sas_token}`
      : `/${alert_type_id}/${session_type}_${uuidv4()}_${getSystemTime().getTime()}.${type}?${sas_token}`,
    data: blob,
    // eslint-disable-next-line
    alert_type_id: +alert_type_id.match(/\d+/)[0]
  };
};

export const getVideoUploadBlobPayload = (
  app: GlobalAppState,
  blob: any,
  offlineBatchId?: any
): any => {
  return getPayLoad(
    app.session.session_type,
    app.storage?.sas_token,
    app.storage?.file_destination.video,
    blob,
    "webm",
    offlineBatchId
  );
};

export const getCameraGrabBlobPayload = (storage: any, session: any, blob: any) => {
  return getPayLoad(
    session.session_type,
    storage?.sas_token,
    storage?.file_destination.camera_grab,
    blob,
    "png"
  );
};

export const getScreenGrabBlobPayload = (storage: any, session: any, blob: any) => {
  return getPayLoad(
    session.session_type,
    storage?.sas_token,
    storage?.file_destination.screen_grab,
    blob,
    "png"
  );
};
