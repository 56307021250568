import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProctoringRoute, getEndpoint } from "routes/route";
import { useAppSelector, useAppDispatch } from "store/store";
import { setPrecheckSuccess } from "store/app.slice";
import { GlobalAppState } from "globals/interfaces";
import { FormattedMessage } from "react-intl";
import FooterLogo from "components/FooterLogo";
import Header from "components/Header";
import SmileyLogo from "assets/img/smiley.png";
import Base from "components/layouts/Base";
import Content from "components/layouts/Content";

export const PreFlightSuccess = () => {
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => {
      if (globalState?.configuration?.full_screen_mode)
        navigate(getEndpoint(ProctoringRoute.FullScreen));
      else navigate(getEndpoint(ProctoringRoute.RecorderScreen));
    }, 2000);
    dispatch(setPrecheckSuccess({ precheckSuccess: true }));
    const Dragablediv = document.getElementById("DragabledivId") as HTMLElement;
    let recorderContainer = document.getElementById("tvp_recorder_container") as HTMLElement;
    if (!recorderContainer) {
      recorderContainer = document.createElement("div") as HTMLElement;
      recorderContainer.id = "tvp_recorder_container";
    } else if (Dragablediv && recorderContainer) {
      Dragablediv.appendChild(recorderContainer);
    }
  }, []);
  useEffect(() => {
    const Dragablediv = document.getElementById("DragabledivId") as HTMLElement;
    const recorderContainer = document.getElementById("tvp_recorder_container") as HTMLElement;
    if (Dragablediv && recorderContainer) {
      const fchatBtn = document.getElementById("chat-icon") as HTMLElement;
      const prochatBtn = document.getElementById("prchat") as HTMLElement;
      if (fchatBtn && prochatBtn) {
        fchatBtn.style.display = "none";
        prochatBtn.style.display = "none";
      }
    }
  }, []);
  return (
    <Base>
      <Header title={""} titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-ml-2.5"} />
      <Content>
        <div className="tvp-innerContent">
          <img className="tvp-mx-auto tvp-SmileyLogo" src={SmileyLogo} alt="Smiley icon" />
          <div className="tvp-mt-12 tvp-flex tvp-flex-col tvp-items-center">
            <p className="tvp-mb-4 tvp-clr-neutral tvp-text-lg">
              <FormattedMessage id="page.PreFlightSuccess.headerText" />
            </p>
            <p>
              <FormattedMessage id="page.PreFlightSuccess.content" />
            </p>
          </div>
        </div>
      </Content>
      <FooterLogo />
    </Base>
  );
};

export default PreFlightSuccess;
