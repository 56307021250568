import React from "react";
import ChatIcon from "assets/img/blue-chat.png";
type ChatProps = {
  onClick?: () => void;
};
function ChatButton(props: ChatProps) {
  return (
    <div id="prchat">
      <div className="tvp-chatIcon">
        <img src={ChatIcon} alt="chatIcon" onClick={props.onClick} />
      </div>
    </div>
  );
}

export default ChatButton;
