export const pre_check = `{
    "pre_check": [
        {
            "status": "approved",
            "reason": null,
            "number_of_attempts": 1,
            "verification_type": "photo_id"
        },
        {
            "status": "approved",
            "reason": null,
            "number_of_attempts": 1,
            "verification_type": "test_env"
        }
    ],
    "termination_reason": null,
    "session_status": "online"
}`;
