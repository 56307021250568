import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/store";
import { ScreenShareStatus, SecondaryDeviceStatus } from "globals/enums";
import { nextScreen } from "routes/workflow";
import { ProctoringRoute, getEndpoint } from "routes/route";
import Instructions from "components/ScreenShareInstructions";
import Base from "components/layouts/Base";
import Header from "components/Header";
import FooterLogo from "components/FooterLogo";
import Error from "components/ErrorScreens/ScreenShareError";
import { hideProview } from "helpers/hideProview";
import { ScreenShareState } from "../services/screenShare";

const ScreenShare = () => {
  const screenShare = useAppSelector((state) => state.screenShare);
  const { screenShareStatus, screenShareError }: ScreenShareState = screenShare;
  const globalState = useAppSelector((state) => state.app);
  const navigate = useNavigate();

  const {
    fireBaseState: { secondary_device_status },
    configuration: { full_screen_mode }
  } = globalState;

  const handleNavigateToNextScreen = () => {
    if (secondary_device_status === SecondaryDeviceStatus.Disconnected) {
      navigate(getEndpoint(ProctoringRoute.SecondaryDevice));
    } else {
      const nextScreenToNavigate = nextScreen(
        ProctoringRoute.ScreenShare,
        globalState.configuration,
        globalState.session.session_type,
        true
      );
      if (nextScreenToNavigate === ProctoringRoute.RecorderScreen) {
        if (full_screen_mode) navigate(getEndpoint(ProctoringRoute.FullScreen));
        else hideProview();
      } else {
        navigate(getEndpoint(nextScreenToNavigate));
      }
    }
  };

  useEffect(() => {
    if (screenShareStatus === ScreenShareStatus.ScreenShareStarted) {
      handleNavigateToNextScreen();
    }
  }, [screenShareStatus]);

  return (
    <Base>
      <Header title={""} titleClass={"tvp-text-sm tvp-font-semibold clr-dgray tvp-ml-2.5"} />
      {(screenShareStatus === ScreenShareStatus.PermissionFailed ||
        screenShareStatus === ScreenShareStatus.ReInitializePermission) && (
        <Error screenShareError={screenShareError} />
      )}
      {(!screenShareStatus || screenShareStatus === ScreenShareStatus.InitializePermission) && (
        <Instructions />
      )}
      <FooterLogo />
    </Base>
  );
};

export default ScreenShare;
