import React, { useState, useRef } from "react";
import send from "assets/img/Vector2.png";

type MessageInputProps = {
  handleSendMessage: (inputValue: string) => void;
  active: boolean;
};

const MessageInput = (props: MessageInputProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (inputValue) {
        props.handleSendMessage(inputValue);
        setInputValue("");
      }
    }
  };

  const handleSubmit = () => {
    if (inputValue && props.active && navigator.onLine) {
      props.handleSendMessage(inputValue);
      setInputValue("");
    }
  };

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <label htmlFor="inputfield" aria-label="Please enter the text to chat with proctor">
      <div className="tvp-chatInputfolder">
        <input
          id="inputfield"
          ref={inputRef}
          className={`tvp-chatInput ${props.active ? "" : "tvp-disabled"}`}
          value={inputValue}
          name="inputfield"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={!props.active}
          onClick={handleInputClick}
        />

        <div
          className={props.active && navigator.onLine ? "tvp-iconHolder" : "tvp-iconHolderDisabled"}
          onClick={handleSubmit}
        >
          <img src={send} alt="send icon" />
        </div>
      </div>
    </label>
  );
};

export default MessageInput;
