import React, { useState, useEffect } from "react";
import { ProctoringRoute, getEndpoint } from "routes/route";
import { FormattedMessage } from "react-intl";
import Button from "components/Button";
import FooterLogo from "components/FooterLogo";
import Header from "components/Header";
import ListInstruction from "components/ListInstruction";
import ViewDemo from "components/ViewDemo";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/store";
import { GlobalAppState } from "globals/interfaces";
import { nextScreen } from "routes/workflow";
import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
import { AppLocale, SubTitleMode } from "../globals/enums";

const EnvTestInstructions = () => {
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const [isDemoOpened, SetIsDemoOpened] = useState(false);
  const instlist = [
    {
      id: 1,
      item: <FormattedMessage id="page.EnvTestInstructions.instruction.textOne" />,
      altText: "Arrow indicating instruction one"
    },
    {
      id: 2,
      item: <FormattedMessage id="page.EnvTestInstructions.instruction.textTwo" />,
      altText: "Arrow indicating instruction two"
    }
  ];

  useEffect(() => {
    const video = document.getElementById("tvp_demo_video") as HTMLVideoElement;
    if (globalState.currentLanguage === AppLocale.French) {
      const track = video.addTextTrack("captions", "Captions", "fr");
      track.mode = SubTitleMode.Showing;
      track.addCue(
        new VTTCue(1, 5, "Étape 01\nVérification des écouteurs\nN'utilisez pas d'écouteurs")
      );
      track.addCue(
        new VTTCue(
          10,
          15,
          "Étape 02\nVérification de la table de travail, de la table, du sol et du plafond :\nEnregistrez l'ensemble de la table de travail, de la table, du sol et du plafond de la pièce dans laquelle vous effectuez le test."
        )
      );
      track.addCue(
        new VTTCue(
          40,
          45,
          "Étape 03\nVérification à 360° de la pièce :\nFaites pivoter la caméra pour obtenir une vue à 360° de la pièce."
        )
      );
    } else {
      const tracks = video.textTracks;
      for (let i = 0; i < tracks.length; i++) {
        tracks[i].mode = SubTitleMode.Hidden;
      }
    }
  }, [globalState.currentLanguage]);

  const closeDemo = () => {
    SetIsDemoOpened(false);
  };

  const navigate = useNavigate();
  const handleNext = () => {
    const getNextScreen = nextScreen(
      ProctoringRoute.TestEnvInstructions,
      globalState.configuration,
      globalState.session.session_type
    );
    navigate(getEndpoint(getNextScreen));
  };

  return (
    <Base>
      {isDemoOpened && <ViewDemo onClick={closeDemo} />}
      {!isDemoOpened && (
        <>
          <Header
            title={<FormattedMessage id="page.EnvTestInstructions.header" />}
            titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
            tabIndex={1}
          />
          <Content>
            <div className="tvp-flex tvp-col-row tvp-px-4 tvp-justify-center tvp-items-center tvp-mt-7">
              <div className="tvp-pr-1 tvp-or1-2 tvp-h-auto tvp-pr-6">
                <p className="tvp-mb-3">
                  <FormattedMessage id="page.EnvTestInstructions.instructions.text" />
                </p>
                <ListInstruction instList={instlist} tips={false} className="" />
              </div>
              <video
                tabIndex={2}
                className="tvp-testEnvDemoVideo tvp-or2-1 tvp-mb-4"
                src="https://cdn.proview.io/assets/360degree-check-video.mp4"
                id="tvp_demo_video"
                controls
                disablePictureInPicture
                controlsList="nodownload"
              ></video>
            </div>

            <div className="tvp-parts tvp-justify-center">
              <p className="tvp-mt-3">
                <FormattedMessage id="page.EnvTestInstructions.instruction.textThree" />
              </p>
            </div>

            <div className="tvp-parts tvp-justify-center">
              <Button
                tabIndex={3}
                label={<FormattedMessage id="button.startTest" />}
                className={
                  "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green"
                }
                onClick={handleNext}
              />
            </div>
          </Content>
          <FooterLogo />
        </>
      )}
    </Base>
  );
};

export default EnvTestInstructions;
