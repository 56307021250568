import React, { useEffect } from "react";

type VideoPlayerProps = {
  mediaStream?: MediaStream;
  playerWidth: string;
  videoRef: React.RefObject<HTMLVideoElement>;
};

const VideoPlayer = (props: VideoPlayerProps) => {
  useEffect(() => {
    if (props?.mediaStream && props.videoRef?.current) {
      props.videoRef.current.srcObject = props.mediaStream;
    }
  }, [props.mediaStream, props.videoRef]);

  return (
    <>
      <video
        webkit-playsinline="true"
        style={{ width: `${props.playerWidth}` }}
        ref={props.videoRef}
        muted
        autoPlay
        playsInline
      ></video>
    </>
  );
};

export default VideoPlayer;
