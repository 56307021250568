import { useEffect, useState } from "react";

const useGetZoomLevel = () => {
  const [resizeLevel, setResizeLevel] = useState<number>(0);

  const getZoomLevel = () => {
    const level = Math.round((window.outerHeight / window.innerHeight) * 10);
    setResizeLevel(level);
  };

  window.addEventListener("resize", () => {
    getZoomLevel();
  });

  useEffect(() => {
    getZoomLevel();
  }, []);

  return { resizeLevel };
};

export default useGetZoomLevel;
