export function getRefreshToken(sasExpiriesIn: string) {
  const refreshToken = `{
  "service": "azure",
  "sas_token": "sv=2021-06-08&si=18996&sr=c&sig=4vmZ4juEpomQZTXXQpn%2BO0LK0eO7Xf8JOvapj1ahHuo%3D",
  "sas_expiries_in": "${sasExpiriesIn}",
  "sas_url": "http://localhost:8080/",
  "container_name": "4a9307fd-32a1-487f-ab67-91130a02a93e"
}`;
  return refreshToken;
}
