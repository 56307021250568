import React from "react";

type AudioProgressBarProps = {
  width: number;
};

const AudioProgressBar = (props: AudioProgressBarProps) => {
  return (
    <div className={"tvp-progressBarWrapper"}>
      <div className="tvp-progressBar" style={{ width: `${props.width}%` }}></div>
    </div>
  );
};

export default AudioProgressBar;
