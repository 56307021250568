import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Channel,
  ExternalLink,
  GlobalAppState,
  MediaSourceInputPayload,
  PauseProctorPayload
} from "globals/interfaces";
import { SessionTokenApiResponse, VerificationTokenApiResponse } from "services/session-token";
import { PreCheckItem } from "services/precheck";
import { Queue } from "helpers/data-structure/queue";
import { AppLocale, SessionScope, SessionStatus, TwilioAudioCallEvent } from "globals/enums";
import { alertApi } from "services/alert";
import { AlertName, AlertType } from "helpers/alert-type";
// import { localInitScriptConfigurations } from "local-run";

export const initialAppState: GlobalAppState = {
  verificationCodeSuccess: false,
  precheckStartTime: 0,
  currentOfflineEvent: {
    offlineBatchId: null,
    offlineTime: null,
    totalChunksQueued: 0
  },
  videoRecordingsQueue: new Queue<{ blob: Blob; startTime: string }>(),
  verificationCode: "",
  audioSourceInput: {
    deviceId: null,
    groupId: null,
    kind: null,
    label: null
  },
  videoSourceInput: {
    deviceId: null,
    groupId: null,
    kind: null,
    label: null
  },
  clientConfiguration: {
    additionalInstruction: "",
    referenceLinks: [],
    enforceTSB: false,
    redirectURL: "",
    blacklistedSoftwaresWindows: "",
    blacklistedSoftwaresMac: "",
    minimizeOption: "false",
    whitelistedSoftwaresWindows: "",
    whitelistedSoftwaresMac: "",
    isScreenProtectionEnabled: "false"
  },
  configuration: {
    type: "",
    organization_id: -1,
    eye_gaze: false,
    audio_call: true,
    valid_from: "",
    valid_till: "",
    audio_check: true,
    screen_grab: true,
    face_capture: true,
    end_buffer_time: 0,
    video_recording: true,
    object_detection: false,
    disable_noise_detection: false,
    twilio_streaming: true,
    environment_check: true,
    start_buffer_time: 0,
    chat_support_event: false,
    mixpanel_analytics: true,
    pre_flight_approval: true,
    terms_and_conditions: true,
    terms_and_condition_url: "",
    privacy_url: "",
    support_url: "",
    support_email: "",
    chat_support_precheck: false,
    high_touch_proctoring: true,
    id_verification_check: true,
    midway_face_verification: true,
    firebase_db: false,
    proctor_initiated_audio_call: false,
    screen_recording: false,
    secondary_camera: false,
    brand_color: "",
    brand_logo: "",
    streaming_provider: "",
    feedback: false
  },
  alertConfigs: [],
  fireBaseConnectionState: false,
  identification: {
    photo: false,
    photo_id: false
  },
  session: {
    session_id: 0,
    session_token: "",
    session_type: SessionScope.AiProctor,
    session_uuid: "",
    old_session_uuid: "",
    organization_id: 1,
    instruction: "",
    secondary_camera_token: "",
    session_token_id: null,
    attendee_id: 0
  },
  initScriptConfiguration: {
    type: "production",
    UID: "e376b1ec-4a5b-43d9-8821-72de13f7554a",
    event_external_id: "99130",
    profile_id:
      process.env.REACT_APP_EXT_CANDIDATE_ID ||
      `dummy_profile${String(Math.floor(Math.random() * 600000) + 1)}`,
    session_id:
      process.env.REACT_APP_EXT_SESSION_ID ||
      `dummy_session${String(Math.floor(Math.random() * 600000) + 1)}`,
    session_type: process.env.REACT_APP_SESSION_TYPE || "ai_proctor",
    session_title: "dummyEvent45 Test",
    isIDRequired: false,
    timers: {
      face: {
        samplingDuration: 30000,
        samplingFrequency: 30000
      },
      flash: {
        samplingDuration: 10000,
        samplingFrequency: 10500
      },
      audio: {
        record: 5000,
        playback: 5000
      },
      transition: 2000,
      faceDetectionCounter: 20,
      windowcheck: 1000,
      uploadFileTimeout: 600000
    },
    initCallback: undefined
  },
  chat: {
    token: "",
    user_id: ""
  },
  instruction: "",
  organizationId: -1,
  storage: {
    container_name: "",
    file_destination: {
      camera_grab: "",
      screen_grab: "",
      test_env: "",
      video: ""
    },
    sas_expiries_in: "",
    sas_token: "",
    sas_url: "",
    service: ""
  },
  currentLanguage: AppLocale.English,
  uiErrorScreen: "",
  fireBaseState: {
    pre_check: [],
    status: "offline",
    termination_reason: "null",
    audio_call_initiated: false,
    proctor_group_room_identity: "",
    secondary_device_status: ""
  },
  channelData: {
    channels: {
      broadcast: {
        id: "",
        name: "",
        proctor_event_id: 0,
        type: ""
      },
      private: {
        id: "",
        name: "",
        proctor_event_id: 0,
        type: ""
      }
    },
    participant_group_id: 0
  },
  showChat: false,
  showLinkAndVideo: false,
  showVideoThumbnail: false,
  twilioAudioCallStatus: null,
  twilioAudioCall: undefined,
  isCameraGrabEnabled: false,
  isFaceDetectionEnabled: false,
  isObjectDetectionEnabled: false,
  notificationAlerts: [],
  precheckSuccess: false,
  isVideoStreamingEnabled: false,
  isScreenRecordingEnabled: false,
  isOnline: true,
  isStreamingDisconnected: false,
  uiPauseScreen: {
    reason: "",
    note: ""
  },
  showProctorCandidateChat: false,
  mediaPermission: { video: false, audio: false, screen: false },
  chatService: false,
  previewStyle: "",
  sessionStatus: "",
  isReadyToLoadNewSession: false,
  systemTimeDiff: 0,
  isPaused: false
};

const appSlice = createSlice({
  name: "app",
  initialState: initialAppState,
  reducers: {
    setVerificationTokenApiDetails: (
      state: GlobalAppState,
      {
        payload: { verificationTokenApiResponse }
      }: PayloadAction<{ verificationTokenApiResponse: VerificationTokenApiResponse }>
    ) => {
      state.configuration = verificationTokenApiResponse.configuration;
      state.verificationCodeSuccess = true;
      state.systemTimeDiff = verificationTokenApiResponse.systemTimeDiff;
    },
    setSessionTokenApiDetails: (
      state: GlobalAppState,
      {
        payload: { sessionTokenApiResponse }
      }: PayloadAction<{ sessionTokenApiResponse: SessionTokenApiResponse }>
    ) => {
      state.session.session_id = sessionTokenApiResponse.session_id;
      state.session.session_token = sessionTokenApiResponse.session_token;
      state.session.session_type = sessionTokenApiResponse.session_type;
      state.session.session_uuid = sessionTokenApiResponse.session_uuid;
      state.session.old_session_uuid = "";
      state.session.organization_id = sessionTokenApiResponse.organization_id;
      state.session.instruction = sessionTokenApiResponse.instruction;
      state.session.secondary_camera_token = sessionTokenApiResponse.secondary_camera_token;
      state.session.session_token_id = sessionTokenApiResponse.session_token_id;
      state.configuration.firebase_db = sessionTokenApiResponse.configuration.firebase_db;
      state.storage = sessionTokenApiResponse.storage;
      state.chat = sessionTokenApiResponse.chat;
      state.identification = sessionTokenApiResponse.identification;
      state.alertConfigs = sessionTokenApiResponse.configuration.alert_configs!;
      state.configuration.brand_color = sessionTokenApiResponse.configuration.brand_color;
      state.configuration.brand_logo = sessionTokenApiResponse.configuration.brand_logo;
      state.configuration = sessionTokenApiResponse.configuration;
      state.isObjectDetectionEnabled = sessionTokenApiResponse.configuration.object_detection;
      state.session.attendee_id = sessionTokenApiResponse.attendee_id;
      state.isReadyToLoadNewSession = false;
    },
    setMediaPermission: (
      state: GlobalAppState,
      {
        payload: { mediaPermission }
      }: PayloadAction<{ mediaPermission: { video?: boolean; audio?: boolean; screen?: boolean } }>
    ) => {
      state.mediaPermission = { ...state.mediaPermission, ...mediaPermission };
    },
    setLanguage: (
      state: GlobalAppState,
      { payload: { locale } }: PayloadAction<{ locale: string }>
    ) => {
      state.currentLanguage = locale;
    },
    setUiErrorScreen: (
      state: GlobalAppState,
      { payload: { uiErrorScreen } }: PayloadAction<{ uiErrorScreen: string }>
    ) => {
      state.uiErrorScreen = uiErrorScreen;
    },
    setPrecheckStartTime: (
      state: GlobalAppState,
      { payload: { precheckStartTime } }: PayloadAction<{ precheckStartTime: number }>
    ) => {
      state.precheckStartTime = precheckStartTime;
    },
    setUiPauseScreen: (
      state: GlobalAppState,
      { payload: { reason, note } }: PayloadAction<PauseProctorPayload>
    ) => {
      state.uiPauseScreen = { reason, note };
    },
    setFireBaseState: (
      state: GlobalAppState,
      {
        payload: {
          pre_check,
          status,
          termination_reason,
          proctor_group_room_identity,
          audio_call_initiated,
          secondary_device_status
        }
      }: PayloadAction<{
        pre_check?: PreCheckItem[];
        status?: string;
        termination_reason?: string;
        proctor_group_room_identity?: string;
        audio_call_initiated?: boolean;
        secondary_device_status?: string;
      }>
    ) => {
      state.fireBaseState.pre_check = pre_check ?? state.fireBaseState.pre_check;
      state.fireBaseState.status = status ?? state.fireBaseState.status;
      state.fireBaseState.termination_reason =
        termination_reason ?? state.fireBaseState.termination_reason;
      state.fireBaseState.proctor_group_room_identity =
        proctor_group_room_identity ?? state.fireBaseState.proctor_group_room_identity;
      state.fireBaseState.audio_call_initiated =
        audio_call_initiated ?? state.fireBaseState.audio_call_initiated;
      state.fireBaseState.secondary_device_status =
        secondary_device_status ?? state.fireBaseState.secondary_device_status;
    },
    setChannelState: (
      state: GlobalAppState,
      {
        payload: { channels, participant_group_id }
      }: PayloadAction<{ channels: Channel; participant_group_id: number }>
    ) => {
      state.channelData.channels = channels;
      state.channelData.participant_group_id = participant_group_id;
    },
    setFireBaseConnectionState: (
      state: GlobalAppState,
      { payload: { is_connected } }: PayloadAction<{ is_connected: boolean }>
    ) => {
      state.fireBaseConnectionState = is_connected;
    },
    setAudioSourceInput: (
      state: GlobalAppState,
      { payload: { audio_source } }: PayloadAction<{ audio_source: MediaSourceInputPayload }>
    ) => {
      state.audioSourceInput = audio_source;
    },
    setVideoSourceInput: (
      state: GlobalAppState,
      { payload: { video_source } }: PayloadAction<{ video_source: MediaSourceInputPayload }>
    ) => {
      state.videoSourceInput = video_source;
    },
    setSASToken: (
      state: GlobalAppState,
      { payload: { storage } }: PayloadAction<{ storage: GlobalAppState["storage"] }>
    ) => {
      state = { ...state, storage };
    },
    setChatOpen: (
      state: GlobalAppState,
      { payload: { showChat } }: PayloadAction<{ showChat: boolean }>
    ) => {
      state.showChat = showChat;
    },
    setLinkAndVideo: (
      state: GlobalAppState,
      { payload: { showLinkAndVideo } }: PayloadAction<{ showLinkAndVideo: boolean }>
    ) => {
      state.showLinkAndVideo = showLinkAndVideo;
    },
    setVideoThumbnail: (
      state: GlobalAppState,
      { payload: { showVideoThumbnail } }: PayloadAction<{ showVideoThumbnail: boolean }>
    ) => {
      state.showVideoThumbnail = showVideoThumbnail;
    },
    setTwilioAudioCallStatus: (
      state: GlobalAppState,
      {
        payload: { twilio_audio_call_status, twilio_audio_call }
      }: PayloadAction<{
        twilio_audio_call_status: TwilioAudioCallEvent | null;
        twilio_audio_call?: any;
      }>
    ) => {
      state.twilioAudioCallStatus = twilio_audio_call_status;
      state.twilioAudioCall = twilio_audio_call;
    },
    setIsCameraGrabEnabled: (
      state: GlobalAppState,
      { payload: { isCameraGrabEnabled } }: PayloadAction<{ isCameraGrabEnabled: boolean }>
    ) => {
      state.isCameraGrabEnabled = isCameraGrabEnabled;
    },
    setIsFaceDetectionEnabled: (
      state: GlobalAppState,
      { payload: { isFaceDetectionEnabled } }: PayloadAction<{ isFaceDetectionEnabled: boolean }>
    ) => {
      state.isFaceDetectionEnabled = isFaceDetectionEnabled;
    },
    setIsObjectDetectionEnabled: (
      state: GlobalAppState,
      {
        payload: { isObjectDetectionEnabled }
      }: PayloadAction<{ isObjectDetectionEnabled: boolean }>
    ) => {
      state.isObjectDetectionEnabled = isObjectDetectionEnabled;
    },
    setVideoStreamingEnabled: (
      state: GlobalAppState,
      { payload: { isVideoStreamingEnabled } }: PayloadAction<{ isVideoStreamingEnabled: boolean }>
    ) => {
      state.isVideoStreamingEnabled = isVideoStreamingEnabled;
    },
    setScreenRecordingEnabled: (
      state: GlobalAppState,
      {
        payload: { isScreenRecordingEnabled }
      }: PayloadAction<{ isScreenRecordingEnabled: boolean }>
    ) => {
      state.isScreenRecordingEnabled = isScreenRecordingEnabled;
    },
    setNotificationAlerts: (state: GlobalAppState, action) => {
      state.notificationAlerts = [...state.notificationAlerts, action.payload];
    },
    removeToastedAlert: (state: GlobalAppState) => {
      state.notificationAlerts = state.notificationAlerts.slice(1);
    },
    setPrecheckSuccess: (
      state: GlobalAppState,
      { payload: { precheckSuccess } }: PayloadAction<{ precheckSuccess: boolean }>
    ) => {
      state.precheckSuccess = precheckSuccess;
    },
    setIsOnline: (
      state: GlobalAppState,
      { payload: { isOnline } }: PayloadAction<{ isOnline: boolean }>
    ) => {
      state.isOnline = isOnline;
    },
    setIsStreamingDisconnected: (
      state: GlobalAppState,
      { payload: { isStreamingDisconnected } }: PayloadAction<{ isStreamingDisconnected: boolean }>
    ) => {
      state.isStreamingDisconnected = isStreamingDisconnected;
    },
    setClientConfiguration: (
      state: GlobalAppState,
      {
        payload: { additionalInstruction, referenceLinks }
      }: PayloadAction<{
        additionalInstruction?: string;
        referenceLinks?: ExternalLink[];
      }>
    ) => {
      state.clientConfiguration.additionalInstruction =
        additionalInstruction ?? state.clientConfiguration.additionalInstruction;
      state.clientConfiguration.referenceLinks =
        referenceLinks ?? state.clientConfiguration.referenceLinks;
    },
    setPreviewStyle: (
      state: GlobalAppState,
      {
        payload: { previewStyle }
      }: PayloadAction<{
        previewStyle: string;
      }>
    ) => {
      state.previewStyle = previewStyle;
    },
    setProctorCandidateChat: (
      state: GlobalAppState,
      {
        payload: { showProctorCandidateChat }
      }: PayloadAction<{ showProctorCandidateChat: boolean }>
    ) => {
      state.showProctorCandidateChat = showProctorCandidateChat;
    },
    setChatService: (
      state: GlobalAppState,
      { payload: { chatService } }: PayloadAction<{ chatService: boolean }>
    ) => {
      state.chatService = chatService;
    },

    setCurrentOfflineEvent: (
      state: GlobalAppState,
      {
        payload: { offlineTime, offlineBatchId, totalChunksQueued }
      }: PayloadAction<{ offlineTime: string; offlineBatchId: string; totalChunksQueued: number }>
    ) => {
      state.currentOfflineEvent = {
        offlineBatchId,
        offlineTime,
        totalChunksQueued
      };
    },
    setSessionStatus: (
      state: GlobalAppState,
      {
        payload: { status }
      }: PayloadAction<{
        status: string;
      }>
    ) => {
      state.sessionStatus = status;
    },
    setToInitialState: (state) => {
      return {
        state,
        ...initialAppState,
        isReadyToLoadNewSession: true,
        session: {
          ...initialAppState.session,
          old_session_uuid: state.session?.session_uuid,
          session_type: state.session.session_type
        },
        configuration: {
          ...initialAppState.configuration,
          brand_color: state.configuration?.brand_color || "",
          brand_logo: state.configuration?.brand_logo || "",
          feedback: state.configuration?.feedback || false
        }
      };
    },
    setIsPaused: (
      state: GlobalAppState,
      { payload: { isPaused } }: PayloadAction<{ isPaused: boolean }>
    ) => {
      state.isPaused = isPaused;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(alertApi.endpoints.createAlert.matchFulfilled, (state, action) => {
      if (action.meta?.arg?.originalArgs) {
        const { alert_type_id } = action.meta.arg.originalArgs;
        if (alert_type_id === AlertType[AlertName.SessionCompleted].id) {
          state.sessionStatus = SessionStatus.Completed;
        }
      }
    });
  }
});

export const {
  setVerificationTokenApiDetails,
  setSessionTokenApiDetails,
  setLanguage,
  setUiErrorScreen,
  setUiPauseScreen,
  setFireBaseState,
  setChannelState,
  setFireBaseConnectionState,
  setAudioSourceInput,
  setSASToken,
  setChatOpen,
  setTwilioAudioCallStatus,
  setVideoSourceInput,
  setIsCameraGrabEnabled,
  setIsFaceDetectionEnabled,
  setVideoStreamingEnabled,
  setScreenRecordingEnabled,
  setNotificationAlerts,
  removeToastedAlert,
  setPrecheckSuccess,
  setIsOnline,
  setIsStreamingDisconnected,
  setClientConfiguration,
  setLinkAndVideo,
  setVideoThumbnail,
  setProctorCandidateChat,
  setCurrentOfflineEvent,
  setMediaPermission,
  setChatService,
  setPreviewStyle,
  setSessionStatus,
  setIsObjectDetectionEnabled,
  setToInitialState,
  setPrecheckStartTime,
  setIsPaused
} = appSlice.actions;

export const selectAppState = (state: GlobalAppState) => state;

export default appSlice;
