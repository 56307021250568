import { appId, proctoringApiBaseUrl } from "config/constant";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { StreamParticipantPayload } from "globals/interfaces";
import { RootState } from "store/store";

export interface StreamParticipant {
  roomId: string;
  streamName: number;
  token: string;
}

export const streamApi = createApi({
  reducerPath: "streamApi",
  baseQuery: fetchBaseQuery({
    baseUrl: proctoringApiBaseUrl,
    prepareHeaders: (headers: Headers, { getState }) => {
      const session = (getState() as RootState).app.session;
      headers.set("app-id", appId as string);
      headers.set("session-token", session.session_token!);
      headers.set("x-talview-client-version", "0.0.0");
    }
  }),
  endpoints: (builder) => ({
    streamParticipant: builder.mutation({
      query: (payload: StreamParticipantPayload) => {
        return {
          url: "v1/stream/participant",
          method: "POST",
          body: payload?.participant_group_id ? payload : undefined
        };
      },
      transformResponse: (response: StreamParticipant) => {
        return response;
      }
    })
  })
});

export const { useStreamParticipantMutation } = streamApi;
