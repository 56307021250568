import { useEffect } from "react";
import FooterLogo from "components/FooterLogo";
import Header from "components/Header";
import arrow from "assets/img/arrow.svg";
import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
import QRCode from "qrcode.react";
import { useAppSelector } from "store/store";
import { secondaryDeviceAppUrl } from "config/constant";
import { FullScreenState, SecondaryDeviceStatus, StorageKeyNames } from "globals/enums";
import { getEndpoint, ProctoringRoute } from "routes/route";
import { nextScreen } from "routes/workflow";
import { useNavigate } from "react-router-dom";
import play from "assets/img/play.png";
import { hideProview } from "helpers/hideProview";
import { FormattedMessage } from "react-intl";
import { ScreenShareState } from "../services/screenShare";

const SecondaryDevice = () => {
  const navigate = useNavigate();
  const globalState = useAppSelector((state) => state.app);
  const {
    configuration,
    fireBaseState: { secondary_device_status },
    session: { secondary_camera_token, session_type }
  } = globalState;
  const { fullScreenStatus }: ScreenShareState = useAppSelector((state) => state.screenShare);

  const qrCodeUrl = `${secondaryDeviceAppUrl}?sed_token=${secondary_camera_token}`;

  useEffect(() => {
    if (!secondary_device_status) {
      sessionStorage.setItem(StorageKeyNames.Screen, ProctoringRoute.SecondaryDevice);
      return;
    }
    const currentScreen = sessionStorage.getItem(StorageKeyNames.Screen);
    if (secondary_device_status === SecondaryDeviceStatus.Connected) {
      if (currentScreen === ProctoringRoute.RecorderScreen) {
        if (fullScreenStatus !== FullScreenState.Started && configuration.full_screen_mode) {
          navigate(getEndpoint(ProctoringRoute.FullScreen));
          return;
        }
        hideProview();
        return;
      }

      if (currentScreen !== ProctoringRoute.SecondaryDevice) {
        navigate(getEndpoint(currentScreen));
      } else {
        const nextScreenToNavigate = nextScreen(
          ProctoringRoute.SecondaryDevice,
          configuration,
          session_type
        );
        navigate(getEndpoint(nextScreenToNavigate));
      }
    }
  }, [secondary_device_status]);

  return (
    <Base>
      <Header
        title={<FormattedMessage id="pages.secondaryDevice.header" />}
        titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
      />
      <Content>
        <div className="tvp-seccam-Wrapper">
          <div className="tvp-seccam-main">
            <div className="tvp-lipart-seccam">
              <li>
                <div className={"tvp-flex tvp-flex-row tvp-mr-2.5"}>
                  <img
                    className={"tvp-flex tvp-flex-row tvp-w-3 tvp-h-3 tvp-mt-1.5"}
                    src={arrow}
                    alt="Arrow indicating instruction one"
                  />
                </div>
                <div className={"tvp-w-11/12 tvp-align-left"}>
                  <FormattedMessage id="page.secondaryDevice.instruction1" />
                </div>
              </li>
              <li>
                <div className={"tvp-flex tvp-flex-row tvp-mr-2.5"}>
                  <img
                    className={"tvp-flex tvp-flex-row tvp-w-3 tvp-h-3 tvp-mt-1.5"}
                    src={arrow}
                    alt="Arrow indicating instruction two"
                  />
                </div>
                <div className={"tvp-w-11/12 tvp-align-left"}>
                  <FormattedMessage id="page.secondaryDevice.instruction2" />
                </div>
              </li>
              <li>
                <div className={"tvp-flex tvp-flex-row tvp-mr-2.5"}>
                  <img
                    className={"tvp-flex tvp-flex-row tvp-w-3 tvp-h-3 tvp-mt-1.5"}
                    src={arrow}
                    alt="Arrow indicating instruction three"
                  />
                </div>
                <div className={"tvp-w-11/12 tvp-align-left"}>
                  <FormattedMessage id="page.secondaryDevice.instruction3" />
                </div>
              </li>
            </div>
          </div>
          <div className="tvp-seccam-main">
            <video
              className="tvp-testEnvDemoVideo tvp-or2-1 tvp-mb-4"
              src="https://cdn.proview.io/assets/secondary_cam.mp4"
              id="tvp_demo_video"
              controls
              disablePictureInPicture
              controlsList="nodownload"
            ></video>
          </div>
          <div className="tvp-seccam-main">
            <QRCode value={qrCodeUrl} />
          </div>
          <div className="tvp-seccam-main tvp-align-center tvp-pr-4 ">
            <img src={play} className="tvp-demoBtn" alt="Play video icon" />
            <FormattedMessage id="page.secondaryDevice.instruction4" />
          </div>
        </div>
      </Content>
      <FooterLogo />
    </Base>
  );
};
export default SecondaryDevice;
