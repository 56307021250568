import React, { useRef, useEffect } from "react";

type Props = React.PropsWithChildren & {
  onClick: () => void;
};

const ComponentToCloseOnClickOutside: React.FC<Props> = ({ onClick, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const chatBoxes = document.getElementsByClassName("chatBox");
      let isClickInside = false;
      for (let i = 0; i < chatBoxes.length; i++) {
        if (chatBoxes[i].contains(event.target as Node)) {
          isClickInside = true;
          break;
        }
      }

      if (!isClickInside) {
        onClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClick]);

  return (
    <div ref={ref} className="chatBox">
      {children}
    </div>
  );
};

export default ComponentToCloseOnClickOutside;
