import { useEffect, useState } from "react";
import Header from "components/Header";
import AudioProgressBar from "components/AudioProgressBar";
import { ClientErrorTypes, SessionStatus } from "globals/enums";
import FooterLogo from "components/FooterLogo";
import { FormattedMessage } from "react-intl";
import { useAppSelector, useAppDispatch } from "store/store";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { AlertLogConfig } from "services/session-token";
import Base from "components/layouts/Base";
import {
  setIsCameraGrabEnabled,
  setIsFaceDetectionEnabled,
  setLinkAndVideo,
  setVideoStreamingEnabled,
  setScreenRecordingEnabled,
  setToInitialState
} from "store/app.slice";
import { useNavigate } from "react-router-dom";
import { getEndpoint, ProctoringRoute } from "../routes/route";
import { handleError } from "helpers/sentry";

const EndScreen = () => {
  const cameraGrabQueue = useAppSelector((state) => state.cameraGrabQueue);
  const sessionStatus = useAppSelector((state) => state.app.sessionStatus);
  const isReadyToLoadNewSession = useAppSelector((state) => state.app.isReadyToLoadNewSession);
  const screenGrabQueue = useAppSelector((state) => state.screenGrabQueue);
  const videoRecordingQueue = useAppSelector((state) => state.videoRecordingQueue);
  const twilioAudioCall = useAppSelector((state) => state.app.twilioAudioCall);
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const isFeedbackEnabled = useAppSelector((state) => state.app.configuration.feedback);
  const [uploadProgressLevel, setUploadProgressLevel] = useState<number>(0);
  const dispatch = useAppDispatch();
  const [totalQueueLength, setTotalQueueLength] = useState<number>(0);
  const navigate = useNavigate();

  const updateProgress = (totalQueue: number) => {
    const pendingVideoQueue = videoRecordingQueue.items.length;
    const pendingScreenGrabQueue = screenGrabQueue.items.length;
    const pendingCameraGrabQueue = cameraGrabQueue.items.length;

    const progress = totalQueue
      ? 100 -
        ((pendingVideoQueue + pendingScreenGrabQueue + pendingCameraGrabQueue) / totalQueue) * 100
      : 100;
    setUploadProgressLevel(progress);
  };

  useEffect(() => {
    updateProgress(totalQueueLength);
  }, [videoRecordingQueue.items, screenGrabQueue.items, videoRecordingQueue.items]);

  useEffect(() => {
    const totalQueue =
      videoRecordingQueue.items.length +
      screenGrabQueue.items.length +
      cameraGrabQueue.items.length;

    setTotalQueueLength(totalQueue);

    updateProgress(totalQueue);

    if (twilioAudioCall) {
      twilioAudioCall.destroy();
    }
    const tvp_chat_container = document.getElementById("tvp-chat-container") as HTMLDivElement;
    const draggableComponent = document?.getElementById("tvp_recorder_holder");
    if (draggableComponent) {
      draggableComponent.remove();
    }
    if (tvp_chat_container) {
      tvp_chat_container.remove();
    }
    dispatch(setIsCameraGrabEnabled({ isCameraGrabEnabled: false }));
    dispatch(setIsFaceDetectionEnabled({ isFaceDetectionEnabled: false }));
    dispatch(setVideoStreamingEnabled({ isVideoStreamingEnabled: false }));
    dispatch(setScreenRecordingEnabled({ isScreenRecordingEnabled: false }));
    dispatch(setLinkAndVideo({ showLinkAndVideo: false }));

    const element = document?.getElementById("proview_root");
    if (element) {
      element.style.backgroundColor = "#000";
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (uploadProgressLevel === 100) {
        alertHelper(alertConfig).raiseAlert(AlertName.SessionCompleted, dispatch);
      }
    }, 3000);
  }, [uploadProgressLevel]);

  useEffect(() => {
    if (sessionStatus === SessionStatus.Completed) {
      dispatch(setToInitialState());
    }
  }, [sessionStatus]);

  useEffect(() => {
    if (isReadyToLoadNewSession) {
      if (isFeedbackEnabled) {
        navigate(getEndpoint(ProctoringRoute.Feedback));
      } else {
        const draggableComponent = document?.getElementById("tvp_recorder_container");
        if (draggableComponent) {
          while (draggableComponent.firstChild) {
            draggableComponent.removeChild(draggableComponent.firstChild);
          }
        }

        const element = document?.getElementById("proview_root");
        element && (element.style.display = "none");
        try {
          window.ProctorClient3.finalCallBack && window.ProctorClient3.finalCallBack();
        } catch (error) {
          handleError(error, ClientErrorTypes.SessionEndError);
        }
      }
    }
  }, [isReadyToLoadNewSession, isFeedbackEnabled]);

  return (
    <Base>
      <Header
        title={""}
        titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
      />
      <div className="tvp-h-auto tvp-bg-white tvp-flex-auto tvp-flex tvp-flex-col tvp-px-2 tvp-content tvp-pt-[60px]">
        <h1 className="tvp-text-[#4a4a4a] tvp-text-lg tvp-font-medium tvp-text-center">
          <FormattedMessage id="page.end.saving" />
        </h1>
        <p className="tvp-mt-1.5 tvp-mb-[60px] tvp-text-center tvp-text-[#4a4a4a] tvp-text-sm">
          <FormattedMessage id="page.EndScreen.upload.waitingText" />
        </p>
        <div className="tvp-mt-4 tvp-mb-2 tvp-text-center">
          <AudioProgressBar width={uploadProgressLevel === 0 ? 1 : uploadProgressLevel} />
        </div>
        <div className="tvp-w-[60%]">
          {uploadProgressLevel === 100 && (
            <p className="tvp-text-sm">
              <span className="tvp-font-medium">{uploadProgressLevel}</span>%{" "}
              <FormattedMessage id="page.EndScreen.uploaded.text" />
            </p>
          )}
          {uploadProgressLevel !== 100 && (
            <p className=" tvp-text-sm ">
              <FormattedMessage id="page.EndScreen.uploadSpeed.text" />
            </p>
          )}
        </div>
      </div>
      <FooterLogo />
    </Base>
  );
};

export default EndScreen;
