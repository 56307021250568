import { PrecheckState } from "globals/enums";
import { ProctoringRoute } from "routes/route";

export const recheckScreen = (
  photoIdStatus: string | undefined,
  testEnvStatus: string | undefined
) => {
  const navigateToRecheckScreen =
    photoIdStatus === PrecheckState.Rejected
      ? ProctoringRoute.PhotoIDVerification
      : photoIdStatus !== PrecheckState.Rejected && testEnvStatus === PrecheckState.Rejected
      ? ProctoringRoute.TestEnvCapture
      : null;
  return navigateToRecheckScreen;
};
