import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const Base: React.FC<Props> = ({ children }) => {
  return (
    <div id="basediv">
      <div
        className="tvp-flex tvp-flex-col tvp-justify-start tvp-h-auto tvp-max-w-2xl tvp-min-w-2xl 
  tvp-rounded-lg tvp-bg-white tvp-my-0 tvp-px-2.5 tvp-py-4 tvp-overflow-x-hidden tvp-base"
      >
        {children}
      </div>
    </div>
  );
};

export default Base;
