import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import mixpanel from "config/mixpanel";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  AccessStatus,
  BrowserName,
  FullScreenState,
  ScreenShareEvent,
  ScreenShareStatus
} from "globals/enums";
import { getEndpoint, ProctoringRoute } from "routes/route";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { AlertLogConfig } from "services/session-token";
import { ScreenShareState, updateFullScreenState, updateScreenGrab } from "services/screenShare";
import { MediaContext } from "context/MediaContext";
import { getSharedScreenEvent, getUserBrowser } from "helpers/browser-helper";
import { showProview } from "helpers/showProview";
import { setScreenRecordingEnabled } from "store/app.slice";

const screenHeight = window.outerHeight;

const body = document.body,
  html = document.documentElement;

const documentHeight = Math.max(
  body.scrollHeight,
  body.offsetHeight,
  html.clientHeight,
  html.scrollHeight,
  html.offsetHeight
);

const useScreenShareFullScreenCheck = () => {
  const configuration = useAppSelector((state) => state.app.configuration);
  const { screenShareStatus, fullScreenStatus }: ScreenShareState = useAppSelector(
    (state) => state.screenShare
  );
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const { full_screen_mode } = useAppSelector((state) => state.app.configuration);
  const precheckSuccess = useAppSelector((state) => state.app.precheckSuccess);
  const { displayStream, displayMediaStreamError, startDisplayMedia, stopDisplayMedia } =
    useContext(MediaContext);

  const userBrowser = getUserBrowser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleScreenShare = () => {
    alertHelper(alertConfig).raiseAlert(AlertName.ScreenCaptureEnabled, dispatch);
    dispatch(updateScreenGrab({ screenShareStatus: ScreenShareStatus.ScreenShareStarted }));
    if (configuration.screen_recording) {
      dispatch(setScreenRecordingEnabled({ isScreenRecordingEnabled: true }));
    }
  };

  const checkPermissions = async () => {
    // TODO : Need to fix it later for firefox. Currently, Skip the checkPermissions.
    if (userBrowser === BrowserName.Firefox) return true;
    const cameraState = await navigator.permissions.query({ name: "camera" as PermissionName });
    const micState = await navigator.permissions.query({ name: "microphone" as PermissionName });
    // Note : Notifications permission is not required for screen share. so commenting it out for stop screenshare permission check.
    // const notificationsState = await navigator.permissions.query({
    //   name: "notifications" as PermissionName
    // });
    return (
      cameraState.state === AccessStatus.Granted && micState.state === AccessStatus.Granted
      // &&
      // notificationsState.state === AccessStatus.Granted
    );
  };

  const onScreenShareBeingStoppedByUser = async (displayStream: MediaStream) => {
    displayStream.getVideoTracks()[0].onended = async function () {
      disableScreenGrab();
      alertHelper(alertConfig).raiseAlert(AlertName.ScreenCaptureStopped, dispatch);
      const showError = await checkPermissions();
      if (showError) {
        showProview();
        handlePermissionCheckFailed();
        navigate(getEndpoint(ProctoringRoute.ScreenShare));
      }
    };
  };

  const handlePermissionCheckFailed = (err?: string) => {
    dispatch(
      updateScreenGrab({
        screenShareStatus: ScreenShareStatus.PermissionFailed,
        screenShareError: err || ""
      })
    );
    if (full_screen_mode && screenHeight !== documentHeight) {
      dispatch(updateFullScreenState({ fullScreenStatus: FullScreenState.Stopped }));
    }
  };

  useEffect(() => {
    document.addEventListener("StopProctorClient", async () => {
      disableScreenGrab(true);
      stopDisplayMedia();
    });
    document.addEventListener("Terminated", async () => {
      disableScreenGrab(true);
      stopDisplayMedia();
    });
    document.addEventListener("StopScreenShare", () => {
      disableScreenGrab();
      stopDisplayMedia();
    });
  }, [displayStream]);

  useEffect(() => {
    if (displayStream && displayStream.active) {
      const sharedScreenEvent = getSharedScreenEvent(displayStream?.getVideoTracks(), userBrowser);
      if (sharedScreenEvent === ScreenShareEvent.EntireScreenShare) {
        handleScreenShare();
      } else {
        displayStream?.getVideoTracks().forEach((track) => track.stop());
        handlePermissionCheckFailed(sharedScreenEvent);
      }
      mixpanel.track(sharedScreenEvent);
      onScreenShareBeingStoppedByUser(displayStream);
    }
  }, [displayStream]);

  const handleInitializeScreenShare = async () => {
    if (!displayStream || !displayStream.active) {
      await startDisplayMedia({
        audio: false,
        video: {
          frameRate: 15
        }
      });
    }
  };

  const disableScreenGrab = (isProctoringStopped?: boolean) => {
    dispatch(
      updateScreenGrab({
        screenShareStatus: ScreenShareStatus.ScreenShareStopped
      })
    );
    if (full_screen_mode) {
      if (isProctoringStopped) document.onfullscreenchange = () => {};
      else if (screenHeight !== documentHeight)
        dispatch(updateFullScreenState({ fullScreenStatus: FullScreenState.Stopped }));

      exitFullScreen();
    }
  };

  useEffect(() => {
    if (displayMediaStreamError) {
      handlePermissionCheckFailed(displayMediaStreamError?.message);
    }
  }, [displayMediaStreamError]);

  useEffect(() => {
    if (
      screenShareStatus === ScreenShareStatus.InitializePermission ||
      screenShareStatus === ScreenShareStatus.ReInitializePermission
    ) {
      handleInitializeScreenShare();
    }
  }, [screenShareStatus]);

  // Handle exiting fullscreen whenever screenshare is stopped.
  const exitFullScreen = () => {
    if (document?.exitFullscreen && !!document.fullscreenElement) {
      document.exitFullscreen();
    }
  };

  const onFullScreenChange = (e) => {
    if (
      document.fullscreenElement === null &&
      e.target.clientHeight !== screenHeight &&
      screenShareStatus !== ScreenShareStatus.PermissionFailed
    ) {
      console.log(AlertName.ExitFullscreen);
      exitFullScreenActions();
    } else {
      console.log(AlertName.EnterFullscreen);
    }
  };

  const onWindowFullScreenChange = (e) => {
    const screenSize = e.target.screen.height;
    const documentSize = e.target.parent.document.documentElement.clientHeight;

    if (screenSize === documentSize) {
      alertHelper(alertConfig).raiseAlert(AlertName.EnterFullscreen, dispatch);
      dispatch(updateFullScreenState({ fullScreenStatus: FullScreenState.Started }));
    } else {
      exitFullScreenActions();
    }
  };

  const exitFullScreenActions = () => {
    if (fullScreenStatus !== FullScreenState.Stopped) {
      alertHelper(alertConfig).raiseAlert(AlertName.ExitFullscreen, dispatch);
      dispatch(updateFullScreenState({ fullScreenStatus: FullScreenState.Stopped }));
    }
  };

  useEffect(() => {
    if (
      screenShareStatus === ScreenShareStatus.ScreenShareStarted &&
      fullScreenStatus === FullScreenState.Stopped
    ) {
      showProview();
      navigate(getEndpoint(ProctoringRoute.FullScreen));
    }
  }, [screenShareStatus, fullScreenStatus]);

  // Handle any fullscreen change caused by screenshare or any other medium.
  useEffect(() => {
    if (full_screen_mode && precheckSuccess) {
      window.onresize = (e) => onWindowFullScreenChange(e);
      document.onfullscreenchange = (e) => onFullScreenChange(e);
    }
    return () => {
      if (full_screen_mode) {
        document.onfullscreenchange = () => {};
      }
    };
  }, [full_screen_mode, precheckSuccess]);
};

export default useScreenShareFullScreenCheck;
