import { MessageFormatElement } from "react-intl";
import { AppLocale } from "globals/enums";
import enMessages from "lang/translations/en-US.json";
import cnMessages from "lang/translations/zh-CN.json";
import cyMessages from "lang/translations/cy-GB.json";
import frMessages from "lang/translations/fr-FR.json";
import jaMessages from "lang/translations/ja-JP.json";
import esMessages from "lang/translations/es-ES.json";
import ptbrMessages from "lang/translations/pt-BR.json";

export interface MessagesEntry {
  messages: Record<string, string> | Record<string, MessageFormatElement[]> | undefined;
  locale: string;
}

export const englishMessages: MessagesEntry = {
  messages: { ...enMessages },
  locale: AppLocale.English
};

export const appMessages: MessagesEntry[] = [
  { messages: enMessages, locale: AppLocale.English },
  { messages: cnMessages, locale: AppLocale.Chinese },
  { messages: cyMessages, locale: AppLocale.Welsh },
  { messages: frMessages, locale: AppLocale.French },
  { messages: jaMessages, locale: AppLocale.Japanese },
  { messages: ptbrMessages, locale: AppLocale.BrazilianPortuguese },
  { messages: esMessages, locale: AppLocale.Spanish }
];
