import { handleError } from "helpers/sentry";
import React from "react";
import { useAppSelector, useAppDispatch } from "store/store";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { AlertLogConfig } from "services/session-token";

const useDetectMediaDevices = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [videoInputDetected, setVideoInputDetected] = React.useState<boolean>(false);
  const [audioInputDetected, setAudioInputDetected] = React.useState<boolean>(false);
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const dispatch = useAppDispatch();

  async function detectMediaDevices() {
    setLoading(true);
    try {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      let cameraAlertDetails;
      if (mediaDevices.some((mediaDevice) => mediaDevice.kind === "videoinput")) {
        setVideoInputDetected(true);
        cameraAlertDetails = AlertName.CameraDetected;
      } else {
        setVideoInputDetected(false);
        cameraAlertDetails = AlertName.CameraNotDetected;
      }
      cameraAlertDetails && alertHelper(alertConfig).raiseAlert(cameraAlertDetails, dispatch);

      let audioAlertDetails;
      if (mediaDevices.some((mediaDevice) => mediaDevice.kind === "audioinput")) {
        setAudioInputDetected(true);
        audioAlertDetails = AlertName.AudioDeviceDetected;
      } else {
        setAudioInputDetected(false);
        audioAlertDetails = AlertName.AudioDeviceNotDetected;
      }
      audioAlertDetails && alertHelper(alertConfig).raiseAlert(audioAlertDetails, dispatch);
    } catch (e: any) {
      handleError(e);
    } finally {
      setLoading(false);
    }
    return () => {
      setLoading(false);
      setVideoInputDetected(false);
      setAudioInputDetected(false);
    };
  }

  React.useEffect(() => {
    detectMediaDevices();
  }, []);

  return [videoInputDetected, audioInputDetected, loading];
};

export default useDetectMediaDevices;
