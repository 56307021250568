import React from "react";
import EmptyRating from "assets/img/empty_rating.svg";
import { useAppSelector } from "../store/store";

type RatingProps = {
  rating: number;
  updateRating: (rating: number) => void;
};

const Rating = (props: RatingProps) => {
  const { rating, updateRating } = props;
  const { configuration } = useAppSelector((state) => state.app);

  const checkAndUpdateRating = (newRating: number) => {
    if (newRating === rating) updateRating(0);
    else updateRating(newRating);
  };
  return (
    <div className="tvp-flex tvp-gap-4">
      {new Array(5).fill("").map((value, index) => (
        <div
          className="tvp-max-w-[1.625rem]"
          onClick={() => checkAndUpdateRating(index + 1)}
          key={"rating" + index}
          data-testid={"rating" + index}
        >
          {rating < index + 1 ? (
            <img className="tvp-cursor-pointer" src={EmptyRating} alt="Empty Rating" />
          ) : (
            <svg
              width="26"
              height="25"
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.2606 0.499105C12.3207 0.351489 12.4229 0.22521 12.5544 0.136333C12.6859 0.0474568 12.8406 0 12.9989 0C13.1573 0 13.312 0.0474568 13.4435 0.136333C13.575 0.22521 13.6772 0.351489 13.7373 0.499105L16.7545 7.81203C16.811 7.94887 16.9039 8.06735 17.0229 8.15444C17.1419 8.24154 17.2824 8.29386 17.429 8.30566L25.2639 8.93809C25.9724 8.99532 26.2592 9.88672 25.7197 10.3517L19.7505 15.5056C19.639 15.6017 19.5559 15.7269 19.5103 15.8675C19.4647 16.0081 19.4584 16.1586 19.4921 16.3025L21.3166 24.0075C21.3533 24.1621 21.3437 24.3241 21.289 24.4732C21.2343 24.6223 21.137 24.7517 21.0094 24.8451C20.8818 24.9385 20.7296 24.9917 20.572 24.9979C20.4144 25.0042 20.2584 24.9633 20.1239 24.8803L13.415 20.7524C13.2896 20.6754 13.1457 20.6347 12.9989 20.6347C12.8522 20.6347 12.7082 20.6754 12.5829 20.7524L5.87397 24.8817C5.73944 24.9647 5.58354 25.0056 5.42593 24.9994C5.26833 24.9931 5.11611 24.9399 4.98848 24.8465C4.86086 24.7531 4.76357 24.6237 4.70889 24.4747C4.65421 24.3256 4.64459 24.1635 4.68127 24.0089L6.50582 16.3025C6.53965 16.1586 6.53343 16.008 6.48784 15.8674C6.44225 15.7268 6.35905 15.6016 6.2474 15.5056L0.278206 10.3517C0.158671 10.2482 0.0721955 10.1113 0.0296546 9.95831C-0.0128862 9.80534 -0.00959287 9.64311 0.0391205 9.49202C0.0878339 9.34093 0.179792 9.20773 0.303431 9.10917C0.42707 9.01061 0.576869 8.95108 0.733989 8.93809L8.56891 8.30566C8.71551 8.29386 8.85604 8.24154 8.97503 8.15444C9.09403 8.06735 9.18687 7.94887 9.24335 7.81203L12.2606 0.499105Z"
                fill={configuration.brand_color || "#1CB841"}
              />
            </svg>
          )}
        </div>
      ))}
    </div>
  );
};

export default Rating;
