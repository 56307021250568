import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { AlertLogConfig } from "services/session-token";

const useDetectPasteText = () => {
  const dispatch = useAppDispatch();
  const alertConfig: AlertLogConfig[] = useSelector((state: RootState) => state.app.alertConfigs);
  const precheckSuccess = useSelector((state: RootState) => state.app.precheckSuccess);

  const handlePasteEvent = () => {
    alertHelper(alertConfig).raiseAlert(AlertName.PastingTextDetected, dispatch);
  };

  const stop = () => {
    document.removeEventListener("paste", handlePasteEvent);
  };
  useEffect(() => {
    const detectPasteText = () => {
      if (precheckSuccess) {
        document.addEventListener("paste", handlePasteEvent);
      } else {
        stop();
      }
    };

    detectPasteText();

    return () => {
      stop();
    };
  }, [precheckSuccess]);
};

export default useDetectPasteText;
