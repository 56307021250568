import { getStreamAppKey } from "config/constant";
import { StreamChat } from "stream-chat";
import { Role } from "globals/enums";
import { ChannelState } from "globals/interfaces";
import { handleError } from "helpers/sentry";
import { Chat } from "services/session-token";
import { getSystemTime } from "helpers/common";

interface ChatService {
  markAllMessagesAsRead: any;
  markAllMessagesAsDelivered: any;
  markAsDelivered: any;
  sendMessage: any;
  getMessages: any;
}

export async function initializeChat(
  chatDetails: Chat,
  channelData: ChannelState,
  handleEvent: any
): Promise<ChatService> {
  let client: any;
  let channelRef: any;
  try {
    client = StreamChat.getInstance(getStreamAppKey as string, {
      enableWSFallback: true,
      enableInsights: true,
      recoverStateOnReconnect: true,
      warmUp: true
    });
    const user_id: string = chatDetails?.user_id;
    const token: string = chatDetails?.token;
    const channel_id: string = channelData?.channels?.private?.id;
    await client.connectUser(
      {
        id: user_id,
        name: Role.Candidate
      },
      token
    );
    channelRef = await initializeChannel(channel_id, client, handleEvent);
  } catch (error) {
    handleError(error);
  }

  return {
    markAllMessagesAsRead: async () => {
      if (channelRef && channelRef.markRead) {
        await channelRef.markRead();
        const messages = channelRef.state.messages;
        messages.forEach((message: { read_at: any; user?: { id: string | any[] } }) => {
          if (!message.read_at) {
            markMessageAsRead(message, client);
          }
        });
      }
    },
    markAllMessagesAsDelivered: async () => {
      if (channelRef && channelRef.markRead && channelRef.countUnread() > 0) {
        await channelRef.markRead();
        const messages = channelRef.state.messages;
        messages.forEach(
          (message: { read_at?: any; received_at?: any; user?: { id: string | any[] } }) => {
            if (!message.received_at && !message.read_at) {
              markAsDelivered(message, client);
            }
          }
        );
      }
    },
    markAsDelivered: async (message: { id: any }) => {
      const updatedMessage = {
        received_at: getSystemTime().toISOString()
      };
      await client.partialUpdateMessage(message.id, {
        set: {
          ...updatedMessage
        }
      });
    },
    sendMessage: async function sendMessage(text: string) {
      return await channelRef.sendMessage({ text });
    },
    getMessages: () => {
      return channelRef?.state?.messages;
    }
  };
}

async function markMessageAsRead(message: any, client: any) {
  const updatedMessage = {
    read_at: getSystemTime().toISOString()
  };
  await client.partialUpdateMessage(message.id, {
    set: {
      ...updatedMessage
    }
  });
}

async function markAsDelivered(message: any, client: any) {
  const updatedMessage = {
    received_at: getSystemTime().toISOString()
  };
  await client.partialUpdateMessage(message.id, {
    set: {
      ...updatedMessage
    }
  });
}

const initializeChannel = async (channel_id: string, client: any, handleEvent: any) => {
  let aChannel: any;
  try {
    aChannel = await client.channel("messaging", channel_id);
    await aChannel.watch();
    aChannel.on("channel.hidden", handleEvent);
    aChannel.on("message.new", handleEvent);
    aChannel.on(handleEvent);
  } catch (error) {
    handleError(error);
  }
  return aChannel;
};
