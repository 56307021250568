import { useSelector } from "react-redux";
import { dequeueItem } from "store/videoRecordingSlice";
import { useAppDispatch, RootState } from "store/store";
import { useCreateBlobMutation } from "services/blobStorage";
import { ClientErrorTypes, SessionScope } from "globals/enums";
import { useVideoFileMutation } from "services/platform-stream";
import { getVideoUploadBlobPayload } from "helpers/blob-payload";
import { AlertName } from "helpers/alert-type";
import { alertHelper } from "helpers/alert-helper";
import { handleError } from "helpers/sentry";
import useQueueProcessor from "./useQueueProcessor";

const useVideoRecordingUploader = () => {
  const dispatch = useAppDispatch();
  const [createBlob] = useCreateBlobMutation();
  const app = useSelector((state: RootState) => state.app);
  const [videoFile] = useVideoFileMutation();
  const queueSelector = (state: any) => state.videoRecordingQueue;

  const uploadVideoChunk = async (item: any) => {
    try {
      if (item.offlineBatchId) {
        await createBlob({
          ...getVideoUploadBlobPayload(app, item.blob, item.offlineBatchId),
          offline_batch_id: item.offlineBatchId,
          offline_sequence_id: item.offlineSequenceId,
          offline_at: item.offlineTime
        }).unwrap();
      } else if (app.session.session_type === SessionScope.LiveProctor) {
        await videoFile({
          blob: item.blob,
          sessionToken: app.session.session_token,
          startTime: item.startTime
        }).unwrap();
      } else {
        await createBlob(getVideoUploadBlobPayload(app, item.blob)).unwrap();
      }
    } catch (error: any) {
      const errorMessage = `Azure Storage Blob: Error in video File Upload, Alert Type Id: 112, Error Message: ${error?.message}`;
      alertHelper(app.alertConfigs).raiseAlert(AlertName.FileUploadFailure, dispatch, errorMessage);
    }
  };

  const uploadError = (error: unknown) => {
    handleError(error, ClientErrorTypes.OfflineVideoUploadError);
  };

  useQueueProcessor(queueSelector, dequeueItem, uploadVideoChunk, uploadError);
};

export default useVideoRecordingUploader;
