import { ProctoringRoute, getEndpoint } from "routes/route";
import { FormattedMessage } from "react-intl";
import Button from "components/Button";
import FooterLogo from "components/FooterLogo";
import Header from "components/Header";
import ListInstruction from "components/ListInstruction";
import React from "react";
import WelcomeLogo from "assets/img/welcome.png";
import { useNavigate } from "react-router-dom";

import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
function Welcome() {
  const navigate = useNavigate();
  const handleNext = () => {
    navigate(getEndpoint(ProctoringRoute.HardwareTest));
  };
  const list = [
    {
      id: 1,
      item: <FormattedMessage id="page.welcome.instructions.textOne" />,
      altText: "Arrow indicating instruction one"
    },
    {
      id: 2,
      item: <FormattedMessage id="page.welcome.instructions.textTwo" />,
      altText: "Arrow indicating instruction two"
    },
    {
      id: 3,
      item: <FormattedMessage id="page.welcome.instructions.textThree" />,
      altText: "Arrow indicating instruction three"
    }
  ];
  return (
    <Base>
      <Header
        title={<FormattedMessage id="page.welcome.header" />}
        titleClass={"tvp-text-sm tvp-font-semibold clr-green tvp-uppercase tvp-ml-2.5"}
      />
      <Content>
        <div className="tvp-flex col-row tvp-my-auto align-center tvp-justify-center">
          <ListInstruction instList={list} className="or1-2 tvp-mt-3 tvp-mb-4" />
          <img className="tvp-p-4 or2-1 tvp-mt-3 " src={WelcomeLogo} alt="WelcomeLogo" />
        </div>
        <Button
          label={<FormattedMessage id="button.next" />}
          className={
            "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer clr-white tvp-mx-auto bg-green bdr-green tvp-w-150"
          }
          onClick={handleNext}
        />
      </Content>
      <FooterLogo />
    </Base>
  );
}

export default Welcome;
