import { useState, useEffect, useRef } from "react";
import { To, useNavigate } from "react-router";
import { store, useAppDispatch, useAppSelector } from "store/store";
import { ProctoringRoute, getEndpoint } from "routes/route";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  ErrorTypes,
  PauseProctorReasonCodes,
  ScreenShareStatus,
  StorageKeyNames
} from "globals/enums";
import { setCurrentOfflineEvent, setIsOnline, setUiErrorScreen } from "store/app.slice";
import { candidateOfflineThreshold } from "config/constant";
import { AlertName, AlertType } from "helpers/alert-type";
import FreshChat from "config/freshchat";
import { showPauseProctorScreen, stopRecording } from "helpers/pauseProctor";
import { enqueueItem } from "store/priorityAlertSlice";
import { getSystemTime } from "helpers/common";

const useWatchNetworkStatus = () => {
  const [queueOnlineAlert, setQueueOnlineAlert] = useState(false);
  const cameraGrabQueue = useAppSelector((state) => state.cameraGrabQueue);
  const screenGrabQueue = useAppSelector((state) => state.screenGrabQueue);
  const alertQueue = useAppSelector((state) => state.alertQueue);
  const [networkStatus, setNetworkStatus] = useState(true);
  const currentOfflineEvent = useAppSelector((state) => state.app.currentOfflineEvent);
  const isOnline = useAppSelector((state) => state.app.isOnline);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let offlineStartTime = getSystemTime().getTime();
  let networkDetectionTimeout: NodeJS.Timeout | undefined;
  const networkOfflineTimer = useRef<NodeJS.Timer | null>(null);

  const reset = () => {
    clearInterval(networkDetectionTimeout);
    clearInterval(networkOfflineTimer.current as any);
  };

  const hideChatContainer = () => {
    const chatContainer = document.getElementById("tvp-chat-container") as HTMLElement;
    chatContainer.style.display = "none";
  };

  const goToScreen = (currentScreen: To | null, timeOffline: number) => {
    const screenShareStatus = store.getState().screenShare.screenShareStatus;
    if (timeOffline > Number(candidateOfflineThreshold) / 1000) {
      stopRecording(dispatch);
      FreshChat.unload();
      hideChatContainer();
      dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.StreamingReconnection }));
      navigate(getEndpoint(ProctoringRoute.Error));
    } else if (store.getState().app.isStreamingDisconnected) {
      dispatch(setUiErrorScreen({ uiErrorScreen: ErrorTypes.StreamingReconnection }));
      navigate(getEndpoint(ProctoringRoute.Error));
    } else if (
      screenShareStatus === ScreenShareStatus.PermissionFailed ||
      screenShareStatus === ScreenShareStatus.ReInitializePermission
    ) {
      navigate(getEndpoint(ProctoringRoute.ScreenShare));
    } else if (currentScreen) {
      navigate(currentScreen);
    }
  };

  const isSessionEnded = (currentScreen: string | null) => {
    return (
      currentScreen === ProctoringRoute.EndScreen || currentScreen === ProctoringRoute.Terminated
    );
  };

  const addOfflineAlert = (offlineTime, offlineBatchId) => {
    dispatch(
      enqueueItem({
        alert_type_id: AlertType[AlertName.CandidateOffline].id,
        description: AlertType[AlertName.CandidateOffline].alertName,
        timestamp: getSystemTime().toISOString(),
        metadata: {
          offline_batch_id: offlineBatchId,
          offline_at: offlineTime,
          alerts_queued: alertQueue?.items?.length || 0,
          screen_grabs_queued: screenGrabQueue?.items?.length || 0,
          camera_grabs_queued: cameraGrabQueue?.items?.length || 0,
          offline_video_chunks_queued: 0
        },
        sync: true
      })
    );
  };

  const setOnline = () => {
    const currentScreen = sessionStorage.getItem(StorageKeyNames.Screen);
    if (currentScreen === ProctoringRoute.RecorderScreen) {
      setQueueOnlineAlert(true);
    }
    setNetworkStatus(true);
    dispatch(setIsOnline({ isOnline: true }));
    reset();
    if (isSessionEnded(currentScreen)) {
      return;
    }
    const timeOffline = (getSystemTime().getTime() - offlineStartTime) / 1000;
    goToScreen(currentScreen, timeOffline);
  };

  const setOffline = () => {
    const offlineTime = getSystemTime().toISOString();
    offlineStartTime = getSystemTime().getTime();
    const offlineBatchId = uuidv4();
    dispatch(
      setCurrentOfflineEvent({
        offlineTime,
        offlineBatchId: offlineBatchId,
        totalChunksQueued: 0
      })
    );
    setNetworkStatus(false);
    dispatch(setIsOnline({ isOnline: false }));
    const currentScreen = sessionStorage.getItem(StorageKeyNames.Screen);
    if (currentScreen === ProctoringRoute.RecorderScreen) {
      addOfflineAlert(offlineTime, offlineBatchId);
    }
    networkDetectionTimeout = setTimeout(() => {
      toast.warning("Your connection seems to be offline");
    }, Number(candidateOfflineThreshold));
    if (isSessionEnded(currentScreen) || currentScreen === ProctoringRoute.RecorderScreen) {
      return;
    }
    showPauseProctorScreen(dispatch, navigate, PauseProctorReasonCodes.NetworkDisconnection);
  };

  useEffect(() => {
    if (queueOnlineAlert) {
      dispatch(
        enqueueItem({
          alert_type_id: AlertType[AlertName.CandidateOnline].id,
          description: AlertType[AlertName.CandidateOnline].alertName,
          timestamp: getSystemTime().toISOString(),
          metadata: {
            offline_batch_id: currentOfflineEvent.offlineBatchId,
            offline_at: currentOfflineEvent.offlineTime,
            online_at: getSystemTime().toISOString(),
            alerts_queued: alertQueue?.items?.length || 0,
            screen_grabs_queued: screenGrabQueue?.items?.length || 0,
            camera_grabs_queued: cameraGrabQueue?.items?.length || 0,
            offline_video_chunks_queued: currentOfflineEvent.totalChunksQueued
          },
          sync: true
        })
      );
      setQueueOnlineAlert(false);
    }
  }, [queueOnlineAlert, currentOfflineEvent, alertQueue, screenGrabQueue, cameraGrabQueue]);

  useEffect(() => {
    if (!isOnline) {
      networkOfflineTimer.current = setInterval(() => {
        window.ProctorClient3.networkDisconnectionCallback &&
          window.ProctorClient3.networkDisconnectionCallback();
      }, candidateOfflineThreshold);
    } else {
      toast.info("You are online now");
    }
  }, [isOnline]);

  const watchNetworkStatus = () => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);
  };

  useEffect(() => {
    watchNetworkStatus();
  }, []);

  return [networkStatus];
};

export default useWatchNetworkStatus;
