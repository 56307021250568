import { Outlet, RouterProvider, createMemoryRouter } from "react-router-dom";
import AudioProgressBar from "pages/AudioDetection";
import EnvTestCapture from "pages/EnvTestCapture";
import EnvTestInstructions from "pages/EnvTestInstructions";
import ErrorScreen from "pages/Error";
import ExamStart from "pages/ExamStart";
import FaceCapture from "pages/FaceCapture";
import FaceCaptureInstructions from "pages/FaceCaptureInstructions";
import HardwareTest from "pages/HardwareTest";
import AudioTestInstructions from "pages/AudioTestInstructions";
import Instructions from "pages/Instructions";
import PhotoIdVerification from "pages/PhotoIdVerification";
import PreFlightSuccess from "pages/PreFlightSuccess";
import { ProctoringRoute } from "routes/route";
import Shell from "pages/Shell";
import Splash from "pages/Splash";
import TermsOfUse from "pages/TermsOfUse";
import VerificationStatus from "pages/VerificationStatus";
import Welcome from "pages/Welcome";
import EndScreen from "pages/End";
import RecorderScreen from "pages/RecorderScreen";
import ScreenShare from "pages/ScreenSharePage";
import Terminated from "pages/Terminated";
import SecondaryDevice from "pages/SecondaryDevice";
import PauseProctorScreen from "pages/PauseProctorScreen";
import FullScreen from "pages/FullScreenPage";
import Feedback from "pages/Feedback";

export default function Navigation() {
  const routes = [
    {
      path: ProctoringRoute.Landing,
      element: (
        <>
          <Shell />
          <Outlet />
        </>
      ),
      children: [
        {
          index: true,
          element: <Splash />
        },
        {
          path: ProctoringRoute.TermsOfUse,
          element: <TermsOfUse />
        },
        {
          path: ProctoringRoute.Instructions,
          element: <Instructions />
        },
        {
          path: ProctoringRoute.Welcome,
          element: <Welcome />
        },
        {
          path: ProctoringRoute.HardwareTest,
          element: <HardwareTest />
        },
        {
          path: ProctoringRoute.PhotoIDVerification,
          element: <PhotoIdVerification />
        },
        {
          path: ProctoringRoute.FaceCaptureInstructions,
          element: <FaceCaptureInstructions />
        },
        {
          path: ProctoringRoute.FaceCapture,
          element: <FaceCapture />
        },
        {
          path: ProctoringRoute.HardwareTestInstructions,
          element: <AudioTestInstructions />
        },
        {
          path: ProctoringRoute.AudioDetection,
          element: <AudioProgressBar />
        },
        {
          path: ProctoringRoute.TestEnvInstructions,
          element: <EnvTestInstructions />
        },
        {
          path: ProctoringRoute.TestEnvCapture,
          element: <EnvTestCapture />
        },
        {
          path: ProctoringRoute.VerificationStatus,
          element: <VerificationStatus />
        },
        {
          path: ProctoringRoute.PreFlightSuccess,
          element: <PreFlightSuccess />
        },
        {
          path: ProctoringRoute.ExamStart,
          element: <ExamStart />
        },
        {
          path: ProctoringRoute.Error,
          element: <ErrorScreen />
        },
        {
          path: ProctoringRoute.Terminated,
          element: <Terminated />
        },
        {
          path: ProctoringRoute.EndScreen,
          element: <EndScreen />
        },
        {
          path: ProctoringRoute.RecorderScreen,
          element: <RecorderScreen />
        },
        {
          path: ProctoringRoute.ScreenShare,
          element: <ScreenShare />
        },
        {
          path: ProctoringRoute.FullScreen,
          element: <FullScreen />
        },
        {
          path: ProctoringRoute.Feedback,
          element: <Feedback />
        },
        {
          path: ProctoringRoute.SecondaryDevice,
          element: <SecondaryDevice />
        },
        {
          path: ProctoringRoute.PauseProctor,
          element: <PauseProctorScreen />
        }
      ],
      errorElement: <ErrorScreen />,
      hasErrorBoundary: true
    }
  ];

  const router = createMemoryRouter(routes, {
    initialEntries: ["/"],
    initialIndex: 0
  });

  return <RouterProvider router={router} />;
}
