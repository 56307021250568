import * as Bowser from "bowser";
import { ScreenShareEvent, ReadyState, BrowserName } from "globals/enums";

export const isTsbBrowser = () => {
  return (
    window.navigator.userAgent.includes("Proview-SB") ||
    window.navigator.userAgent.includes("TalviewBrowser")
  );
};

// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const getSharedScreenEvent = (videoTrackStream: MediaStreamTrack[], userBrowser: string) => {
  const mediaStreamTrack = videoTrackStream && videoTrackStream.length ? videoTrackStream[0] : null;

  let sharedScreen = "";
  if (mediaStreamTrack && mediaStreamTrack?.readyState === ReadyState.Live) {
    sharedScreen = mediaStreamTrack.label;
  }

  const entireScreenValues = [ScreenShareEvent.PrimaryMonitor, ScreenShareEvent.Monitor];

  if (sharedScreen.includes("browser")) {
    return ScreenShareEvent.TabScreenShare;
  }
  if (
    entireScreenValues.includes(sharedScreen as ScreenShareEvent) ||
    sharedScreen.includes("screen") ||
    // isSafari ||
    isTsbBrowser() ||
    (userBrowser === BrowserName.Firefox && mediaStreamTrack.label === "")
  ) {
    return ScreenShareEvent.EntireScreenShare;
  }

  if (sharedScreen.includes("window")) {
    return ScreenShareEvent.WindowScreenShare;
  }
  return ScreenShareEvent.NotShared;
};

export const getUserBrowser = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getBrowser().name.toLocaleLowerCase() as BrowserName;
};

const supportedBrowsers = [BrowserName.Chrome, BrowserName.Edge, BrowserName.Firefox];
export const isSupportedBrowser = () => {
  return supportedBrowsers.includes(getUserBrowser()) || isTsbBrowser();
};

export const detectMob = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};
