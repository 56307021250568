import { useContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { useNavigate } from "react-router";
import { AlertLogConfig, Session, SessionTokenApiResponse } from "services/session-token";
import { getEndpoint, ProctoringRoute } from "routes/route";
import { ClientErrorTypes, SessionScope, StorageKeyNames, StreamingProvider } from "globals/enums";
import { MediaContext } from "context/MediaContext";
import {
  setIsCameraGrabEnabled,
  setIsFaceDetectionEnabled,
  setLinkAndVideo,
  setPrecheckSuccess,
  setVideoStreamingEnabled
} from "store/app.slice";
import FreshChat from "config/freshchat";
import { hideProview } from "helpers/hideProview";
import { handleError } from "helpers/sentry";
import { alertHelper } from "../helpers/alert-helper";
import { AlertName } from "../helpers/alert-type";

const RecorderScreen = () => {
  const sessionDetails: Session = useAppSelector((state) => state.app.session);
  const { stop, stopDisplayMedia, setMediaStreamError } = useContext(MediaContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const configuration = useAppSelector((state) => state.app.configuration);
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);

  useEffect(() => {
    setMediaStreamError(null);
    dispatch(setIsCameraGrabEnabled({ isCameraGrabEnabled: true }));
  }, []);

  const stopProctoring = async () => {
    dispatch(setPrecheckSuccess({ precheckSuccess: false }));
    dispatch(setIsCameraGrabEnabled({ isCameraGrabEnabled: false }));
    dispatch(setIsFaceDetectionEnabled({ isFaceDetectionEnabled: false }));
    const element = document?.getElementById("proview_root");
    if (element) {
      element.style.display = "flex";
    }
    await stop();
    stopDisplayMedia();
  };

  useEffect(() => {
    document.addEventListener("Terminated", async () => {
      stopProctoring();
      navigate(getEndpoint(ProctoringRoute.Terminated));
    });

    document.addEventListener("StopProctorClient", async () => {
      stopProctoring();
      navigate(getEndpoint(ProctoringRoute.EndScreen));
    });
  }, []);

  const initializeFreshChat = (
    configuration: SessionTokenApiResponse["configuration"],
    sessionDetails: Session
  ) => {
    const { chat_support_precheck, chat_support_event } = configuration || {};
    const { session_type } = sessionDetails;
    if (session_type !== SessionScope.CandidateAuth) {
      if (chat_support_precheck && !chat_support_event) {
        FreshChat.unload();
      } else if (chat_support_event && !window.fcWidget) {
        FreshChat.init(sessionDetails?.session_id);
      }
    }
  };

  useEffect(() => {
    initializeFreshChat(configuration, sessionDetails);
    if (configuration.streaming_provider === StreamingProvider.PlatformStreaming) {
      alertHelper(alertConfig).raiseAlert(AlertName.RecordingStarted, dispatch);
      dispatch(setIsFaceDetectionEnabled({ isFaceDetectionEnabled: true }));
      configuration.video_recording &&
        dispatch(setVideoStreamingEnabled({ isVideoStreamingEnabled: true }));
      dispatch(setLinkAndVideo({ showLinkAndVideo: true }));
    }
    hideProview();
    sessionStorage.setItem(StorageKeyNames.Screen, ProctoringRoute.RecorderScreen);
    const sessionUuid = sessionDetails?.session_uuid;
    try {
      window.ProctorClient3.giveControl() &&
        sessionUuid &&
        window.ProctorClient3.giveControl().initCallback(null, sessionUuid, true, true, false);
    } catch (error) {
      handleError(error, ClientErrorTypes.SessionStartError);
    }
  }, []);

  return <div className="lastscreen"></div>;
};
export default RecorderScreen;
