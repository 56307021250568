import { freshChatHost, freshChatToken, freshChatSiteId, freshChatScript } from "config/constant";
declare global {
  interface Window {
    fcWidget: any;
  }
}

function load(externalId: number) {
  const initFreshChat = () => {
    window.fcWidget.init({
      token: freshChatToken,
      host: freshChatHost,
      siteId: freshChatSiteId,
      externalId: externalId,
      config: {
        cssNames: {
          widget: "proctoring_fc_frame"
        },
        headerProperty: {
          hideChatButton: true
        }
      }
    });
  };

  const createElement = (i: any, t: string, e: any) => {
    e = i.createElement("script");
    e.id = t;
    e.async = !0;
    e.src = freshChatScript;
    e.onload = initFreshChat;
    i.head.appendChild(e);
  };

  const initialize = (i: any, t: string) => {
    let e;
    const callBack = i.getElementById(t) ? initFreshChat : createElement;
    callBack(i, t, e);
  };
  initialize(document, "freshchat-js-sdk");
}

function _unload() {
  if (window.fcWidget) {
    window.fcWidget.off("widget:loaded");
    window.fcWidget.destroy();
  }
}

const freshChatActions = {
  init: function (session_id: number) {
    load(session_id);
  },
  unload: function () {
    _unload();
  }
};

export default freshChatActions;
