import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { firebaseCollections, PauseProctorReasonCodes, StorageKeyNames } from "globals/enums";
import { getDatabase, onValue, ref } from "firebase/database";
import { showPauseProctorScreen } from "../helpers/pauseProctor";
import { useNavigate } from "react-router";
import { setIsPaused } from "../store/app.slice";
import { ProctoringRoute } from "../routes/route";

const useWatchPauseStatus = () => {
  const session = useAppSelector((state) => state.app.session);
  const pauseRef = ref(getDatabase(), `${firebaseCollections.SessionV2}/${session.session_id}`);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return onValue(pauseRef, (sessionSnapShot) => {
      const isPause = sessionSnapShot.val() || {};
      const currentScreen = sessionStorage.getItem(StorageKeyNames.Screen);
      if (session.session_id && currentScreen === ProctoringRoute.RecorderScreen) {
        if (isPause.pause) {
          dispatch(setIsPaused({ isPaused: true }));
          showPauseProctorScreen(dispatch, navigate, PauseProctorReasonCodes.PausedByProctor);
        } else {
          dispatch(setIsPaused({ isPaused: false }));
        }
      }
    });
  }, [pauseRef]);

  return [pauseRef];
};

export default useWatchPauseStatus;
