import { AlertLogConfig } from "services/session-token";
import { alertListToNotifyCandidate, defaultNotificationThreshold } from "config/constant";
const eventCountOccurenceById = [] as number[];
const filterTheRequiredAlertFromAlertConfig = (
  alertConfig: AlertLogConfig[],
  alert_type_id: any
) => {
  const filteredAlertList = alertConfig.filter((item) => {
    if (alertListToNotifyCandidate.includes(item.alert_type_id)) {
      return item;
    }
    return null;
  });
  return getCurrentAlertDetails(filteredAlertList, alert_type_id);
};

const getCurrentAlertDetails = (filteredArray: AlertLogConfig[], alert_type_id: any) => {
  return filteredArray.filter((item) => item.alert_type_id == alert_type_id);
};

const getCountOccurence = (alert: AlertLogConfig[]) => {
  return eventCountOccurenceById.filter((item: any) => {
    return item === alert[0].alert_type_id;
  }).length;
};

const shuouldNotify = (alert: AlertLogConfig[]) => {
  const { prompt_each_time, prompt_min_occurrence, alert_type_id } = alert[0];
  if (!prompt_each_time) {
    return false;
  }
  eventCountOccurenceById.push(alert_type_id);
  const countOccurrences = getCountOccurence(alert);
  const notification_threshold = prompt_min_occurrence || defaultNotificationThreshold;
  return countOccurrences <= notification_threshold;
};

const isNotifyCandidateWithThisAlert = (alert: AlertLogConfig[]) => {
  const notify = shuouldNotify(alert);
  return notify;
};

export const shouldNotifyCandidate = (alertConfig: AlertLogConfig[], alert_type_id: any) => {
  const filteredArray = filterTheRequiredAlertFromAlertConfig(alertConfig, alert_type_id);
  if (!filteredArray?.length) return false;
  return isNotifyCandidateWithThisAlert(filteredArray);
};
