import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { appId, proctoringApiBaseUrl, xClientVersion } from "config/constant";
import type { RootState } from "store/store";
import mixpanel from "config/mixpanel";
import { AlertApiPayload } from "globals/interfaces";

export const faceCaptureUploadApi = createApi({
  reducerPath: "faceCaptureUpload",
  baseQuery: fetchBaseQuery({
    baseUrl: proctoringApiBaseUrl,
    prepareHeaders: (headers: Headers, { getState }) => {
      const session = (getState() as RootState).app.session;
      headers.set("app-id", appId as string);
      headers.set("session-token", session.session_token!);
      headers.set("x-talview-client-version", xClientVersion);
    }
  }),
  endpoints: (builder) => ({
    faceCapture: builder.mutation({
      query: (payload: AlertApiPayload) => {
        mixpanel.track(payload.description);
        return {
          url: "v1/alert",
          method: "POST",
          body: payload
        };
      },
      transformResponse(response: any) {
        return response;
      },
      transformErrorResponse(errorResponse: any) {
        return errorResponse?.data?.message;
      }
    })
  })
});

export const { useFaceCaptureMutation: useFaceCapture } = faceCaptureUploadApi;
