import React from "react";

type ButtonProps = {
  label: string | React.ReactNode;
  className: string;
  onClick?: () => void;
  type?: string;
  disabled?: boolean;
  name?: string;
  tabIndex?: number;
};

const Button = (props: ButtonProps) => {
  return props.disabled ? (
    <button className={props.className} disabled={props.disabled} tabIndex={props.tabIndex}>
      {props.label}
    </button>
  ) : (
    <button className={props.className} onClick={props.onClick} tabIndex={props.tabIndex}>
      {props.label}
    </button>
  );
};

export default Button;
