import { dequeueItem } from "store/alertSlice";
import { useCreateAlertMutation } from "services/alert";
import { AlertType, AlertName } from "helpers/alert-type";
import useQueueProcessor from "./useQueueProcessor";
import { handleError } from "helpers/sentry";
import { ClientErrorTypes } from "globals/enums";

const useAlertUploader = () => {
  const [createAlert] = useCreateAlertMutation();
  const queueSelector = (state: any) => state.alertQueue;

  const uploadAction = async (item: any) => {
    const keepalive = item?.alert_type_id === AlertType[AlertName.CandidateOffline].id;
    await (keepalive
      ? createAlert({ ...item, keepalive: true }).unwrap()
      : createAlert(item).unwrap());
  };

  const uploadError = (error) => {
    handleError(error, ClientErrorTypes.AlertUploadError)
  }

  useQueueProcessor(queueSelector, dequeueItem, uploadAction, uploadError);
};

export default useAlertUploader;
