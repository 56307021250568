import { AccessStatus, BrowserName, ErrorTypes } from "globals/enums";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getEndpoint, ProctoringRoute } from "routes/route";
import { setUiErrorScreen } from "store/app.slice";
import { useAppDispatch } from "store/store";
import { getUserBrowser } from "helpers/browser-helper";

const usePermissionChange = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const browser = getUserBrowser();
  const checkState = (permission: PermissionStatus, errorType: string | undefined) => {
    if (permission.state === AccessStatus.Denied) {
      dispatch(setUiErrorScreen({ uiErrorScreen: errorType as string }));
      navigate(getEndpoint(ProctoringRoute.Error));
    }
  };

  const callbackCameraChange = async () => {
    const permission = await navigator.permissions.query({ name: "camera" as PermissionName });
    checkState(permission, ErrorTypes.CameraInaccessibleError);
  };

  const callbackMicrophoneChange = async () => {
    const permission = await navigator.permissions.query({ name: "microphone" as PermissionName });
    checkState(permission, ErrorTypes.MicrophoneInaccessibleError);
  };

  // const callbackNotificationChange = async () => {
  //   const permission = await navigator.permissions.query({
  //     name: "notifications" as PermissionName
  //   });
  //   checkState(permission, ErrorTypes.NotificationsInaccessibleError);
  // };

  useEffect(() => {
    if (browser !== BrowserName.Firefox) {
      navigator.permissions.query({ name: "camera" as PermissionName }).then((permissionStatus) => {
        permissionStatus.addEventListener("change", callbackCameraChange);
      });

      navigator.permissions
        .query({ name: "microphone" as PermissionName })
        .then((permissionStatus) => {
          permissionStatus.addEventListener("change", callbackMicrophoneChange);
        });
    }

    // navigator.permissions
    //   .query({ name: "notifications" as PermissionName })
    //   .then((permissionStatus) => {
    //     permissionStatus.addEventListener("change", callbackNotificationChange);
    //   });

    return () => {
      if (browser !== BrowserName.Firefox) {
        navigator.permissions
          .query({ name: "camera" as PermissionName })
          .then((permissionStatus) => {
            permissionStatus.removeEventListener("change", callbackCameraChange);
          });

        navigator.permissions
          .query({ name: "microphone" as PermissionName })
          .then((permissionStatus) => {
            permissionStatus.removeEventListener("change", callbackMicrophoneChange);
          });
      }

      // navigator.permissions
      //   .query({ name: "notifications" as PermissionName })
      //   .then((permissionStatus) => {
      //     permissionStatus.removeEventListener("change", callbackNotificationChange);
      //   });
    };
  }, [callbackCameraChange, callbackMicrophoneChange]);
};

export default usePermissionChange;
