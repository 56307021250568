import { dequeueItem } from "store/priorityAlertSlice";
import { useCreateAlertMutation } from "services/alert";
import useQueueProcessor from "./useQueueProcessor";
import { ClientErrorTypes } from "globals/enums";
import { handleError } from "helpers/sentry";

const useAlertUploader = () => {
  const [createAlert] = useCreateAlertMutation();
  const queueSelector = (state: any) => state.priorityAlertQueue;

  const uploadAction = async (item: any) => {
    await createAlert(item).unwrap();
  };

  const uploadError = (error: unknown) => {
    handleError(error, ClientErrorTypes.AlertUploadError);
  };

  useQueueProcessor(queueSelector, dequeueItem, uploadAction, uploadError);
};

export default useAlertUploader;
