import { dequeueItem } from "store/cameraGrabSlice";
import { useCreateBlobMutation } from "services/blobStorage";
import useQueueProcessor from "./useQueueProcessor";
import { ClientErrorTypes } from "globals/enums";
import { handleError } from "helpers/sentry";

const useCameraGrabUploader = () => {
  const [createBlob] = useCreateBlobMutation();
  const queueSelector = (state: any) => state.cameraGrabQueue;

  const uploadAction = async (item: any) => {
    createBlob(item).unwrap();
  };

  const uploadError = (error: unknown) => {
    handleError(error, ClientErrorTypes.CameraGrabUploadError);
  };

  useQueueProcessor(queueSelector, dequeueItem, uploadAction, uploadError);
};

export default useCameraGrabUploader;
