import { FormattedMessage } from "react-intl";
import arrow from "assets/img/arrow.svg";
import PermissionIcon from "assets/img/permissions.png";

const PermissionError = () => {
  return (
    <div className="tvp-flex tvp-w-full tvp-h-auto tvp-bg-white tvp-my-4 tvp-flex-grow tvp-flex-col">
      <div className="tvp-w-full tvp-flex tvp-flex-row tvp-justify-around">
        <img className="tvp-permissionIcon tvp-mt-5" src={PermissionIcon} alt="permissionIcon" />
      </div>
      <span>
        <h3 className="tvp-my-3 tvp-font-bold tvp-text-xl tvp-text-center tvp-clr-neutral">
          <FormattedMessage id="component.permissionError.segment-1.title" />
        </h3>
      </span>
      <div className="tvp-w-10/12 tvp-ml-auto tvp-mr-auto">
        <ul className="tvp-list-disc">
          <li>
            <div className={"tvp-flex tvp-flex-row tvp-mr-2.5"}>
              <img
                className={"tvp-flex tvp-flex-row tvp-w-3 tvp-h-3 tvp-mt-1.5"}
                src={arrow}
                alt="Arrow indicating instruction one"
              />
            </div>
            <FormattedMessage id="component.permissionError.segment-1.content.step-1" />
          </li>
          <li>
            <div className={"tvp-flex tvp-flex-row tvp-mr-2.5"}>
              <img
                className={"tvp-flex tvp-flex-row tvp-w-3 tvp-h-3 tvp-mt-1.5"}
                src={arrow}
                alt="Arrow indicating instruction two"
              />
            </div>
            <FormattedMessage id="component.permissionError.segment-1.content.step-2" />
          </li>
          <li>
            <div className={"tvp-flex tvp-flex-row tvp-mr-2.5"}>
              <img
                className={"tvp-flex tvp-flex-row tvp-w-3 tvp-h-3 tvp-mt-1.5"}
                src={arrow}
                alt="Arrow indicating instruction three"
              />
            </div>
            <FormattedMessage id="component.permissionError.segment-1.content.step-3" />
          </li>
        </ul>
        <p className="tvp-font-bold tvp-mt-2">
          <FormattedMessage id="component.permissionError.tip" />
          <span className="tvp-clr-neutral">
            <FormattedMessage id="component.permissionError.segment-1.content-2" />
          </span>
        </p>
      </div>
    </div>
  );
};

export default PermissionError;
