import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { appId, proctoringApiBaseUrl, xClientVersion } from "config/constant";
import type { RootState } from "store/store";

export interface PreCheckItem {
  status: string;
  reason?: string | null;
  number_of_attempts?: number;
  verification_type: string;
}

export interface PrecheckApiResponse {
  pre_check: PreCheckItem[];
  session_status: string;
  termination_reason: string;
}

export const precheckApi = createApi({
  reducerPath: "precheck",
  baseQuery: fetchBaseQuery({
    baseUrl: proctoringApiBaseUrl,
    prepareHeaders: (headers: Headers, { getState }) => {
      const sessionToken = (getState() as RootState).app.session.session_token;
      headers.set("app-id", appId as string);
      headers.set("session-token", sessionToken);
      headers.set("x-talview-client-version", xClientVersion);
    }
  }),
  endpoints: (builder) => ({
    getPrecheck: builder.query<PrecheckApiResponse, Partial<PreCheckItem> | undefined>({
      query: () => {
        return {
          url: "v1/session/pre-check",
          method: "GET"
        };
      },
      transformResponse(response: PrecheckApiResponse) {
        return response;
      },
      transformErrorResponse(errorResponse: any) {
        return errorResponse?.data?.message;
      }
    }),
    invalidatePreCheck: builder.mutation<void, void>({
      query: () => {
        return {
          url: "/v1/pre-check/invalidate",
          method: "PATCH"
        };
      }
    })
  })
});

export const { useGetPrecheckQuery, useInvalidatePreCheckMutation } = precheckApi;
