import { useAppSelector } from "store/store";
import { FormattedMessage } from "react-intl";
import Button from "components/Button";
import PermissionIcon from "assets/img/permissions.png";
import arrow from "assets/img/arrow.svg";
import { livekitDiagnosticsUrl, speedTestUrl, twilioDiagnosticsUrl } from "config/constant";
import { StreamingProvider } from "globals/enums";

const StreamingReconnection = () => {
  const configuration = useAppSelector((state) => state.app.configuration);

  const instlist = [
    {
      id: 1,
      item: (
        <FormattedMessage
          id="component.streamingReconnection.instruction-1"
          values={{
            a: (chunks) => (
              <a
                href={speedTestUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: 0 }}
              >
                {chunks}
              </a>
            )
          }}
        />
      ),
      altText: "Arrow indicating instruction one"
    },
    {
      id: 2,
      item: (
        <FormattedMessage
          id="component.streamingReconnection.instruction-2"
          values={{
            a: (chunks) => (
              <a
                href={
                  configuration.streaming_provider === StreamingProvider.Livekit
                    ? livekitDiagnosticsUrl
                    : twilioDiagnosticsUrl
                }
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: 0 }}
              >
                {chunks}
              </a>
            )
          }}
        />
      ),
      altText: "Arrow indicating instruction two"
    }
  ];

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="tvp-w-full tvp-h-auto tvp-bg-white tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-justify-center">
      <div className="tvp-w-full tvp-flex tvp-flex-row tvp-justify-around">
        <img className="tvp-permissionIcon tvp-mt-5" src={PermissionIcon} alt="permissionIcon" />
      </div>
      <span className="tvp-mt-4">
        <h6 className="tvp-my-3 tvp-font-bold tvp-text-center tvp-clr-neutral">
          <FormattedMessage id="component.streamingReconnection.header" />
        </h6>
      </span>
      <div className="tvp-max-w-max tvp-w-5/6 tvp-my-4 tvp-mx-auto tvp-flex tvp-flex-col tvp-gap-4">
        <FormattedMessage id="component.streamingReconnection.content-1" />
        <div className="tvp-flex tvp-flex-col tvp-gap-2">
          {instlist.map(({ item, altText }) => (
            <div className="tvp-flex tvp-gap-1 tvp-items-center">
              <div className="tvp-flex tvp-flex-row tvp-items-center tvp-mr-2.5">
                <img
                  className="tvp-flex tvp-flex-row tvp-items-center tvp-w-3 tvp-h-3"
                  src={arrow}
                  alt={altText}
                />
              </div>
              <div className="tvp-w-11/12">{item}</div>
            </div>
          ))}
        </div>
        <FormattedMessage id="component.streamingReconnection.content-2" />
      </div>
      <Button
        label={<FormattedMessage id="component.streamingReconnection.refresh.button" />}
        className="tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-bg-green tvp-mx-auto tvp-bdr-green tvp-mt-4 tvp-font-bold"
        onClick={handleRefresh}
      />
    </div>
  );
};

export default StreamingReconnection;
