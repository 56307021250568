import { FormattedMessage } from "react-intl";
import Connectivity from "assets/img/connectivity.png";

function NetworkConnectivityError() {
  return (
    <div className="tvp-w-full tvp-h-auto tvp-bg-white  tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-justify-center">
      <div className="tvp-w-full tvp-flex tvp-flex-row tvp-justify-around">
        <img src={Connectivity} alt="no connection" />
      </div>
      <span>
        <h3 className="tvp-my-3 tvp-font-bold tvp-text-xl tvp-text-center tvp-clr-neutral">
          <FormattedMessage id="component.networkConnectivityError.segment-1.title" />
        </h3>
      </span>
      <div className="tvp-text-center tvp-w-10/12 tvp-ml-auto tvp-mr-auto">
        <p>
          <FormattedMessage id="component.networkConnectivityError.segment-1.content" />
        </p>
        <p className="tvp-mt-2">
          <FormattedMessage id="component.networkConnectivityError.segment-1.content-2" />
        </p>
      </div>
    </div>
  );
}

export default NetworkConnectivityError;
