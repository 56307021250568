import { dequeueItem } from "../store/screenGrabSlice";
import { useCreateBlobMutation } from "services/blobStorage";
import useQueueProcessor from "./useQueueProcessor";
import { handleError } from "helpers/sentry";
import { ClientErrorTypes } from "globals/enums";

const useScreenGrabUploader = () => {
  const [createBlob] = useCreateBlobMutation();
  const queueSelector = (state: any) => state.screenGrabQueue;

  const uploadAction = async (item: any) => {
    createBlob(item);
  };

  const uploadError = (error) => {
    handleError(error, ClientErrorTypes.ScreenGrabUploadError)
  } 
 
  useQueueProcessor(queueSelector, dequeueItem, uploadAction, uploadError);
};

export default useScreenGrabUploader;
