/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import {
  environmentName,
  tracesSampleRate,
  sampleRate,
  replaysSessionSampleRate,
  replaysOnErrorSampleRate
} from "config/constant";
import "./index.css";
import "./scoped-preflight.css";
import App from "./App";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: (integrations) => {
    return integrations
      .filter((integration) => integration.name !== "BrowserApiErrors")
      .concat([
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        }),
        Sentry.replayIntegration(),
        Sentry.thirdPartyErrorFilterIntegration({
          filterKeys: [process.env.SENTRY_FILTER_KEY],
          behaviour: "apply-tag-if-exclusively-contains-third-party-frames",
        }),
      ]);
  },
  tracesSampleRate: tracesSampleRate,
  sampleRate: sampleRate,
  release: process.env.SENTRY_RELEASE,
  replaysSessionSampleRate: replaysSessionSampleRate,
  replaysOnErrorSampleRate: replaysOnErrorSampleRate,
  ignoreErrors: ["AbortError: The play() request was interrupted"]
});

let proviewRoot = document.getElementById("proview_root") as HTMLElement;
let chatContainer = document.getElementById("tvp-chat-container") as HTMLElement;
if (!proviewRoot) {
  proviewRoot = document.createElement("div") as HTMLElement;
  proviewRoot.id = "proview_root";
  proviewRoot.style.backgroundColor = "#000";
  proviewRoot.style.display = "flex";
  proviewRoot.style.justifyContent = "center";
  proviewRoot.style.alignItems = "center";
  proviewRoot.style.zIndex = "9999999";
  proviewRoot.style.position = "fixed";
  proviewRoot.style.top = "0px";
  proviewRoot.style.bottom = "-14px";
  proviewRoot.style.left = "0px";
  proviewRoot.style.right = "0px";
  document.body.appendChild(proviewRoot);
} else {
  proviewRoot.style.setProperty("display", "flex", "important");
}

if (!chatContainer) {
  chatContainer = document.createElement("div") as HTMLElement;
  chatContainer.id = "tvp-chat-container";
  chatContainer.className = "tvp-proview_root";
  chatContainer.style.zIndex = "999999999";
  chatContainer.style.position = "absolute";
  chatContainer.style.bottom = "0px";
  chatContainer.style.right = "0px";
  chatContainer.style.display = "block";
  document.body.appendChild(chatContainer);
}

const root = ReactDOM.createRoot(proviewRoot);

if (environmentName === "development") {
  const { worker } = require("./mocks/browser");
  worker.start();
  if (module.hot) {
    module.hot.accept();
  }
}

root.render(<App />);
