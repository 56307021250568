import { Provider } from "react-redux";

// Store
import { store } from "store/store";

// Providers
import TranslationProvider from "providers/translation-provider";
import Navigation from "routes/navigation";
import "./style.css";
import { MediaProvider } from "context/MediaContext";
import BrandingContainer from "components/BrandingContainer";
const App = () => {
  return (
    <div className="tvp-proview_root">
      <Provider store={store}>
        <TranslationProvider>
          <MediaProvider>
            <BrandingContainer />
            <Navigation />
          </MediaProvider>
        </TranslationProvider>
      </Provider>
    </div>
  );
};

export default App;
