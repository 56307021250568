import { AppLanguage, AppLocale } from "globals/enums";
import { useAppDispatch, useAppSelector } from "store/store";
import Select from "react-select";

import GlobeIcon from "assets/img/globe.svg";
import React from "react";
import { setLanguage } from "store/app.slice";

const LanguageSwitch = ({ tabIndex }) => {
  const appDetails = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const appLanguages = [
    { label: AppLanguage.English, value: AppLocale.English },
    { label: AppLanguage.Chinese, value: AppLocale.Chinese },
    { label: AppLanguage.Welsh, value: AppLocale.Welsh },
    { label: AppLanguage.French, value: AppLocale.French },
    { label: AppLanguage.Japanese, value: AppLocale.Japanese },
    { label: AppLanguage.BrazilianPortuguese, value: AppLocale.BrazilianPortuguese },
    { label: AppLanguage.Spanish, value: AppLocale.Spanish }
  ];

  const handleLanguageChange = (e: any) => {
    dispatch(setLanguage({ locale: e.value }));
  };

  return (
    <div className="tvp-ml-auto" style={{ backgroundColor: "#fff" }}>
      <div className="tvp-flex">
        <label className="tvp-flex" id="aria-label" htmlFor="aria-language-input">
          <img
            className="tvp-GlobeIcon tvp-pr-2 tvp-box-border"
            src={GlobeIcon}
            width={30}
            height={20}
            alt="A globe icon"
          />
        </label>
        <Select
          aria-labelledby="aria-label"
          inputId="aria-language-input"
          className="basic-single"
          classNamePrefix="select"
          defaultValue={appLanguages.find(
            (language) => language.value === appDetails?.currentLanguage
          )}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={false}
          name="aria-selected-language"
          options={appLanguages}
          onChange={(e) => handleLanguageChange(e)}
          tabIndex={tabIndex}
        />
      </div>
    </div>
  );
};

export default LanguageSwitch;
