import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { appId, proctoringApiBaseUrl, xClientVersion } from "config/constant";
import type { RootState } from "store/store";

export interface PingApiPayload {
  session_token: string;
}

export const pingApi = createApi({
  reducerPath: "ping",
  baseQuery: fetchBaseQuery({
    baseUrl: proctoringApiBaseUrl,
    prepareHeaders: (headers: Headers, { getState }) => {
      const sessionToken = (getState() as RootState).app.session.session_token;
      headers.set("app-id", appId as string);
      headers.set("session-token", sessionToken);
      headers.set("x-talview-client-version", xClientVersion);
    }
  }),
  endpoints: (builder) => ({
    createPing: builder.query({
      query: (payload: PingApiPayload) => {
        return {
          url: "/ping",
          method: "POST",
          body: payload
        };
      },
      transformResponse: (response, meta) => {
        const date = meta?.response?.headers.get("date");
        return date;
      },
      transformErrorResponse: (response: { status: string | number }) => {
        return response;
      }
    })
  })
});

export const { useCreatePingQuery } = pingApi;
