import { Dispatch } from "@reduxjs/toolkit";
import { ErrorTypes } from "globals/enums";
import { ProctoringRoute, getEndpoint } from "routes/route";
import {
  setIsCameraGrabEnabled,
  setIsFaceDetectionEnabled,
  setUiErrorScreen,
  setVideoStreamingEnabled,
  setScreenRecordingEnabled
} from "store/app.slice";

const showPauseProctorScreen = (
  dispatch: Dispatch,
  navigate: any,
  code?: string,
  reason?: string,
  note?: string
) => {
  if (code === "TV-PC-NDC") {
    dispatch(
      setUiErrorScreen({ uiErrorScreen: ErrorTypes.NetworkConnectivityErrDetails as string })
    );
    navigate(getEndpoint(ProctoringRoute.Error));
  } else {
    navigate(getEndpoint(ProctoringRoute.PauseProctor));
  }
  const element = document?.getElementById("proview_root");
  if (element) {
    element.style.display = "flex";
  }
};

const stopRecording = (dispatch: Dispatch) => {
  const screenshareStop = new CustomEvent("StopScreenShare");
  document.dispatchEvent(screenshareStop);

  dispatch(setIsCameraGrabEnabled({ isCameraGrabEnabled: false }));
  dispatch(setIsFaceDetectionEnabled({ isFaceDetectionEnabled: false }));
  dispatch(setVideoStreamingEnabled({ isVideoStreamingEnabled: false }));
  dispatch(setScreenRecordingEnabled({ isScreenRecordingEnabled: false }));

  const videoTracks = new CustomEvent("StopVideoTracks");
  document.dispatchEvent(videoTracks);
  document.getElementById("recorder_video_custom") &&
    document.getElementById("recorder_video_custom")?.remove();
  const videoContainer = document?.getElementById("tvp_recorder_container") as HTMLElement;
  if (videoContainer) videoContainer.innerHTML = "";
};

const pauseProctor = (
  dispatch: Dispatch,
  navigate: any,
  code: string,
  reason?: string,
  note?: string
) => {
  if (!code) console.log("Missing required argument: code");
  showPauseProctorScreen(dispatch, navigate, code, reason, note);
  stopRecording(dispatch);
};

export { pauseProctor, stopRecording, showPauseProctorScreen };
