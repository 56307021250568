import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { appId, proctoringApiBaseUrl } from "config/constant";

export interface FeedbackApiPayload {
  proctor_rating?: number;
  session_rating: number;
  text: string;
  session_uuid: string;
}

export const feedbackApi = createApi({
  reducerPath: "alert",
  baseQuery: fetchBaseQuery({
    baseUrl: proctoringApiBaseUrl,
    prepareHeaders: (headers: Headers) => {
      headers.set("app-id", appId as string);
    }
  }),
  endpoints: (builder) => ({
    submitFeedback: builder.mutation({
      query: (payload: FeedbackApiPayload) => {
        return {
          url: "v1/session/feedback",
          method: "POST",
          body: payload
        };
      },
      transformResponse(response: any) {
        return response;
      },
      transformErrorResponse(errorResponse: any) {
        return errorResponse?.data?.message;
      }
    })
  })
});

export const { useSubmitFeedbackMutation } = feedbackApi;
