/* eslint-disable */
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { useDispatch, useSelector } from "react-redux";
import thunk from "redux-thunk";
import type { TypedUseSelectorHook } from "react-redux";
import { sessionTokenApi } from "services/session-token";
import { audioTokenApi } from "services/audio-token";
import { alertApi } from "services/alert";
import { channelApi } from "services/channel";
import { blobApi } from "services/blobStorage";
import { streamApi } from "services/stream";
import { faceCaptureUploadApi } from "services/faceCaptureUpload";
import { photoIdCaptureUploadApi } from "services/photoIdCaptureUpload";
import { platformStreamAPI } from "services/platform-stream";
import appSlice from "store/app.slice";
import screenShareSlice from "services/screenShare";
import { precheckApi } from "services/precheck";
import { pingApi } from "services/ping";
import screenGrabQueue from "store/screenGrabSlice";
import cameraGrabQueue from "store/cameraGrabSlice";
import videoRecordingQueue from "store/videoRecordingSlice";
import priorityAlertQueue from "store/priorityAlertSlice";
import alertQueue from "store/alertSlice";
import firebaseCustomTokenApi from "services/firebase-custom-token";

const additonialMiddlewares = [
  thunk,
  sessionTokenApi.middleware,
  faceCaptureUploadApi.middleware,
  photoIdCaptureUploadApi.middleware,
  alertApi.middleware,
  blobApi.middleware,
  channelApi.middleware,
  audioTokenApi.middleware,
  precheckApi.middleware,
  streamApi.middleware,
  platformStreamAPI.middleware,
  pingApi.middleware,
  firebaseCustomTokenApi.middleware
];

const store = configureStore({
  reducer: {
    screenGrabQueue,
    cameraGrabQueue,
    videoRecordingQueue,
    alertQueue,
    priorityAlertQueue,
    app: appSlice.reducer,
    screenShare: screenShareSlice.reducer,
    [sessionTokenApi.reducerPath]: sessionTokenApi.reducer,
    [alertApi.reducerPath]: alertApi.reducer,
    [blobApi.reducerPath]: blobApi.reducer,
    [channelApi.reducerPath]: channelApi.reducer,
    [audioTokenApi.reducerPath]: audioTokenApi.reducer,
    [precheckApi.reducerPath]: precheckApi.reducer,
    [streamApi.reducerPath]: streamApi.reducer,
    [platformStreamAPI.reducerPath]: platformStreamAPI.reducer,
    [pingApi.reducerPath]: pingApi.reducer,
    [faceCaptureUploadApi.reducerPath]: faceCaptureUploadApi.reducer,
    [photoIdCaptureUploadApi.reducerPath]: photoIdCaptureUploadApi.reducer,
    [firebaseCustomTokenApi.reducerPath]: firebaseCustomTokenApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    }).concat(additonialMiddlewares);
  }
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export { store };
